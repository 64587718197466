import React, { useState } from "react";
import { AppBar, Toolbar, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import { DrawerMenu } from "./DrawerMenu";

interface CustomAppBarProps {
    title: string;
    contentColor?: string;
    backButton?: JSX.Element;

    head?: { title: string; subtitle: string };
    actions?: {
        icon: JSX.Element;
        label: string | JSX.Element;
        onClick: (e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    }[];
}

export function CustomAppBar({
    title,
    contentColor = "black",
    backButton,
    head,
    actions,
}: CustomAppBarProps) {
    const [state, setState] = useState({
        isOpen: false,
    });
    const toggleDrawer = () => {
        setState((prev) => ({ isOpen: !prev.isOpen }));
    };
    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                position="static"
                elevation={0}
                sx={{
                    backgroundColor: "transparent",
                }}
            >
                <Toolbar>
                    {backButton}
                    <Typography
                        variant="h5"
                        fontWeight={700}
                        component="div"
                        color={contentColor}
                        sx={{ flexGrow: 1 }}
                    >
                        {title}
                    </Typography>
                    {(head || actions) && (
                        <IconButton
                            onClick={() => toggleDrawer()}
                            size="large"
                            edge="end"
                            aria-label="menu"
                            sx={{ mr: 2, color: { contentColor } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <DrawerMenu //TODO: capire se è meglio togliere qando (head || actions) è false
                isOpen={state.isOpen}
                toggle={toggleDrawer}
                head={head}
                actions={actions}
            />
        </Box>
    );
}
