import { useState } from "react";
import {
    Dialog,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DialogProps } from ".";
import { CustomButton } from "@/components/CustomButton";
import { useReportService } from "@/services/reportService";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";
import { theme } from "@/theme";
interface AssistenceReportType {
    type: string;
    name: string;
}
const reportSchema = z.object({
    message: z.string().min(1, "requiredField"),
    typeReport: z.string().min(1, "requiredField"),
});

type ReportSchema = z.infer<typeof reportSchema>;

export function ReportDialog({ isOpen, closeDialog }: DialogProps) {
    const lang = useTranslatedMessages();
    const [isProcessing, setIsProcessing] = useState(false);

    const {
        register,
        handleSubmit,
        setError,
        control,
        reset,
        formState: { errors, isValid },
    } = useForm<ReportSchema>({
        resolver: zodResolver(reportSchema),
    });

    const { reportRequest } = useReportService();

    const onSubmit = async (data: ReportSchema) => {
        try {
            setIsProcessing(true);
            await reportRequest({ message: data.message, type: data.typeReport });
            closeDialog();
            reset({ message: "", typeReport: "" });
        } catch (err) {
            console.log(`Error reportRequest`, err);
            setError("root.serverError", {
                message: "sendReportServerError",
            });
        } finally {
            setIsProcessing(false);
        }
    };

    const getErrorMessage = (error: any | undefined) => {
        return !!error ? lang.pages.profile[error.message] : "";
    };
    const reporting: AssistenceReportType[] = [
        { type: "assistance", name: "richiesta assistenza" },
        { type: "error_report", name: "segnalazione errori" },
        { type: "other", name: "altro" },
    ];
    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={isOpen}
            onClose={() => {
                closeDialog();
                reset({ message: "", typeReport: "" });
            }}
            PaperProps={{
                style: { borderRadius: 0 },
            }}
        >
            <DialogHeaderComponent title={lang.pages.profile.profileReport} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
                >
                    <FormControl
                        sx={{ width: "100%" }}
                        variant="outlined"
                        margin="normal"
                        error={Boolean(errors.message)}
                    >
                        <InputLabel id="client-label">{lang.pages.profile.typeReport}</InputLabel>

                        <Controller
                            name="typeReport"
                            control={control}
                            render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                <Select
                                    name={name}
                                    labelId="client-label"
                                    value={value ? value : ""}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    inputRef={ref}
                                    label={lang.pages.profile.typeReport}
                                >
                                    {reporting.map((rep) => (
                                        <MenuItem key={rep.type} value={rep.type}>
                                            {rep.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                    <TextField
                        sx={{ width: "100%" }}
                        label={lang.shared.message}
                        rows={4}
                        error={!!errors.message}
                        helperText={getErrorMessage(errors.message)}
                        multiline
                        {...register("message", { required: true })}
                    />
                    {!!errors.root?.serverError && (
                        <Typography
                            variant="caption"
                            textAlign={"left"}
                            sx={{
                                marginTop: "10px",
                                color: (theme) => theme.palette.error.light,
                            }}
                        >
                            {getErrorMessage(errors.root.serverError.message)}
                        </Typography>
                    )}
                    <Stack flexDirection={"row"} gap={1}>
                        <CustomButton
                            sx={{
                                width: "100%",
                                borderColor: theme.palette.secondary.dark,
                                border: "1px solid",
                                backgroundColor: theme.palette.primary.light,
                                color: theme.palette.secondary.dark,
                            }}
                            label={lang.shared.close}
                            onClick={() => {
                                closeDialog();
                                reset({ message: "", typeReport: "" });
                            }}
                        />
                        <CustomButton
                            type="submit"
                            sx={{
                                width: "100%",
                                color: theme.palette.primary.light,
                                backgroundColor: theme.palette.secondary.dark,
                            }}
                            disabled={!isValid}
                            label={lang.dialogs.contact.contactDialogSendButton}
                        />
                    </Stack>
                </DialogContent>
            </form>
        </Dialog>
    );
}
