import React, { ReactNode, SyntheticEvent, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import Italian from "./languages/it-IT.json";
import English from "./languages/en-US.json";
import French from "./languages/fr-FR.json";
import { Box } from "@mui/material";

export const supportedLanguages = {
    en: {
        messages: English,
        name: "English",
        icon: "uk_flag.png",
    },
    fr: {
        messages: French,
        name: "French",
        icon: "fr_flag.png",
    },
    it: {
        messages: Italian,
        name: "Italian",
        icon: "it_flag.png",
    },
};
interface AppContextInterface {
    locale: string;
    changeLocale: () => void;
    supportedLanguages: typeof supportedLanguages;
}

const browserLocale = navigator.language?.slice(0, 2) ?? "it";
//selectLang: () => { }
const initialCtx: AppContextInterface = {
    locale: browserLocale,
    changeLocale: () => { },
    supportedLanguages,
};
export const AppCtx = React.createContext<AppContextInterface>(initialCtx);

const lang = supportedLanguages[browserLocale];

const LanguagesWrapper = ({ children }: { children: ReactNode }) => {
    const [locale, setLocale] = useState(browserLocale);
    const [messages, setMessages] = useState(lang.messages);

    /*function selectLang(e: SyntheticEvent) {
        const newLocale = (e?.target as HTMLInputElement).value;
        setLocale(newLocale);
        setMessages(supportedLanguages[newLocale].messages)
    }*/

    const changeLocale = () => {
        const keys = Object.keys(supportedLanguages);
        let idx = keys.indexOf(locale);
        idx = idx + 1 < keys.length ? idx + 1 : 0;
        let newLocale = keys[idx];
        setLocale(() => newLocale);
        setMessages(supportedLanguages[newLocale].messages);
    };
    return (
        <AppCtx.Provider value={{ locale, changeLocale, supportedLanguages }}>
            <IntlProvider messages={messages} locale={locale}>
                {children}
            </IntlProvider>
        </AppCtx.Provider>
    );
};

export default LanguagesWrapper;
