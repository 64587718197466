import { Box, Button, IconButton } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

interface BottomNavbarTutorialProps {
    isTutorialEnd: boolean;
    showSkip: boolean;
    onSkip: () => void;
    onClickForward: () => void;
}

export function BottomNavbarTutorial({
    isTutorialEnd,
    showSkip,
    onSkip,
    onClickForward,
}: BottomNavbarTutorialProps) {
    return (
        <div
            style={{
                width: "100%",
                display: "-webkit-inline-flex",
                justifyContent: "space-between",
            }}
        >
            {showSkip ? (
                <Button
                    variant="text"
                    sx={{
                        visibility: !isTutorialEnd ? "visible" : "hidden",
                        textTransform: "none",
                    }}
                    onClick={onSkip}
                >
                    Salta
                </Button>
            ) : (
                <Box />
            )}
            <div
                className="paginator"
                style={{ width: "auto", display: "flex", alignItems: "center" }}
            ></div>
            <div
                className="padding-right-medium next"
                style={{ display: "flex", alignItems: "center" }}
            >
                <IconButton component="span" onClick={onClickForward}>
                    <ArrowForwardRoundedIcon />
                </IconButton>
            </div>
        </div>
    );
}
