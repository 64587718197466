import { useAuthService } from "@/services/authService";
import { Navigate } from "react-router-dom";

export interface ProtectedRouteProps {
    children: JSX.Element;
    redirectPath?: string;
}
export const ProtectedRoute = ({ children, redirectPath }: ProtectedRouteProps) => {
    const { isLoggedIn } = useAuthService();
    if (!isLoggedIn())
        // user is not authenticated
        return <Navigate to={`/${redirectPath || ""}`} />;

    return children;
};
