import { useFormContext } from "react-hook-form";
import {
    TextField,
    Typography,
    Box,
    Stack,
    InputAdornment,
    IconButton,
    Popover,
} from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import HelpIcon from "@mui/icons-material/Help";
import { theme } from "@/theme";
import { useState } from "react";

interface InputPressureDataProps {
    systolicPressureRequired?: boolean;
    diastolicPressureRequired?: boolean;
}
function PressureData({
    systolicPressureRequired = true,
    diastolicPressureRequired = true,
}: InputPressureDataProps) {
    const {
        register,
        formState: { errors },
    } = useFormContext();
    const lang = useTranslatedMessages();
    const [infoTextIsOpen, setInfoTextIsOpen] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    return (
        <Stack gap={3}>
            {/* <Typography variant='h4'>{lang.scales.pressureComponent.title} </Typography>
             */}

            <Box height={65}>
                <TextField
                    {...register("systolicPressure", {
                        valueAsNumber: true,
                        required: systolicPressureRequired
                            ? lang.scales.pressureComponent.systolicPressure.errorText
                            : undefined,
                        validate: (value) => {
                            if (!value) {
                                return true;
                            }
                            if (!Number.isInteger(value) || value < 85 || value > 300) {
                                return lang.scales.pressureComponent.systolicPressure.errorText;
                            } else {
                                return true;
                            }
                        },
                    })}
                    inputProps={{
                        "data-testid": "form-component-systolicPressure", // Aggiungi data-testid qui
                        step: ".01",
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    label={
                        <>
                            {lang.scales.pressureComponent.systolicPressure.label}
                            {systolicPressureRequired ? "*" : null}
                        </>
                    }
                    fullWidth
                    type="number"
                    error={!!errors.systolicPressure}
                    helperText={
                        errors.systolicPressure
                            ? String(errors.systolicPressure?.message)
                            : lang.scales.pressureComponent.systolicPressure.hintText
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                mmHg
                                <IconButton
                                    onClick={(event) => {
                                        setAnchorEl(event.currentTarget);
                                        setInfoText(
                                            lang.scales.pressureComponent.systolicPressure.infoText
                                        );
                                        setInfoTextIsOpen(true);
                                    }}
                                    edge="end"
                                >
                                    {<HelpIcon sx={{ color: theme.palette.secondary.light }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    FormHelperTextProps={{
                        style: {
                            fontSize: '0.65rem', // Cambia la grandezza del testo come preferisci
                        },
                    }}
                />
            </Box>
            <Box height={65}>
                <TextField
                    {...register("diastolicPressure", {
                        valueAsNumber: true,
                        required: diastolicPressureRequired
                            ? lang.scales.pressureComponent.diastolicPressure.errorText
                            : undefined,
                        validate: (value) => {
                            if (!value) {
                                return true;
                            }
                            if (!Number.isInteger(value) || value < 50 || value > 300) {
                                return lang.scales.pressureComponent.diastolicPressure.errorText;
                            } else {
                                return true;
                            }
                        },
                    })}
                    inputProps={{
                        "data-testid": "form-component-diastolicPressure", // Aggiungi data-testid qu
                        step: ".01",
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    label={
                        <>
                            {lang.scales.pressureComponent.diastolicPressure.label}
                            {diastolicPressureRequired ? "*" : null}
                        </>
                    }
                    type="number"
                    fullWidth
                    error={!!errors.diastolicPressure}
                    helperText={
                        errors.diastolicPressure
                            ? String(errors.diastolicPressure?.message)
                            : lang.scales.pressureComponent.diastolicPressure.hintText
                    }
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                mmHg
                                <IconButton
                                    onClick={(event) => {
                                        setAnchorEl(event.currentTarget);
                                        setInfoText(
                                            lang.scales.pressureComponent.diastolicPressure.infoText
                                        );
                                        setInfoTextIsOpen(true);
                                    }}
                                    edge="end"
                                >
                                    {<HelpIcon sx={{ color: theme.palette.secondary.light }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    FormHelperTextProps={{
                        style: {
                            fontSize: '0.65rem', // Cambia la grandezza del testo come preferisci
                        },
                    }}
                />
            </Box>
            <Popover
                open={infoTextIsOpen}
                anchorEl={anchorEl}
                onClose={() => {
                    setInfoTextIsOpen(false);
                }}
                disableScrollLock
                transformOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Typography sx={{ p: 2 }}>{infoText}</Typography>
            </Popover>
        </Stack>
    );
}

export default PressureData;
