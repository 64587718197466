import React, { useEffect, useRef, useState } from "react";
import ProfileTable from "../../../../components/newGraphics/ProfileTable";
import { CircularProgress, Stack } from "@mui/material";
import { useLocalStorageService } from "@/states/localStorageState";
import { getPharmacy, Pharmacy } from "@/services/pharmacyService";
import ViewAppBar from "@/components/newGraphics/ViewAppBar";

export default function Profile() {
    const user = useLocalStorageService((state) => state.user);
    const [pharmacy, setPharmacy] = useState<Pharmacy>();
    const [isLoading, setIsLoading] = useState(true);
    const hasInizalized = useRef(false);
    useEffect(() => {
        if (!hasInizalized.current) {
            hasInizalized.current = true;
            fetchData();
        }
    }, [hasInizalized]);

    const fetchData = async () => {
        try {
            if (user?.pharmacyId) {
                const pharmacyData = await getPharmacy(user.pharmacyId);
                setPharmacy(pharmacyData);
                setIsLoading(false);
            }
        } catch (error) {
            console.error("Errore nel recupero delle informazioni della farmacia", error);
            setIsLoading(false);
        }
    };
    return (
        <Stack>
            <ViewAppBar />
            {isLoading ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <ProfileTable reloadData={fetchData} pharmacy={pharmacy!} />
            )}
        </Stack>
    );
}
