import createTheme from "@mui/material/styles/createTheme";

export const theme = createTheme({
    /*status: {
    danger: '#e53e3e',
  },*/
    palette: {
        primary: {
            light: "#ffffff",
            main: "#6C7386",
            dark: "#000000",
        },
        error: {
            light: "#f99988",
            main: "#f58484",
            dark: "#e53e3e",
        },
        secondary: {
            light: "#165E87", // "#5ca7ab",
            main: "#C5EDF6", // "#C5F6D8",
            dark: "#164A87", //#168287"
        },
        background: {
            default: "#f7f8fa", //"#f9faf9",
            paper: "#ffffff",
        },
        darkSecondary: {
            main: "#939393",
        },
        disabled: {
            main: "#D4C2DB",
        },
    },
    typography: {
        h6: {
            fontSize: 12,
        },
        h5: {
            fontSize: 16,
        },
        h4: {
            fontSize: 20,
        },
        h3: {
            fontSize: 32,
        },
    },
    components: {
        AidaptForm: {
            sx: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 3,
                "& .form_fields": {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "flex-start",
                    width: "100%",
                    gap: 1,
                },
                "& .form_actions": {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    flexWrap: "wrap",
                    gap: 1,
                },
                "& .MuiFormGroup-root": {
                    width: "100%",
                },
                "& .MuiFormControlLabel-root": {
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: 4,
                    marginLeft: 0,
                },
                "& .MuiFormControlLabel-root > span": {
                    width: "none",
                    color: "primary",
                    flex: 0.25,
                },
                "& .MuiInputBase-root": {
                    width: "100%",
                    color: "primary",
                },
                "& .MuiBox-root": {
                    width: "100%",
                    color: "primary",
                    /* flex: 0.75 */
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: "#939393",
                    opacity: 0.5,
                },
                colorPrimary: {
                    "&.Mui-checked": {
                        // Controls checked color for the thumb
                        color: "#164A87",
                    },
                },
                track: {
                    // Controls default (unchecked) color for the track
                    opacity: 0.2,
                    backgroundColor: "#939393",
                    ".Mui-checked.Mui-checked + &": {
                        // Controls checked color for the track
                        opacity: 0.4,
                        backgroundColor: "#164A87",
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    // Stili per il bordo quando non è focalizzato
                    /* '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'grey',
                    }, */
                    // Stili per il bordo quando è focalizzato
                    /* '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'blue', // Cambia a seconda delle tue esigenze
                    }, */
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#164A87", // Cambia a seconda delle tue esigenze
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    // 'paper' è l'elemento interno del Dialog che puoi stilizzare
                    width: "30rem", // Larghezza fissa
                },
            },
        },
    },
});
