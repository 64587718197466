export const countryRiskMap = {
    algeria: "very high",
    andorra: "low",
    armenia: "very high",
    austria: "moderate",
    bosnia_herzegovina: "high",
    belgium: "low",
    bulgaria: "very high",
    croatia: "high",
    cyprus: "moderate",
    denmark: "low",
    egypt: "very high",
    estonia: "high",
    finland: "moderate",
    france: "low",
    georgia: "very high",
    germany: "moderate",
    greece: "moderate",
    iceland: "moderate",
    israel: "low",
    ireland: "moderate",
    northern_ireland: "low",
    italy: "moderate",
    kazakhstan: "high",
    kyrgyzstan: "very high",
    latvia: "very high",
    lebanon: "very high",
    libya: "very high",
    lithuania: "very high",
    luxembourg: "low",
    macedonia: "very high",
    malta: "moderate",
    marocco: "very high",
    moldova: "very high",
    monaco: "low",
    montenegro: "very high",
    norway: "low",
    netherlands: "low",
    poland: "high",
    portugal: "moderate",
    united_kingdom: "low",
    romania: "very high",
    czech_republic: "high",
    russia: "very high",
    san_marino: "moderate",
    serbia: "very high",
    syria: "very high",
    slovakia: "high",
    slovenia: "moderate",
    spain: "low",
    sweden: "moderate",
    swiss: "low",
    tunisia: "very high",
    turkey: "high",
    ukraine: "very high",
    hungary: "high",
    uzbekistan: "very high",
};
export const martinHopkins = [
    [3.5, 3.4, 3.3, 3.3, 3.2, 3.1],
    [4.0, 3.9, 3.7, 3.6, 3.6, 3.4],
    [4.3, 4.1, 4.0, 3.9, 3.8, 3.6],
    [4.5, 4.3, 4.1, 4.0, 3.9, 3.9],
    [4.7, 4.4, 4.3, 4.2, 4.1, 3.9],

    [4.8, 4.6, 4.4, 4.2, 4.2, 4.1],
    [4.9, 4.6, 4.5, 4.3, 4.3, 4.2],
    [5.0, 4.8, 4.6, 4.4, 4.3, 4.2],
    [5.1, 4.8, 4.6, 4.5, 4.4, 4.3],
    [5.2, 4.9, 4.7, 4.6, 4.4, 4.3],

    [5.3, 5.0, 4.8, 4.7, 4.5, 4.4],
    [5.4, 5.1, 4.8, 4.7, 4.5, 4.3],
    [5.5, 5.2, 5.0, 4.7, 4.6, 4.5],
    [5.6, 5.3, 5.0, 4.8, 4.6, 4.5],
    [5.7, 5.4, 5.1, 4.9, 4.7, 4.5],

    [5.8, 5.5, 5.2, 5.0, 4.8, 4.6],
    [6.0, 5.5, 5.3, 5.0, 4.8, 4.6],
    [6.1, 5.7, 5.3, 5.1, 4.9, 4.7],
    [6.2, 5.8, 5.4, 5.2, 5.0, 4.7],
    [6.3, 5.9, 5.6, 5.3, 5.0, 4.8],

    [6.5, 6.0, 5.7, 5.4, 5.1, 4.8],
    [6.7, 6.2, 5.8, 5.4, 5.2, 4.9],
    [6.8, 6.3, 5.9, 5.5, 5.3, 5.0],
    [7.0, 6.5, 6.0, 5.7, 5.4, 5.1],
    [7.3, 6.7, 6.2, 5.8, 5.5, 5.2],

    [7.6, 6.9, 6.4, 6.0, 5.6, 5.3],
    [8.0, 7.2, 6.6, 6.2, 5.9, 5.4],
    [8.5, 7.6, 7.0, 6.5, 6.1, 5.6],
    [9.5, 8.3, 7.5, 7.0, 6.5, 5.9],
    [11.9, 10.0, 8.8, 8.1, 7.5, 6.7],
];

export const score2 = [
    [
        [
            [
                [
                    [28, 29, 30, 31],
                    [26, 27, 28, 29],
                    [24, 25, 26, 27],
                    [23, 24, 25, 26],
                ],
                [
                    [20, 21, 22, 23],
                    [18, 19, 20, 21],
                    [16, 17, 18, 19],
                    [15, 15, 16, 17],
                ],
                [
                    [15, 15, 16, 17],
                    [13, 13, 14, 15],
                    [11, 11, 12, 13],
                    [9, 10, 10, 11],
                ],
                [
                    [10, 11, 12, 12],
                    [9, 9, 10, 10],
                    [7, 7, 8, 8],
                    [6, 6, 6, 7],
                ],
                [
                    [8, 8, 9, 9],
                    [7, 7, 7, 7],
                    [5, 6, 6, 6],
                    [5, 5, 5, 5],
                ],
                [
                    [6, 6, 7, 7],
                    [5, 5, 5, 6],
                    [4, 4, 4, 5],
                    [3, 3, 4, 4],
                ],
                [
                    [4, 5, 5, 5],
                    [3, 4, 4, 4],
                    [3, 3, 3, 3],
                    [2, 2, 3, 3],
                ],
                [
                    [3, 4, 4, 4],
                    [3, 3, 3, 3],
                    [2, 2, 2, 3],
                    [2, 2, 2, 2],
                ],
                [
                    [2, 3, 3, 3],
                    [2, 2, 2, 3],
                    [1, 2, 2, 2],
                    [1, 1, 1, 1],
                ],
                [
                    [2, 2, 2, 3],
                    [1, 2, 2, 2],
                    [1, 1, 1, 1],
                    [1, 1, 1, 1],
                ],
            ],
            [
                [
                    [31, 32, 33, 34],
                    [29, 30, 31, 32],
                    [27, 28, 29, 30],
                    [25, 26, 27, 28],
                ],
                [
                    [25, 26, 28, 29],
                    [23, 24, 25, 26],
                    [20, 21, 22, 23],
                    [18, 19, 20, 21],
                ],
                [
                    [21, 22, 23, 24],
                    [18, 19, 20, 21],
                    [15, 16, 17, 18],
                    [13, 14, 15, 15],
                ],
                [
                    [17, 18, 19, 20],
                    [14, 15, 16, 16],
                    [11, 12, 13, 14],
                    [9, 10, 10, 11],
                ],
                [
                    [12, 12, 13, 13],
                    [10, 10, 11, 11],
                    [8, 9, 9, 9],
                    [7, 7, 7, 8],
                ],
                [
                    [10, 10, 11, 11],
                    [8, 8, 9, 9],
                    [6, 7, 7, 8],
                    [5, 6, 6, 6],
                ],
                [
                    [8, 8, 9, 10],
                    [6, 7, 7, 8],
                    [5, 5, 6, 6],
                    [4, 4, 5, 5],
                ],
                [
                    [6, 7, 7, 8],
                    [5, 5, 6, 6],
                    [4, 4, 5, 5],
                    [3, 3, 4, 4],
                ],
                [
                    [5, 5, 6, 7],
                    [4, 4, 5, 5],
                    [3, 3, 4, 4],
                    [2, 2, 3, 3],
                ],
                [
                    [4, 4, 5, 6],
                    [3, 3, 4, 4],
                    [2, 3, 3, 3],
                    [2, 2, 2, 2],
                ],
            ],
        ],
        [
            [
                [
                    [29, 35, 42, 49],
                    [28, 33, 40, 47],
                    [26, 32, 38, 45],
                    [25, 30, 36, 43],
                ],
                [
                    [23, 27, 32, 37],
                    [21, 25, 29, 34],
                    [19, 22, 26, 31],
                    [17, 20, 24, 28],
                ],
                [
                    [19, 21, 24, 27],
                    [16, 18, 21, 23],
                    [14, 15, 18, 20],
                    [12, 13, 15, 17],
                ],
                [
                    [15, 16, 18, 19],
                    [12, 13, 14, 16],
                    [10, 11, 12, 13],
                    [8, 8, 9, 10],
                ],
                [
                    [11, 12, 12, 13],
                    [9, 10, 11, 11],
                    [8, 8, 9, 10],
                    [6, 7, 7, 8],
                ],
                [
                    [8, 9, 10, 11],
                    [7, 8, 8, 9],
                    [6, 6, 7, 8],
                    [5, 5, 6, 6],
                ],
                [
                    [7, 7, 8, 9],
                    [5, 6, 7, 8],
                    [4, 5, 5, 6],
                    [4, 4, 4, 5],
                ],
                [
                    [5, 6, 7, 8],
                    [4, 5, 5, 6],
                    [3, 4, 4, 5],
                    [3, 3, 3, 4],
                ],
                [
                    [4, 5, 6, 6],
                    [3, 4, 4, 5],
                    [2, 3, 3, 4],
                    [2, 2, 3, 3],
                ],
                [
                    [3, 4, 5, 5],
                    [2, 3, 3, 4],
                    [2, 2, 3, 3],
                    [1, 2, 2, 2],
                ],
            ],
            [
                [
                    [29, 35, 42, 49],
                    [27, 33, 40, 47],
                    [26, 32, 38, 45],
                    [25, 30, 36, 43],
                ],
                [
                    [26, 31, 36, 41],
                    [24, 28, 33, 38],
                    [22, 25, 30, 34],
                    [19, 23, 27, 31],
                ],
                [
                    [24, 27, 31, 34],
                    [21, 23, 26, 30],
                    [18, 20, 23, 26],
                    [15, 17, 19, 22],
                ],
                [
                    [22, 24, 26, 28],
                    [18, 19, 21, 23],
                    [14, 16, 17, 19],
                    [12, 13, 14, 15],
                ],
                [
                    [15, 16, 17, 19],
                    [13, 14, 15, 16],
                    [11, 12, 13, 13],
                    [9, 10, 11, 11],
                ],
                [
                    [13, 14, 15, 17],
                    [10, 11, 13, 14],
                    [9, 10, 10, 11],
                    [7, 8, 9, 10],
                ],
                [
                    [10, 12, 13, 15],
                    [9, 10, 11, 12],
                    [7, 8, 9, 10],
                    [6, 6, 7, 8],
                ],
                [
                    [9, 10, 11, 13],
                    [7, 8, 9, 10],
                    [6, 6, 7, 8],
                    [4, 5, 6, 7],
                ],
                [
                    [7, 8, 10, 11],
                    [6, 7, 8, 9],
                    [4, 5, 6, 7],
                    [3, 4, 5, 5],
                ],
                [
                    [6, 7, 8, 10],
                    [5, 5, 6, 8],
                    [3, 4, 5, 6],
                    [3, 3, 4, 5],
                ],
            ],
        ],
    ],
    [
        [
            [
                [
                    [37, 39, 40, 42],
                    [35, 36, 38, 39],
                    [32, 34, 35, 37],
                    [30, 32, 33, 34],
                ],
                [
                    [27, 28, 30, 31],
                    [24, 25, 27, 28],
                    [21, 22, 24, 25],
                    [19, 20, 21, 22],
                ],
                [
                    [19, 20, 21, 23],
                    [16, 17, 18, 19],
                    [14, 15, 15, 16],
                    [12, 12, 13, 14],
                ],
                [
                    [13, 14, 15, 16],
                    [11, 11, 12, 13],
                    [9, 9, 10, 11],
                    [7, 7, 8, 8],
                ],
                [
                    [10, 10, 11, 12],
                    [8, 9, 9, 9],
                    [7, 7, 7, 8],
                    [5, 6, 6, 6],
                ],
                [
                    [7, 8, 8, 9],
                    [6, 6, 7, 7],
                    [5, 5, 5, 6],
                    [4, 4, 4, 5],
                ],
                [
                    [5, 6, 6, 7],
                    [4, 4, 5, 5],
                    [3, 3, 4, 4],
                    [3, 3, 3, 3],
                ],
                [
                    [4, 4, 5, 5],
                    [3, 3, 4, 4],
                    [2, 2, 3, 3],
                    [2, 2, 2, 2],
                ],
                [
                    [3, 3, 3, 4],
                    [2, 2, 3, 3],
                    [2, 2, 2, 2],
                    [1, 1, 1, 2],
                ],
                [
                    [2, 2, 3, 3],
                    [1, 2, 2, 2],
                    [1, 1, 1, 2],
                    [1, 1, 1, 1],
                ],
            ],
            [
                [
                    [41, 43, 44, 46],
                    [39, 40, 42, 43],
                    [36, 38, 39, 41],
                    [34, 35, 37, 38],
                ],
                [
                    [34, 35, 37, 39],
                    [30, 32, 33, 35],
                    [27, 28, 30, 31],
                    [24, 25, 27, 28],
                ],
                [
                    [27, 29, 30, 32],
                    [24, 25, 26, 28],
                    [20, 21, 22, 24],
                    [17, 18, 19, 20],
                ],
                [
                    [22, 23, 25, 26],
                    [18, 19, 20, 22],
                    [15, 16, 17, 18],
                    [12, 13, 13, 14],
                ],
                [
                    [15, 16, 17, 18],
                    [13, 13, 14, 15],
                    [10, 11, 12, 12],
                    [9, 9, 9, 10],
                ],
                [
                    [12, 13, 14, 15],
                    [10, 11, 11, 12],
                    [8, 8, 9, 10],
                    [6, 7, 7, 8],
                ],
                [
                    [10, 11, 11, 12],
                    [8, 8, 9, 10],
                    [6, 7, 7, 8],
                    [5, 5, 6, 6],
                ],
                [
                    [8, 8, 9, 10],
                    [6, 6, 7, 8],
                    [5, 5, 6, 6],
                    [3, 4, 4, 5],
                ],
                [
                    [6, 7, 8, 9],
                    [5, 5, 6, 6],
                    [3, 4, 4, 5],
                    [3, 3, 3, 4],
                ],
                [
                    [5, 5, 6, 7],
                    [3, 4, 5, 5],
                    [3, 3, 3, 4],
                    [2, 2, 2, 3],
                ],
            ],
        ],
        [
            [
                [
                    [37, 45, 53, 62],
                    [36, 43, 51, 59],
                    [34, 41, 49, 57],
                    [32, 39, 47, 55],
                ],
                [
                    [30, 35, 41, 47],
                    [27, 32, 37, 43],
                    [25, 29, 34, 40],
                    [22, 26, 31, 36],
                ],
                [
                    [24, 27, 31, 35],
                    [21, 23, 27, 30],
                    [17, 20, 23, 26],
                    [15, 17, 19, 22],
                ],
                [
                    [19, 21, 23, 25],
                    [15, 17, 18, 20],
                    [12, 13, 15, 16],
                    [10, 11, 12, 13],
                ],
                [
                    [14, 15, 17, 18],
                    [12, 13, 14, 15],
                    [10, 11, 12, 13],
                    [8, 9, 10, 10],
                ],
                [
                    [11, 12, 13, 15],
                    [9, 10, 11, 12],
                    [7, 8, 9, 10],
                    [6, 7, 7, 8],
                ],
                [
                    [9, 10, 11, 12],
                    [7, 8, 9, 10],
                    [5, 6, 7, 8],
                    [4, 5, 6, 6],
                ],
                [
                    [7, 8, 9, 10],
                    [5, 6, 7, 8],
                    [4, 5, 5, 6],
                    [3, 4, 4, 5],
                ],
                [
                    [5, 6, 7, 8],
                    [4, 5, 5, 6],
                    [3, 4, 4, 5],
                    [2, 3, 3, 4],
                ],
                [
                    [4, 5, 6, 7],
                    [3, 4, 4, 5],
                    [2, 3, 3, 4],
                    [2, 2, 2, 3],
                ],
            ],
            [
                [
                    [37, 45, 53, 61],
                    [35, 43, 51, 59],
                    [34, 41, 48, 57],
                    [32, 39, 46, 55],
                ],
                [
                    [34, 40, 46, 53],
                    [31, 36, 42, 48],
                    [28, 33, 38, 44],
                    [25, 30, 35, 40],
                ],
                [
                    [31, 35, 39, 44],
                    [27, 30, 34, 38],
                    [23, 26, 29, 33],
                    [19, 22, 25, 29],
                ],
                [
                    [28, 31, 34, 36],
                    [23, 25, 28, 30],
                    [19, 20, 22, 24],
                    [15, 16, 18, 20],
                ],
                [
                    [20, 22, 23, 25],
                    [17, 18, 20, 21],
                    [14, 15, 17, 18],
                    [12, 13, 14, 15],
                ],
                [
                    [17, 18, 20, 22],
                    [14, 15, 17, 18],
                    [11, 13, 14, 15],
                    [9, 10, 11, 12],
                ],
                [
                    [14, 16, 17, 20],
                    [11, 13, 14, 16],
                    [9, 10, 11, 13],
                    [7, 8, 9, 10],
                ],
                [
                    [11, 13, 15, 17],
                    [9, 10, 12, 14],
                    [7, 8, 9, 11],
                    [5, 6, 7, 8],
                ],
                [
                    [9, 11, 13, 15],
                    [7, 8, 10, 12],
                    [5, 7, 8, 9],
                    [4, 5, 6, 7],
                ],
                [
                    [8, 9, 11, 13],
                    [6, 7, 8, 10],
                    [4, 5, 6, 8],
                    [3, 4, 5, 6],
                ],
            ],
        ],
    ],
    [
        [
            [
                [
                    [53, 55, 57, 58],
                    [50, 52, 54, 55],
                    [47, 49, 51, 52],
                    [44, 46, 48, 50],
                ],
                [
                    [40, 42, 44, 45],
                    [36, 38, 39, 41],
                    [32, 34, 36, 37],
                    [29, 31, 32, 34],
                ],
                [
                    [29, 31, 32, 34],
                    [25, 27, 28, 29],
                    [22, 23, 24, 25],
                    [18, 19, 20, 22],
                ],
                [
                    [21, 22, 24, 25],
                    [17, 18, 19, 20],
                    [14, 15, 16, 17],
                    [11, 12, 13, 14],
                ],
                [
                    [15, 16, 17, 18],
                    [12, 13, 14, 14],
                    [10, 10, 11, 11],
                    [8, 8, 8, 9],
                ],
                [
                    [11, 11, 12, 13],
                    [8, 9, 9, 10],
                    [6, 7, 7, 8],
                    [5, 5, 6, 6],
                ],
                [
                    [7, 8, 9, 10],
                    [5, 6, 7, 7],
                    [4, 4, 5, 5],
                    [3, 3, 4, 4],
                ],
                [
                    [5, 5, 6, 7],
                    [3, 4, 4, 5],
                    [3, 3, 3, 4],
                    [2, 2, 2, 3],
                ],
                [
                    [3, 4, 4, 5],
                    [2, 3, 3, 4],
                    [2, 2, 2, 2],
                    [1, 1, 2, 2],
                ],
                [
                    [2, 3, 3, 4],
                    [1, 2, 2, 2],
                    [1, 1, 1, 2],
                    [1, 1, 1, 1],
                ],
            ],
            [
                [
                    [58, 59, 61, 63],
                    [55, 56, 58, 60],
                    [52, 53, 55, 57],
                    [49, 51, 52, 54],
                ],
                [
                    [49, 51, 53, 55],
                    [44, 46, 48, 50],
                    [40, 42, 44, 46],
                    [36, 38, 40, 41],
                ],
                [
                    [41, 43, 45, 47],
                    [35, 37, 39, 41],
                    [31, 32, 34, 36],
                    [26, 28, 29, 31],
                ],
                [
                    [33, 35, 37, 39],
                    [28, 29, 31, 33],
                    [23, 24, 26, 27],
                    [19, 20, 21, 22],
                ],
                [
                    [26, 27, 29, 30],
                    [21, 22, 23, 24],
                    [16, 17, 18, 19],
                    [13, 14, 14, 15],
                ],
                [
                    [20, 21, 23, 25],
                    [15, 16, 18, 19],
                    [12, 13, 14, 15],
                    [9, 10, 11, 11],
                ],
                [
                    [15, 16, 18, 20],
                    [11, 12, 14, 15],
                    [8, 9, 10, 11],
                    [6, 7, 8, 8],
                ],
                [
                    [11, 13, 14, 16],
                    [8, 9, 10, 12],
                    [6, 7, 8, 9],
                    [4, 5, 6, 6],
                ],
                [
                    [8, 10, 11, 13],
                    [6, 7, 8, 9],
                    [4, 5, 6, 6],
                    [3, 3, 4, 5],
                ],
                [
                    [6, 7, 9, 10],
                    [4, 5, 6, 7],
                    [3, 4, 4, 5],
                    [2, 2, 3, 3],
                ],
            ],
        ],
        [
            [
                [
                    [42, 49, 57, 65],
                    [40, 47, 55, 63],
                    [38, 45, 53, 61],
                    [36, 43, 51, 58],
                ],
                [
                    [34, 40, 45, 51],
                    [31, 36, 42, 47],
                    [29, 33, 38, 44],
                    [26, 30, 35, 40],
                ],
                [
                    [28, 32, 35, 39],
                    [24, 27, 31, 34],
                    [21, 24, 27, 30],
                    [18, 20, 23, 26],
                ],
                [
                    [23, 25, 27, 29],
                    [19, 20, 22, 24],
                    [15, 17, 18, 20],
                    [12, 14, 15, 16],
                ],
                [
                    [17, 18, 20, 22],
                    [14, 15, 16, 18],
                    [11, 12, 13, 15],
                    [9, 10, 11, 12],
                ],
                [
                    [13, 14, 16, 18],
                    [10, 11, 13, 14],
                    [8, 9, 10, 11],
                    [6, 7, 8, 9],
                ],
                [
                    [9, 11, 12, 14],
                    [7, 8, 10, 11],
                    [6, 6, 7, 9],
                    [4, 5, 6, 7],
                ],
                [
                    [7, 8, 10, 11],
                    [5, 6, 7, 9],
                    [4, 5, 5, 6],
                    [3, 3, 4, 5],
                ],
                [
                    [5, 6, 8, 9],
                    [4, 5, 6, 7],
                    [3, 3, 4, 5],
                    [2, 2, 3, 4],
                ],
                [
                    [4, 5, 6, 7],
                    [3, 3, 4, 5],
                    [2, 2, 3, 4],
                    [1, 2, 2, 3],
                ],
            ],
            [
                [
                    [41, 49, 56, 65],
                    [40, 47, 54, 62],
                    [38, 45, 52, 60],
                    [36, 43, 50, 58],
                ],
                [
                    [38, 44, 50, 56],
                    [35, 40, 46, 52],
                    [32, 37, 42, 48],
                    [29, 34, 39, 44],
                ],
                [
                    [35, 39, 44, 48],
                    [31, 34, 38, 43],
                    [27, 30, 34, 37],
                    [23, 26, 29, 33],
                ],
                [
                    [33, 35, 38, 41],
                    [27, 29, 32, 34],
                    [22, 24, 26, 28],
                    [18, 20, 22, 23],
                ],
                [
                    [25, 28, 30, 32],
                    [21, 23, 25, 27],
                    [17, 19, 20, 22],
                    [14, 15, 17, 18],
                ],
                [
                    [20, 23, 25, 28],
                    [16, 18, 20, 23],
                    [13, 15, 16, 18],
                    [10, 12, 13, 15],
                ],
                [
                    [16, 19, 21, 24],
                    [13, 15, 17, 19],
                    [10, 11, 13, 15],
                    [8, 9, 10, 12],
                ],
                [
                    [13, 15, 18, 21],
                    [10, 12, 14, 16],
                    [7, 9, 10, 12],
                    [6, 7, 8, 9],
                ],
                [
                    [10, 13, 15, 18],
                    [8, 9, 11, 14],
                    [6, 7, 8, 10],
                    [4, 5, 6, 7],
                ],
                [
                    [8, 10, 13, 16],
                    [6, 7, 9, 11],
                    [4, 5, 7, 8],
                    [3, 4, 5, 6],
                ],
            ],
        ],
    ],
    [
        [
            [
                [
                    [62, 63, 64, 65],
                    [60, 61, 62, 63],
                    [58, 59, 60, 61],
                    [56, 57, 58, 60],
                ],
                [
                    [53, 54, 55, 57],
                    [50, 51, 52, 54],
                    [47, 48, 49, 51],
                    [44, 45, 47, 48],
                ],
                [
                    [44, 46, 47, 48],
                    [41, 42, 43, 45],
                    [37, 39, 40, 41],
                    [34, 35, 36, 37],
                ],
                [
                    [37, 38, 39, 41],
                    [33, 34, 35, 36],
                    [29, 30, 31, 32],
                    [26, 27, 28, 29],
                ],
                [
                    [27, 28, 30, 31],
                    [22, 23, 24, 26],
                    [18, 19, 20, 21],
                    [15, 16, 16, 17],
                ],
                [
                    [20, 21, 22, 24],
                    [16, 17, 18, 19],
                    [12, 13, 14, 15],
                    [10, 11, 11, 12],
                ],
                [
                    [14, 15, 17, 18],
                    [11, 12, 13, 14],
                    [8, 9, 10, 11],
                    [7, 7, 8, 9],
                ],
                [
                    [10, 11, 12, 14],
                    [8, 9, 9, 11],
                    [6, 6, 7, 8],
                    [4, 5, 5, 6],
                ],
                [
                    [7, 8, 9, 10],
                    [5, 6, 7, 8],
                    [4, 4, 5, 6],
                    [3, 3, 4, 4],
                ],
                [
                    [5, 6, 7, 8],
                    [4, 4, 5, 6],
                    [3, 3, 3, 4],
                    [2, 2, 2, 3],
                ],
            ],
            [
                [
                    [65, 66, 67, 68],
                    [63, 64, 65, 66],
                    [61, 62, 63, 65],
                    [59, 60, 61, 63],
                ],
                [
                    [59, 60, 62, 63],
                    [56, 57, 59, 60],
                    [53, 54, 56, 57],
                    [50, 51, 53, 54],
                ],
                [
                    [53, 55, 56, 58],
                    [49, 51, 52, 53],
                    [46, 47, 48, 49],
                    [42, 43, 44, 46],
                ],
                [
                    [48, 49, 51, 52],
                    [43, 44, 46, 47],
                    [39, 40, 41, 43],
                    [34, 36, 37, 38],
                ],
                [
                    [41, 42, 44, 46],
                    [34, 36, 37, 39],
                    [28, 30, 31, 33],
                    [23, 24, 26, 27],
                ],
                [
                    [33, 35, 37, 39],
                    [27, 29, 30, 32],
                    [22, 23, 25, 26],
                    [17, 18, 20, 21],
                ],
                [
                    [26, 28, 31, 33],
                    [21, 23, 24, 26],
                    [16, 18, 19, 21],
                    [13, 14, 15, 16],
                ],
                [
                    [21, 23, 25, 28],
                    [16, 18, 19, 22],
                    [12, 13, 15, 17],
                    [9, 10, 11, 13],
                ],
                [
                    [16, 18, 21, 23],
                    [12, 14, 15, 17],
                    [9, 10, 12, 13],
                    [7, 8, 9, 10],
                ],
                [
                    [13, 15, 17, 19],
                    [9, 11, 12, 14],
                    [7, 8, 9, 10],
                    [5, 6, 6, 7],
                ],
            ],
        ],
        [
            [
                [
                    [49, 54, 59, 64],
                    [48, 53, 58, 63],
                    [47, 52, 56, 61],
                    [46, 50, 55, 60],
                ],
                [
                    [44, 48, 52, 56],
                    [42, 46, 49, 53],
                    [40, 43, 47, 51],
                    [38, 41, 45, 48],
                ],
                [
                    [40, 42, 45, 48],
                    [37, 39, 42, 44],
                    [34, 36, 39, 41],
                    [31, 33, 36, 38],
                ],
                [
                    [35, 37, 39, 40],
                    [32, 33, 35, 36],
                    [28, 30, 31, 33],
                    [25, 26, 28, 29],
                ],
                [
                    [26, 28, 30, 32],
                    [22, 24, 26, 27],
                    [18, 20, 21, 23],
                    [15, 17, 18, 19],
                ],
                [
                    [20, 23, 25, 27],
                    [17, 19, 20, 22],
                    [14, 15, 17, 18],
                    [11, 12, 14, 15],
                ],
                [
                    [16, 18, 20, 23],
                    [13, 14, 16, 18],
                    [10, 11, 13, 15],
                    [8, 9, 10, 12],
                ],
                [
                    [12, 14, 16, 19],
                    [10, 11, 13, 15],
                    [7, 9, 10, 12],
                    [6, 7, 8, 9],
                ],
                [
                    [9, 11, 13, 16],
                    [7, 8, 10, 12],
                    [5, 6, 8, 9],
                    [4, 5, 6, 7],
                ],
                [
                    [7, 9, 11, 13],
                    [5, 6, 8, 10],
                    [4, 5, 6, 7],
                    [3, 4, 4, 5],
                ],
            ],
            [
                [
                    [49, 54, 59, 64],
                    [48, 53, 58, 63],
                    [47, 52, 56, 61],
                    [46, 50, 55, 60],
                ],
                [
                    [47, 51, 55, 59],
                    [45, 49, 52, 56],
                    [43, 46, 50, 54],
                    [40, 44, 48, 51],
                ],
                [
                    [45, 48, 51, 54],
                    [42, 44, 47, 50],
                    [39, 41, 44, 47],
                    [36, 38, 41, 43],
                ],
                [
                    [43, 45, 47, 49],
                    [39, 41, 42, 44],
                    [35, 41, 42, 44],
                    [31, 33, 34, 36],
                ],
                [
                    [36, 39, 42, 44],
                    [31, 33, 36, 38],
                    [26, 28, 30, 33],
                    [22, 24, 26, 28],
                ],
                [
                    [31, 33, 36, 40],
                    [25, 28, 31, 33],
                    [21, 23, 25, 28],
                    [17, 19, 21, 23],
                ],
                [
                    [25, 28, 32, 35],
                    [21, 23, 26, 29],
                    [17, 19, 21, 24],
                    [13, 15, 17, 19],
                ],
                [
                    [21, 24, 28, 31],
                    [17, 19, 22, 25],
                    [13, 15, 17, 20],
                    [10, 12, 14, 16],
                ],
                [
                    [17, 20, 24, 28],
                    [13, 16, 18, 22],
                    [10, 12, 14, 17],
                    [8, 9, 11, 13],
                ],
                [
                    [14, 17, 20, 24],
                    [11, 13, 16, 19],
                    [8, 10, 12, 14],
                    [6, 7, 9, 11],
                ],
            ],
        ],
    ],
];

export const percentAndMmolCorrelation = [
    { p: 4.0, mmol: 20 },
    { p: 4.1, mmol: 21 },
    { p: 4.2, mmol: 22 },
    { p: 4.3, mmol: 23 },
    { p: 4.4, mmol: 25 },
    { p: 4.5, mmol: 26 },
    { p: 4.6, mmol: 27 },
    { p: 4.7, mmol: 28 },
    { p: 4.8, mmol: 29 },
    { p: 4.9, mmol: 30 },
    { p: 5.0, mmol: 31 },
    { p: 5.1, mmol: 32 },
    { p: 5.2, mmol: 33 },
    { p: 5.3, mmol: 34 },
    { p: 5.4, mmol: 36 },
    { p: 5.5, mmol: 37 },
    { p: 5.6, mmol: 38 },
    { p: 5.7, mmol: 39 },
    { p: 5.8, mmol: 40 },
    { p: 5.9, mmol: 41 },
    { p: 6.0, mmol: 42 },
    { p: 6.1, mmol: 43 },
    { p: 6.2, mmol: 44 },
    { p: 6.3, mmol: 45 },
    { p: 6.4, mmol: 46 },
    { p: 6.5, mmol: 48 },
    { p: 6.6, mmol: 49 },
    { p: 6.7, mmol: 50 },
    { p: 6.8, mmol: 51 },
    { p: 6.9, mmol: 52 },
    { p: 7.0, mmol: 53 },
    { p: 7.1, mmol: 54 },
    { p: 7.2, mmol: 55 },
    { p: 7.3, mmol: 56 },
    { p: 7.4, mmol: 57 },
    { p: 7.5, mmol: 58 },
    { p: 7.6, mmol: 60 },
    { p: 7.7, mmol: 61 },
    { p: 7.8, mmol: 62 },
    { p: 7.9, mmol: 63 },
    { p: 8.0, mmol: 64 },
    { p: 8.1, mmol: 65 },
    { p: 8.2, mmol: 66 },
    { p: 8.3, mmol: 67 },
    { p: 8.4, mmol: 68 },
    { p: 8.5, mmol: 69 },
    { p: 8.6, mmol: 70 },
    { p: 8.7, mmol: 72 },
    { p: 8.8, mmol: 73 },
    { p: 8.9, mmol: 74 },
    { p: 9.0, mmol: 75 },
    { p: 9.1, mmol: 76 },
    { p: 9.2, mmol: 77 },
    { p: 9.3, mmol: 78 },
    { p: 9.4, mmol: 79 },
    { p: 9.5, mmol: 80 },
    { p: 9.6, mmol: 81 },
    { p: 9.7, mmol: 83 },
    { p: 9.8, mmol: 84 },
    { p: 9.9, mmol: 85 },
    { p: 10.0, mmol: 86 },
    { p: 10.1, mmol: 87 },
    { p: 10.2, mmol: 88 },
    { p: 10.3, mmol: 89 },
    { p: 10.4, mmol: 90 },
    { p: 10.5, mmol: 91 },
    { p: 10.6, mmol: 92 },
    { p: 10.7, mmol: 93 },
    { p: 10.8, mmol: 95 },
    { p: 10.9, mmol: 96 },
    { p: 11.0, mmol: 97 },
    { p: 11.1, mmol: 98 },
    { p: 11.2, mmol: 99 },
    { p: 11.3, mmol: 100 },
    { p: 11.4, mmol: 101 },
    { p: 11.5, mmol: 102 },
    { p: 11.6, mmol: 103 },
    { p: 11.7, mmol: 104 },
    { p: 11.8, mmol: 105 },
    { p: 11.9, mmol: 107 },
    { p: 12.0, mmol: 108 },
    { p: 12.1, mmol: 109 },
    { p: 12.2, mmol: 110 },
    { p: 12.3, mmol: 111 },
    { p: 12.4, mmol: 112 },
    { p: 12.5, mmol: 114 },
    { p: 12.6, mmol: 115 },
    { p: 12.7, mmol: 116 },
    { p: 12.8, mmol: 117 },
    { p: 12.9, mmol: 118 },
    { p: 13.0, mmol: 119 },
    { p: 13.1, mmol: 121 },
    { p: 13.2, mmol: 122 },
    { p: 13.3, mmol: 123 },
    { p: 13.4, mmol: 124 },
    { p: 13.5, mmol: 125 },
    { p: 13.6, mmol: 126 },
    { p: 13.7, mmol: 127 },
    { p: 13.8, mmol: 128 },
    { p: 13.9, mmol: 129 },
    { p: 14.0, mmol: 130 },
    { p: 14.1, mmol: 131 },
    { p: 14.2, mmol: 132 },
];

export interface AnalyzerPossibleValueProps {
    mark: string;
    concentrationValue: number;
    concetrationUom?: string;
}
export interface ProjectAnalyzerDataProps {
    project: string;
    possibleValue: AnalyzerPossibleValueProps[];
}
export const AscConstant: ProjectAnalyzerDataProps = {
    project: "ASC",
    possibleValue: [
        {
            mark: "-",
            concentrationValue: 0,
            concetrationUom: "mg/dL",
        },
        {
            mark: "1+",
            concentrationValue: 10,
            concetrationUom: "mg/dL",
        },
        {
            mark: "2+",
            concentrationValue: 20,
            concetrationUom: "mg/dL",
        },
        {
            mark: "3+",
            concentrationValue: 40,
            concetrationUom: "mg/dL",
        },
    ],
};

export const LeuConstant: ProjectAnalyzerDataProps = {
    project: "LEU",
    possibleValue: [
        {
            mark: "-",
            concentrationValue: 0,
            concetrationUom: "Leu/μL",
        },
        {
            mark: "±",
            concentrationValue: 15,
            concetrationUom: "Leu/μL",
        },
        {
            mark: "1+",
            concentrationValue: 70,
            concetrationUom: "Leu/μL",
        },
        {
            mark: "2+",
            concentrationValue: 125,
            concetrationUom: "Leu/μL",
        },
        {
            mark: "3+",
            concentrationValue: 500,
            concetrationUom: "Leu/μL",
        },
    ],
};
export const GluConstant: ProjectAnalyzerDataProps = {
    project: "GLU",
    possibleValue: [
        {
            mark: "-",
            concentrationValue: 0,
            concetrationUom: "mg/dL",
        },
        {
            mark: "±",
            concentrationValue: 100,
            concetrationUom: "mg/dL",
        },
        {
            mark: "1+",
            concentrationValue: 250,
            concetrationUom: "mg/dL",
        },
        {
            mark: "2+",
            concentrationValue: 500,
            concetrationUom: "mg/dL",
        },
        {
            mark: "3+",
            concentrationValue: 1000,
            concetrationUom: "mg/dL",
        },
        {
            mark: "4+",
            concentrationValue: 2000,
            concetrationUom: "mg/dL",
        },
    ],
};
export const AlbConstant: ProjectAnalyzerDataProps = {
    project: "ALB",
    possibleValue: [
        {
            mark: "10 mg/L",
            concentrationValue: 10,
            concetrationUom: "mg/L",
        },
        {
            mark: "30 mg/L",
            concentrationValue: 30,
            concetrationUom: "mg/L",
        },
        {
            mark: "80 mg/L",
            concentrationValue: 80,
            concetrationUom: "mg/L",
        },
        {
            mark: "150 mg/L",
            concentrationValue: 150,
            concetrationUom: "mg/L",
        },
    ],
};
export const BilConstant: ProjectAnalyzerDataProps = {
    project: "ASC",
    possibleValue: [
        {
            mark: "-",
            concentrationValue: 0,
            concetrationUom: "mg/dL",
        },
        {
            mark: "1+",
            concentrationValue: 1,
            concetrationUom: "mg/dL",
        },
        {
            mark: "2+",
            concentrationValue: 2,
            concetrationUom: "mg/dL",
        },
        {
            mark: "3+",
            concentrationValue: 4,
            concetrationUom: "mg/dL",
        },
    ],
};
export const PhConstant: ProjectAnalyzerDataProps = {
    project: "Ph",
    possibleValue: [
        {
            mark: "5,0",
            concentrationValue: 5.0,
        },
        {
            mark: "6,0",
            concentrationValue: 6.0,
        },
        {
            mark: "6,5",
            concentrationValue: 6.5,
        },
        {
            mark: "7,0",
            concentrationValue: 7.0,
        },
        {
            mark: "7,5",
            concentrationValue: 7.5,
        },
        {
            mark: "8,0",
            concentrationValue: 8.0,
        },
        {
            mark: "9,0",
            concentrationValue: 9.0,
        },
    ],
};

export const SgConstant: ProjectAnalyzerDataProps = {
    project: "SG",
    possibleValue: [
        {
            mark: "1,000",
            concentrationValue: 1.0,
        },
        {
            mark: "1,005",
            concentrationValue: 1.005,
        },
        {
            mark: "1,010",
            concentrationValue: 1.01,
        },
        {
            mark: "1,015",
            concentrationValue: 1.015,
        },
        {
            mark: "1,020",
            concentrationValue: 1.02,
        },
        {
            mark: "1,025",
            concentrationValue: 1.025,
        },
        {
            mark: "1,030",
            concentrationValue: 1.03,
        },
    ],
};

export const KetConstant: ProjectAnalyzerDataProps = {
    project: "KET",
    possibleValue: [
        {
            mark: "-",
            concentrationValue: 0,
            concetrationUom: "mg/dL",
        },
        {
            mark: "±",
            concentrationValue: 5,
            concetrationUom: "mg/dL",
        },
        {
            mark: "1+",
            concentrationValue: 15,
            concetrationUom: "mg/dL",
        },
        {
            mark: "2+",
            concentrationValue: 40,
            concetrationUom: "mg/dL",
        },
        {
            mark: "3+",
            concentrationValue: 80,
            concetrationUom: "mg/dL",
        },
        {
            mark: "4+",
            concentrationValue: 160,
            concetrationUom: "mg/dL",
        },
    ],
};
export const ProConstant: ProjectAnalyzerDataProps = {
    project: "PRO",
    possibleValue: [
        {
            mark: "-",
            concentrationValue: 0,
            concetrationUom: "mg/dL",
        },
        {
            mark: "±",
            concentrationValue: 15,
            concetrationUom: "mg/dL",
        },
        {
            mark: "1+",
            concentrationValue: 30,
            concetrationUom: "mg/dL",
        },
        {
            mark: "2+",
            concentrationValue: 100,
            concetrationUom: "mg/dL",
        },
        {
            mark: "3+",
            concentrationValue: 300,
            concetrationUom: "mg/dL",
        },
        {
            mark: "4+",
            concentrationValue: 2000,
            concetrationUom: "mg/dL",
        },
    ],
};
export const NitConstant: ProjectAnalyzerDataProps = {
    project: "NIT",
    possibleValue: [
        {
            mark: "-",
            concentrationValue: 0,
            concetrationUom: "mg/dL",
        },
        {
            mark: "+",
            concentrationValue: 0.1,
            concetrationUom: "mg/dL",
        },
    ],
};
export const UroConstant: ProjectAnalyzerDataProps = {
    project: "URO",
    possibleValue: [
        {
            mark: "-",
            concentrationValue: 0.2,
            concetrationUom: "mg/dL",
        },
        {
            mark: "±",
            concentrationValue: 1,
            concetrationUom: "mg/dL",
        },
        {
            mark: "1+",
            concentrationValue: 2,
            concetrationUom: "mg/dL",
        },
        {
            mark: "2+",
            concentrationValue: 4,
            concetrationUom: "mg/dL",
        },
        {
            mark: "3+",
            concentrationValue: 8,
            concetrationUom: "mg/dL",
        },
        {
            mark: "4+",
            concentrationValue: 12,
            concetrationUom: "mg/dL",
        },
    ],
};

export const CreConstant: ProjectAnalyzerDataProps = {
    project: "CRE",
    possibleValue: [
        {
            mark: "10 mg/dL",
            concentrationValue: 10,
            concetrationUom: "mg/dL",
        },
        {
            mark: "50 mg/dL",
            concentrationValue: 50,
            concetrationUom: "mg/dL",
        },
        {
            mark: "100 mg/dL",
            concentrationValue: 100,
            concetrationUom: "mg/dL",
        },
        {
            mark: "200 mg/dL",
            concentrationValue: 200,
            concetrationUom: "mg/dL",
        },
        {
            mark: "300 mg/dL",
            concentrationValue: 300,
            concetrationUom: "mg/dL",
        },
    ],
};

export const BloConstant: ProjectAnalyzerDataProps = {
    project: "BLO",
    possibleValue: [
        {
            mark: "-",
            concentrationValue: 0,
            concetrationUom: "Ery/μL",
        },
        {
            mark: "±",
            concentrationValue: 10,
            concetrationUom: "Ery/μL",
        },
        {
            mark: "1+",
            concentrationValue: 25,
            concetrationUom: "Ery/μL",
        },
        {
            mark: "2+",
            concentrationValue: 80,
            concetrationUom: "Ery/μL",
        },
        {
            mark: "3+",
            concentrationValue: 200,
            concetrationUom: "Ery/μL",
        },
    ],
};

export const CaConstant: ProjectAnalyzerDataProps = {
    project: "CA",
    possibleValue: [
        {
            mark: "4 mg/dL",
            concentrationValue: 4,
            concetrationUom: "mg/dL",
        },
        {
            mark: "10 mg/dL",
            concentrationValue: 10,
            concetrationUom: "mg/dL",
        },
        {
            mark: "20 mg/dL",
            concentrationValue: 20,
            concetrationUom: "mg/dL",
        },
        {
            mark: "30 mg/dL",
            concentrationValue: 30,
            concetrationUom: "mg/dL",
        },
        {
            mark: "40 mg/dL",
            concentrationValue: 40,
            concetrationUom: "mg/dL",
        },
    ],
};

export enum AcrRiskCategory {
    Recheck = "Recheck",
    Normal = "Normal",
    Microalbuminuria = "Microalbuminuria",
    MassiveAlbuminuria = "MassiveAlbuminuria",
}
// This matrix should mirror the table you've provided, with rows as albumin levels and columns as creatinine levels.
// Fill in the actual values from your table in place of 'AcrRiskCategory.X'.
export const acrMatrix: AcrRiskCategory[][] = [
    // Creatinine -> 10, 50, 100, 200, 300
    [
        AcrRiskCategory.Recheck,
        AcrRiskCategory.Normal,
        AcrRiskCategory.Normal,
        AcrRiskCategory.Normal,
        AcrRiskCategory.Normal,
    ], // Albumin 10
    [
        AcrRiskCategory.MassiveAlbuminuria,
        AcrRiskCategory.Microalbuminuria,
        AcrRiskCategory.Microalbuminuria,
        AcrRiskCategory.Normal,
        AcrRiskCategory.Normal,
    ], // Albumin 30
    [
        AcrRiskCategory.MassiveAlbuminuria,
        AcrRiskCategory.Microalbuminuria,
        AcrRiskCategory.Microalbuminuria,
        AcrRiskCategory.Microalbuminuria,
        AcrRiskCategory.Normal,
    ], // Albumin 80
    [
        AcrRiskCategory.MassiveAlbuminuria,
        AcrRiskCategory.MassiveAlbuminuria,
        AcrRiskCategory.Microalbuminuria,
        AcrRiskCategory.Microalbuminuria,
        AcrRiskCategory.Microalbuminuria,
    ], // Albumin 150
];

// Define the thresholds for albumin and creatinine.
export const albuminThresholds: number[] = [10, 30, 80, 150];
export const creatinineThresholds: number[] = [10, 50, 100, 200, 300];
