import { Box, FormControlLabel, Grid, InputAdornment, Stack, Switch, TextField } from "@mui/material";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import RegistryData from "./scaleComponents/registryComponent";
import { theme } from "@/theme";
import InfoBodyData from "./scaleComponents/bofyInfoComponent";
import SmokeData from "./scaleComponents/smokeComponent";
import PressureData from "./scaleComponents/pressureComponent";
import CholesterolData from "./scaleComponents/cholesterolComponent";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import CardiovascularEventsData from "./scaleComponents/cardiovascularEventComponent";
import { CustomButton } from "@/components";
import GlycatedData from "./scaleComponents/glycatedComponent";
import { ClientData } from "@/services/pharmacyService";
import { useEffect } from "react";
import PrivacyData from "./scaleComponents/privacyComponent";
import FooterFormData from "./scaleComponents/footerFormComponent";
import { constants } from "@/utils/constants";
import UrineAnalyzerData from "./scaleComponents/urineAnalyzerComponent";
import {
    AlbConstant,
    AnalyzerPossibleValueProps,
    AscConstant,
    BilConstant,
    BloConstant,
    CaConstant,
    CreConstant,
    GluConstant,
    KetConstant,
    LeuConstant,
    NitConstant,
    PhConstant,
    ProConstant,
    SgConstant,
    UroConstant,
} from "./scalesUtils/constants";
import { findValueByMark } from "./scalesUtils/glycatedScaleModels&Utils";

const DefaultFormData: FormDataUrine = {
    //ANAGRAFICA
    name: "Test",
    surname: "Test",
    country: "italy",
    sex: "male",
    age: 41,
    //Dati fisici
    /* height: 189,
    weight: 83, */
    //Dati fumatore

    //privacy
    privacy_switch: false,

    //urine analyzer
    urineAnalyzerSwitch: false,
    asc: "-",
    leu: "-",
    glu: "-",
    alb: "10 mg/L",
    bil: "-",
    ph: "5,0",
    sg: "1,000",
    ket: "-",
    pro: "-",
    nit: "-",
    uro: "-",
    cre: "10 mg/dL",
    blo: "-",
    ca: "4 mg/dL",
};
export type FormDataUrine = {
    //ANAGRAFICA
    name?: string;
    surname?: string;
    country: string;
    sex: string;
    age: number;
    //Dati fisici
    /*  height?: number;
     weight?: number; */
    //Dati fumatore
    /* actualSmoker: boolean;
    exSmokerSwitch: boolean;
    exSmokerYears?: number; */
    //Pressione
    /* systolicPressure: number;
    diastolicPressure: number; */
    //Cholesterol
    /* totalCholesterol: number;
    hdlCholesterol: number; */
    //creatine
    /* creatinine: number; */
    //eventi cardiovasculari
    //cardiovascularEvent: boolean, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    /* cardiovascularEventSwitch: boolean;
    moreCardiovascularEvent: boolean; */
    //diabet
    //diabetes: boolean, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    /* diabetesSwitch: boolean; */
    privacy_switch?: boolean;

    //glycated
    /* glycatedValue?: number;
    glycatedUom?: string; */

    //urine analyzer
    urineAnalyzerSwitch?: boolean;
    asc?: string | AnalyzerPossibleValueProps;
    leu?: string | AnalyzerPossibleValueProps;
    glu?: string | AnalyzerPossibleValueProps;
    alb?: string | AnalyzerPossibleValueProps;
    bil?: string | AnalyzerPossibleValueProps;
    ph?: string | AnalyzerPossibleValueProps;
    sg?: string | AnalyzerPossibleValueProps;
    ket?: string | AnalyzerPossibleValueProps;
    pro?: string | AnalyzerPossibleValueProps;
    nit?: string | AnalyzerPossibleValueProps;
    uro?: string | AnalyzerPossibleValueProps;
    cre?: string | AnalyzerPossibleValueProps;
    blo?: string | AnalyzerPossibleValueProps;
    ca?: string | AnalyzerPossibleValueProps;
    newAcr?: AnalyzerPossibleValueProps;
};

type FormProps = {
    onSubmit: SubmitHandler<FormDataUrine>;
    selectedClient?: ClientData;
};

export function UrineScaleHookForm({ onSubmit, selectedClient }: FormProps) {
    const formMethods = useForm<FormDataUrine>({
        mode: "onBlur",
        defaultValues: constants.IS_DEBUG ? { ...DefaultFormData } : undefined,
    });
    const lang = useTranslatedMessages();
    useEffect(() => {
        if (selectedClient) {
            formMethods.setValue("name", selectedClient.name);
            formMethods.setValue("surname", selectedClient.surname);
            formMethods.setValue("country", selectedClient.nationality);
        }
    }, [selectedClient]);

    const trasformDataBeforeSumbit = (data: FormDataUrine) => {
        const updateData = { ...data };

        updateData.asc = findValueByMark(updateData.asc as string, AscConstant) || undefined;
        updateData.leu = findValueByMark(updateData.leu as string, LeuConstant) || undefined;
        updateData.glu = findValueByMark(updateData.glu as string, GluConstant) || undefined;
        updateData.alb = findValueByMark(updateData.alb as string, AlbConstant) || undefined;
        updateData.bil = findValueByMark(updateData.bil as string, BilConstant) || undefined;
        updateData.ph = findValueByMark(updateData.ph as string, PhConstant) || undefined;
        updateData.sg = findValueByMark(updateData.sg as string, SgConstant) || undefined;
        updateData.ket = findValueByMark(updateData.ket as string, KetConstant) || undefined;
        updateData.pro = findValueByMark(updateData.pro as string, ProConstant) || undefined;
        updateData.nit = findValueByMark(updateData.nit as string, NitConstant) || undefined;
        updateData.uro = findValueByMark(updateData.uro as string, UroConstant) || undefined;
        updateData.cre = findValueByMark(updateData.cre as string, CreConstant) || undefined;
        updateData.blo = findValueByMark(updateData.blo as string, BloConstant) || undefined;
        updateData.ca = findValueByMark(updateData.ca as string, CaConstant) || undefined;
        onSubmit(updateData);
    };

    return (
        <FormProvider {...formMethods}>
            <Box
                sx={{
                    borderRadius: "20px 20px 20px 20px",
                    backgroundColor: theme.palette.primary.light,
                }}
            >
                <form onSubmit={formMethods.handleSubmit(trasformDataBeforeSumbit)}>
                    <Stack gap={3} p={3}>
                        <Grid container spacing={3} >

                            <Grid item xs={12}  >
                                <Box >
                                    <Stack gap={3}>
                                        <RegistryData
                                            disabledPatientData={selectedClient ? true : false}
                                            defaultCountry={selectedClient?.nationality}
                                        />

                                    </Stack>
                                </Box>

                            </Grid>


                            <Grid item xs={12} >
                                <Box>
                                    <Stack >
                                        <UrineAnalyzerData showSwitchPhrase={false} />
                                        <PrivacyData />
                                        <FooterFormData />
                                    </Stack>
                                </Box>

                            </Grid>





                        </Grid>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"center"} mt={2}>
                        <Box>
                            <CustomButton
                                sx={{
                                    backgroundColor: theme.palette.secondary.dark,
                                    color: theme.palette.primary.light,
                                }}
                                label={lang.scales.shared.forms.calculate}
                                type="submit"
                            />
                        </Box>

                    </Stack>
                </form>
            </Box>
        </FormProvider>
    );
}
