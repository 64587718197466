import { Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import ViewAppBar from "@/components/newGraphics/ViewAppBar";
import DetailTables from "@/components/newGraphics/DetailTables";
import { useLocation } from "react-router-dom";
import { ClientData } from "@/services/pharmacyService";

export default function ClientDetail() {
    const location = useLocation();
    const [client, setClient] = useState<ClientData | null>(null); // Inizialmente impostato a null

    const hasInizalized = useRef(false);
    useEffect(() => {
        if (!hasInizalized.current) {
            hasInizalized.current = true;
            setClient(location.state);
        }
    }, [hasInizalized]);
    return (
        <>
            {client ? (
                <Stack>
                    <ViewAppBar />
                    <DetailTables client={client} />
                </Stack>
            ) : null}
        </>
    );
}
