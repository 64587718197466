import { Box, Grid, Stack, } from "@mui/material";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import RegistryData from "./scaleComponents/registryComponent";
import { theme } from "@/theme";

import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { CustomButton } from "@/components";
import GlycatedData from "./scaleComponents/glycatedComponent";
import { ClientData } from "@/services/pharmacyService";
import { useEffect } from "react";
import PrivacyData from "./scaleComponents/privacyComponent";
import FooterFormData from "./scaleComponents/footerFormComponent";
import { constants } from "@/utils/constants";
import DiabetData from "./scaleComponents/diabetComponent";

const DefaultFormData: FormDataEmo = {
    //ANAGRAFICA
    name: "Test",
    surname: "Test",
    country: "italy",
    sex: "male",
    age: 41,
    diabetesSwitch: false,
    diabetesSelect: "diabete1",
    glycatedValue: 41,
    glycatedUom: "mmol/mol",
    privacy_switch: false,

};
export type FormDataEmo = {
    name?: string;
    surname?: string;
    country: string;
    sex: string;
    age: number;
    diabetesSwitch: boolean;
    diabetesSelect?: string;
    privacy_switch?: boolean;
    glycatedValue?: number;
    glycatedUom?: string;
};

type FormProps = {
    onSubmit: SubmitHandler<FormDataEmo>;
    selectedClient?: ClientData;
};

export function EmoglobinaScaleHookForm({ onSubmit, selectedClient }: FormProps) {
    const formMethods = useForm<FormDataEmo>({
        mode: "onBlur",
        defaultValues: constants.IS_DEBUG ? { ...DefaultFormData } : undefined,
    });
    const lang = useTranslatedMessages();
    useEffect(() => {
        if (selectedClient) {
            formMethods.setValue("name", selectedClient.name);
            formMethods.setValue("surname", selectedClient.surname);
            formMethods.setValue("country", selectedClient.nationality);
        }
    }, [selectedClient]);



    return (
        <FormProvider {...formMethods}>
            <Box
                sx={{
                    borderRadius: "20px 20px 20px 20px",
                    backgroundColor: theme.palette.primary.light,
                }}
            >
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Stack gap={3} p={3}>
                        <Grid container spacing={3} >

                            <Grid item xs={12} md={6} >
                                <Box >
                                    <Stack gap={3}>
                                        <RegistryData
                                            disabledPatientData={selectedClient ? true : false}
                                            defaultCountry={selectedClient?.nationality}
                                        />


                                    </Stack>
                                </Box>

                            </Grid>


                            <Grid item xs={12} md={6}>
                                <Box >
                                    <Stack gap={3}>

                                        <GlycatedData />

                                        <DiabetData showDiabetAge={false} showDiabetesDiseases={false} />
                                    </Stack>
                                </Box>

                            </Grid>


                            <Grid item xs={12} >
                                <Box>
                                    <Stack >


                                        <PrivacyData />
                                        <FooterFormData />
                                    </Stack>
                                </Box>

                            </Grid>



                            {/* <RegistryData
                            disabledPatientData={selectedClient ? true : false}
                            defaultCountry={selectedClient?.nationality}
                        />
                       
                        <SmokeData /> */}
                            {/* <PressureData diastolicPressureRequired={false} />
                        <CholesterolData triglycerides={false} />
                        <Stack>
                            <TextField
                                {...formMethods.register("creatinine", {
                                    valueAsNumber: true,

                                    validate: (value) => {
                                        if (!value) {
                                            return true;
                                        }
                                        if (value < 0.1 || value > 10.3) {
                                            return lang.scales.scaleWithComponentShared.creatinine
                                                .errorText;
                                        }
                                        return true;
                                    },
                                })}
                                inputProps={{
                                    "data-testid": "form-component-creatinine",
                                    step: ".01",
                                    onWheel: (event: any) => {
                                        event.target.blur();
                                    },
                                }}
                                label={<>{lang.scales.scaleWithComponentShared.creatinine.label}</>}
                                type="number"
                                fullWidth
                                error={!!formMethods.formState.errors.creatinine}
                                helperText={formMethods.formState.errors.creatinine?.message || ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">mg/dL</InputAdornment>
                                    ),
                                }}
                            />
                        </Stack> */}

                            {/* <Stack>
                            <FormControlLabel
                                control={<Switch {...formMethods.register("diabetesSwitch")} />}
                                label={lang.scales.scaleWithComponentShared.diabete2.label}
                                labelPlacement="start"
                                sx={{
                                    marginLeft: 0,
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    flexGrow: 1,
                                    "&.MuiFormControlLabel-labelPlacementStart": {
                                        justifyContent: "space-between",
                                    },
                                }}
                            />
                        </Stack> */}

                            {/* <GlycatedData /> */}

                            {/* <CardiovascularEventsData arteriopathy={false} />

                        <UrineAnalyzerData />

                        <PrivacyData /> */}

                        </Grid>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"center"} mt={2}>
                        <Box>
                            <CustomButton
                                sx={{
                                    backgroundColor: theme.palette.secondary.dark,
                                    color: theme.palette.primary.light,
                                }}
                                label={lang.scales.shared.forms.calculate}
                                type="submit"
                            />
                        </Box>
                        {/* <Box >
                            <CustomButton
                                sx={{ backgroundColor: theme.palette.secondary.dark, color: theme.palette.primary.light }}

                                label="Errori"
                                onClick={() => printErrors(formMethods.formState.errors)}
                            />

                        </Box> */}
                    </Stack>
                </form>
            </Box>
        </FormProvider>
    );
}
