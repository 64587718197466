import { Network } from "./network";

export interface ReportProps {
    reportRequest: (report: { message: string; type: string }) => Promise<void>;
    contactRequest: (contact: { message: string; name: string; email: string }) => Promise<void>;
}
export const useReportService = (): ReportProps => {
    const reportRequest = async (report: { message: string; type: string }) => {
        await Network.Post("/Assistance/sendRequest", report);
    };
    const contactRequest = async (contact: { message: string; name: string; email: string }) => {
        await Network.Post("/Assistance/sendContactRequest", contact);
    };
    return { reportRequest, contactRequest };
};
