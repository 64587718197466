import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Button, Container, Stack, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

interface subPage {
    name: string;
    path: string;
    translatorName: string;
    childrenComponentsPaths: string[];
}

const ReservedAreaSubPages: subPage[] = [
    {
        name: "Recenti",
        path: "/reserved_area",
        translatorName: "reservedAreaRecent",
        childrenComponentsPaths: [],
    },
    {
        name: "Clienti",
        path: "/reserved_area/clients",
        translatorName: "reservedAreaClients",
        childrenComponentsPaths: ["/reserved_area/detail"],
    },
    {
        name: "Profilo",
        path: "/reserved_area/users",
        translatorName: "reservedAreaProfile",
        childrenComponentsPaths: [],
    },
];
export default function ViewAppBar() {
    const translation = useTranslatedMessages();
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();

    function setColorElementInToolbar(page: subPage) {
        if (
            location.pathname === page.path ||
            page.childrenComponentsPaths.includes(location.pathname)
        ) {
            return true;
        }

        return false;
    }

    return (
        <Box>
            <AppBar
                position="static"
                sx={{ backgroundColor: theme.palette.secondary.dark, boxShadow: "none" }}
            >
                <Toolbar
                    className="Ciao"
                    sx={{ marginX: "0px !important", paddingX: "0px !important" }}
                >
                    <Container>
                        <Stack flexDirection={"row"}>
                            {/* Other buttons */}

                            {location.pathname.startsWith("/reserved_area") &&
                                ReservedAreaSubPages.map((subPage, index) => (
                                    <Button
                                        key={index}
                                        component="div"
                                        sx={{
                                            cursor: "pointer",
                                            marginRight: "25px",
                                            borderRadius: "10px", //currentPath === objectPage.path || currentPath.startsWith(objectPage.path + '/')
                                            backgroundColor: setColorElementInToolbar(subPage)
                                                ? theme.palette.secondary.light
                                                : "none",
                                            color: theme.palette.primary.light,
                                            padding: "5px 15px",
                                            "&:hover": {
                                                backgroundColor:
                                                    location.pathname === subPage.path
                                                        ? theme.palette.secondary.light
                                                        : "none",
                                                boxShadow: "none",
                                            },
                                        }}
                                        onClick={() => {
                                            navigate(subPage.path);
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        {translation.pages[subPage.translatorName].name}
                                    </Button>
                                ))}
                        </Stack>
                    </Container>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
