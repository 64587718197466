import Box from "@mui/material/Box";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useState } from "react";
import { theme } from "../../theme";
import { GreenButton } from "./GreenButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import { ClientData } from "@/services/pharmacyService";
import { AddClientDialog } from "@/dialogs/AddClientDialog";
import { useNavigate } from "react-router-dom";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { getColumnsHeaderVariableTranslatorName } from "@/utils/utils";
export type UserDetails = {
    [key: string]: string;
};

export default function ClientsTable({
    clients,
    reloadClients,
}: {
    clients: ClientData[];
    reloadClients: () => void;
}) {
    const lang = useTranslatedMessages();
    const [openDialogs, setOpenDialogs] = useState({
        addClientDialog: false,
    });

    function switchVisibilityDialog(name: keyof typeof openDialogs) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }
    const columns: GridColDef[] = [
        {
            field: "name",
            headerName: lang.tables.client.name,
            width: 250,
        },
        {
            field: "email",
            headerName: lang.tables.client.email,
            width: 250,
        },
        {
            field: "scalesFilledIn",
            headerName: lang.tables.client.scaleNumber,
            width: 200,
        },
        {
            field: "lastUpdate",
            headerName: lang.tables.client.lastUpdate,
            width: 200,
        },
        {
            field: "actions", // Nome del campo della colonna
            headerName: "", // Header della colonna
            width: 200,
            align: "right",
            renderCell: (params) => (
                <div>
                    <GreenButton
                        text="Dettagli"
                        onClickProp={() => {
                            handleButtonClick(params.row); // passo i parameetri row
                            //setIsDetails(true)
                        }}
                    />
                </div>
            ),
        },
    ];

    const navigate = useNavigate();
    //const [currentParams, setCurrentParams] = useState<any>({});
    const handleButtonClick = (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
    ) => {
        // Implementa la logica per l'azione del pulsante qui
        const client = { ...params };

        navigate("/reserved_area/detail", { state: client });
        window.scrollTo(0, 0);
        //setCurrentParams(params)
    };
    //const [isDetails, setIsDetails] = useState<boolean>(false)

    return (
        <>
            <Box sx={{ backgroundColor: theme.palette.background.default }}>
                <Container fixed>
                    <AddClientDialog
                        isOpen={openDialogs.addClientDialog}
                        closeDialog={() => {
                            switchVisibilityDialog("addClientDialog");
                            reloadClients();
                        }}
                    />
                    <Stack sx={{ margin: "20px" }}>
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography
                                sx={{ fontSize: " 1.2rem", color: theme.palette.primary.main }}
                            >
                                {lang.pages.reservedAreaClients.tableTitle}
                            </Typography>
                            <GreenButton
                                text={lang.pages.reservedAreaClients.addCompilationDialogHeader}
                                endIcon={<AddIcon />}
                                onClickProp={() => {
                                    switchVisibilityDialog("addClientDialog");
                                }}
                            />
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            height: "40rem",
                            width: "100%",
                            borderRadius: "10px",
                            marginBottom: "4.3rem",
                            boxShadow:
                                "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                        }}
                    >
                        <DataGrid
                            rows={clients}
                            columns={columns}
                            disableRowSelectionOnClick={true}
                            localeText={{
                                noRowsLabel: lang.tables.client.noRowsLabel, // Personalizza il testo per le righe vuote
                                ...getColumnsHeaderVariableTranslatorName(lang),
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    color: theme.palette.primary.dark,
                                    fontSize: "0.9rem",
                                    fontWeight: "bold",
                                },
                            }}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
}
