import { useFormContext } from "react-hook-form";
import { FormControlLabel, Stack, Switch, IconButton, Popover, Typography } from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import HelpIcon from "@mui/icons-material/Help";
import { useEffect, useState } from "react";
import { theme } from "@/theme";

interface InputData {
    arteriopathy?: boolean;
}
function CardiovascularEventsData({ arteriopathy = true }: InputData) {
    const lang = useTranslatedMessages();
    const { watch, setValue, register } = useFormContext();
    const [infoTextIsOpen, setInfoTextIsOpen] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const isCardiovascularEvent = watch("cardiovascularEventSwitch");

    useEffect(() => {
        if (isCardiovascularEvent) {
            setValue("moreCardiovascularEvent", false);
        }
    }, [isCardiovascularEvent, setValue]);

    return (
        <Stack gap={0}>
            {/* <Typography variant='h4'>{lang.scales.cardiovascularEventsComponent.title} </Typography>
             */}

            <FormControlLabel
                control={
                    <Stack flexDirection={"row"} alignItems={"center"}>
                        <Switch
                            {...register("cardiovascularEventSwitch")}
                            data-testid="form-component-cardiovascularEventSwitch"
                            onChange={(e) =>
                                setValue("cardiovascularEventSwitch", e.target.checked)
                            }
                        />
                        <IconButton
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setInfoText(
                                    lang.scales.cardiovascularEventsComponent.cardiovascularEvent
                                        .infoText
                                );
                                setInfoTextIsOpen(true);
                            }}
                            edge="end"
                        >
                            <HelpIcon sx={{ color: theme.palette.secondary.light }} />
                        </IconButton>
                    </Stack>
                }
                label={lang.scales.cardiovascularEventsComponent.cardiovascularEvent.label}
                labelPlacement="start"
                sx={{
                    marginLeft: 0,
                    marginTop: "10px",
                    marginBottom: "10px",
                    flexGrow: 1,
                    "&.MuiFormControlLabel-labelPlacementStart": {
                        justifyContent: "space-between",
                    },
                }}
            />

            {isCardiovascularEvent && (
                <FormControlLabel
                    control={
                        <Switch
                            {...register("moreCardiovascularEvent")}
                            data-testid="form-component-moreCardiovascularEvent"
                            onChange={(e) => setValue("moreCardiovascularEvent", e.target.checked)}
                        />
                    }
                    label={
                        lang.scales.cardiovascularEventsComponent.cardiovascularEvent
                            .moreCardiovascularEvent
                    }
                    labelPlacement="start"
                    sx={{
                        marginLeft: 0,
                        marginTop: "10px",
                        marginBottom: "10px",
                        flexGrow: 1,
                        "&.MuiFormControlLabel-labelPlacementStart": {
                            justifyContent: "space-between",
                        },
                    }}
                />
            )}

            {arteriopathy ? (
                <FormControlLabel
                    control={
                        <Stack flexDirection={"row"} alignItems={"center"}>
                            <Switch
                                {...register("arteriopathy")}
                                data-testid="form-component-arteriopathy"
                                onChange={(e) => setValue("arteriopathy", e.target.checked)}
                            />
                            <IconButton
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setInfoText(
                                        lang.scales.cardiovascularEventsComponent.arteriopathy
                                            .infoText
                                    );
                                    setInfoTextIsOpen(true);
                                }}
                                edge="end"
                            >
                                <HelpIcon sx={{ color: theme.palette.secondary.light }} />
                            </IconButton>
                        </Stack>
                    }
                    label={lang.scales.cardiovascularEventsComponent.arteriopathy.label}
                    labelPlacement="start"
                    sx={{
                        marginLeft: 0,
                        marginTop: "10px",
                        marginBottom: "10px",
                        flexGrow: 1,
                        "&.MuiFormControlLabel-labelPlacementStart": {
                            justifyContent: "space-between",
                        },
                    }}

                />
            ) : null}
            <Popover
                open={infoTextIsOpen}
                anchorEl={anchorEl}
                onClose={() => {
                    setInfoTextIsOpen(false);
                }}
                disableScrollLock
                transformOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Typography sx={{ p: 2 }}>{infoText}</Typography>
            </Popover>
        </Stack>
    );
}

export default CardiovascularEventsData;
