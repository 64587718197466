import CookieConsent from "react-cookie-consent";
import { Link } from "@mui/material";
import { CustomButton, CustomButtonProps } from "./CustomButton";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

const CookieButton = ({
    children,
    ...rest
}: { children: string } & Omit<CustomButtonProps, "label" | "margin">) => {
    return <CustomButton label={children} {...rest} margin={"0.5em"} />;
};

const CookiePolicy = () => {
    const lang = useTranslatedMessages();
    return (
        <CookieConsent
            location="bottom"
            buttonText={lang.cookie.CookieAcceptText}
            //declineButtonText={useIntl().formatMessage({ id: "CookieDeclineText" })}
            ButtonComponent={CookieButton}
            cookieName="HumtelemedCookie"
            style={{ background: "#2B373B", padding: 16, width: "-webkit-fill-available" }}
            buttonWrapperClasses={"column"}
            expires={365}
            acceptOnScroll
            //overlay
            //flipButtons
            //declineCookieValue={"OnlyTechnicalCookies"}
            cookieValue={"Cookies"}
        >
            {lang.cookie.CookieIntro}{" "}
            <Link href="privacy" style={{}}>
                {lang.pages.privacy.privacyPolicyTitle.toLowerCase()}
            </Link>
            .
        </CookieConsent>
    );
};

export { CookiePolicy };
