import { createBrowserRouter, RouterProvider, Outlet, RouteObject } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useAuthService } from "@/services/authService";
import { Suspense } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import { ProtectedRoute } from "./ProtectedRoute";
import { CookiePolicy } from "@/components";
import TutorialPage from "@/pages/TutorialPage";
import PrivacyPage from "@/pages/PrivacyPage";
import ScalePage from "@/pages/ScalePage";
import { ResetPasswordPage } from "../ResetPasswordPage";
import ErrorPage from "../ErrorPage";
import Home from "../newGraphicsPages/Home";
import AboutUs from "@/pages/newGraphicsPages/AboutUs";
import CustomToolbar from "@/components/newGraphics/CustomToolbar";
import { Footer } from "@/components/newGraphics/Footer";
import Profile from "../newGraphicsPages/reservedArea/subPages/Profile";
import Client from "../newGraphicsPages/reservedArea/subPages/Client";
import Recent from "../newGraphicsPages/reservedArea/subPages/Recent";
import ClientDetail from "../newGraphicsPages/reservedArea/subPages/ClientDetail";

export function AnimatedRouter() {
    const { isLoggedIn } = useAuthService();

    const Root = () => {
        const child = Outlet({});
        return (
            <Stack flexDirection={"column"}>
                <CustomToolbar />
                <Box
                    sx={{
                        left: "calc(50% - 20px)",
                        top: "calc(50% - 22px)",
                        position: "absolute",
                        display: !!child ? "none" : "visible",
                    }}
                >
                    <CircularProgress
                        sx={{
                            color: "white",
                        }}
                    />
                </Box>
                <Box sx={{ minHeight: "60vh" }}>{child}</Box>
                <CookiePolicy />
                <Footer />
            </Stack>
        );
    };

    const checkAuthLoader = async () => {
        const _isLoggedIn = isLoggedIn();
        return _isLoggedIn;
    };

    const routes: RouteObject[] = [
        {
            path: "/",
            element: <Root />,
            errorElement: <ErrorPage />,
            loader: checkAuthLoader,
            children: [
                {
                    path: "",
                    element: <Home />,
                },
                {
                    path: "about_us",
                    element: <AboutUs />,
                },
                {
                    path: "reserved_area",
                    children: [
                        {
                            path: "",
                            element: (
                                <ProtectedRoute redirectPath="">
                                    <Recent />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: "clients",
                            element: (
                                <ProtectedRoute redirectPath="">
                                    <Client />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: "detail",
                            element: (
                                <ProtectedRoute redirectPath="">
                                    <ClientDetail />
                                </ProtectedRoute>
                            ),
                        },
                        {
                            path: "users",
                            element: (
                                <ProtectedRoute redirectPath="">
                                    <Profile />
                                </ProtectedRoute>
                            ),
                        },
                    ],
                },
                {
                    path: "tutorial",
                    element: <TutorialPage />,
                },
                {
                    path: "privacy",
                    element: <PrivacyPage />,
                },

                {
                    path: "scale/:scaleName",
                    element: <ScalePage />,
                },
            ],
        },
        {
            path: "resetPassword",
            element: <ResetPasswordPage />,
        },
    ];

    const router = createBrowserRouter(routes);
    return (
        <AnimatePresence>
            <Suspense>
                <RouterProvider router={router} />
            </Suspense>
        </AnimatePresence>
    );
}
