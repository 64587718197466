import { useFormContext, Controller } from "react-hook-form";
import { Typography, Box, Link, Checkbox } from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

import { theme } from "@/theme";

function PrivacyData() {
    const lang = useTranslatedMessages();
    const { control } = useFormContext();

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ textAlign: "left" }} variant="h5">
                {lang.scales.shared.forms.privacy1}
                <Link color={theme.palette.secondary.light} href="\privacy" target={"_blank"}>
                    {lang.scales.shared.forms.privacyLink}
                </Link>
                *
            </Typography>
            <Controller
                name="privacy_switch"
                control={control}
                defaultValue={false}
                rules={{ required: true }}
                render={({ field }) => (
                    <Checkbox
                        {...field}
                        inputProps={{ "data-testid": "form-component-privacy_switch" } as any}
                        //data-testid="form-component-privacy_switch"
                        checked={field.value}
                        onChange={(e) => {
                            field.onChange(e);
                        }}
                    />
                )}
            />
        </Box>
    );
}

export default PrivacyData;
