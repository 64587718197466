
import { useLocalStorageService } from "@/states/localStorageState";
import axios, { AxiosError } from "axios";
import { constants } from "../utils/constants";


//TODO: sistemare tipi di dati inviati e scaleindexes
enum NetworkResolutionTypes {
    RETRY,
    ERROR,
    REFRESH_TOKEN,
}

const Errors = () => {
    return {
        MAX_ATTEMPTS: Error("Max attempts reached"),
    };
};

/**
 * @description Allows connection between the client and the RESTful API on the server
 */

class Network {
    private static getAuthHeaders(config?: any) {
        const token = localStorage.getItem("accessToken");
        const _config = {
            ...config,
            withCredentials: true,
            headers: {
                ...(config?.headers || {}),
                ...(token ? { Authorization: `Bearer ${token}` } : {})
            },
        };
        return _config;
    }

    /**
     * @param {any} data
     * @returns {Promise<any>} generated report
     * @description Send calculated data anonymously
     */
    static sendScaleData(data: any): Promise<any> {
        const token = localStorage.getItem("accessToken");
        return this.Post(`/Scale${token ? `/createAsUser` : ""}`, { ...data, isDownloaded: false });
    }

    /**
     * @argument reportId: Requires the ID of the report generated by the previous call to the sendScaleData function.
     * @returns ID of generated report
     * @description Update report data specifying that the report has been downloaded
     */
    static async setScaleAsDownloaded(reportId: string | null) {
        if (reportId) await this.Post(`/Scale/setDownloaded/${reportId}`);
        else console.warn("undefined report ID");
    }

    private static async refreshToken() {
        //quì evito di inserire il try catch in modo tale che se la funzione dovesse andare in errore sarà chi la chiama a doversi preoccupare di come gestirla, se invece va a buon fine viene eseguito anche il pezzo di codice sottostante
        const res = await axios.post(
            `${constants.BASE_URL}/Auth/refresh`,
            {},
            Network.getAuthHeaders()
        );
        localStorage.setItem("accessToken", res.data.accessToken);
    }

    private static evalError(error: AxiosError): NetworkResolutionTypes {
        const status = error.response?.status;
        switch (status) {
            case 401:
                return NetworkResolutionTypes.REFRESH_TOKEN;
        }
        return NetworkResolutionTypes.ERROR;
    }

    private static async resolveError(error: AxiosError, callback: () => Promise<any>) {
        switch (Network.evalError(error)) {
            case NetworkResolutionTypes.REFRESH_TOKEN:
                try {
                    await Network.refreshToken();
                } catch (e) {
                    if ((e as AxiosError).response?.status === 400) {
                        await Network.Post("/Auth/logout");
                        localStorage.removeItem("accessToken");
                        useLocalStorageService.getState().setUser(null);
                        useLocalStorageService.getState().setLoading(false);
                        useLocalStorageService.getState().setShowSessionExpiredDialog(true);
                    }
                    throw error;
                }
                return await callback();
            case NetworkResolutionTypes.RETRY:
                return await callback();
            default:
                throw error;
        }
    }

    /**
     * TODO: documentare
     * @argument
     * @returns
     * @description
     */
    static async Get(path: string, config?: object, params?: string, attempt = 0): Promise<any> {
        if (attempt < constants.MAX_ATTEMPT) {
            try {
                let response = await axios.get(
                    `${constants.BASE_URL}${path}${params || ""}`,
                    Network.getAuthHeaders(config)
                );
                return response.data;
            } catch (error) {
                return await Network.resolveError(error as AxiosError, () =>
                    Network.Get(path, config, params, attempt++)
                );
            }
        }
        throw Errors().MAX_ATTEMPTS;
    }

    /**
     * TODO: documentare
     * @argument
     * @returns
     * @description
     */
    static async Post(
        path: string,
        body: object = {},
        config?: object,
        params?: string,
        attempt = 0
    ): Promise<any> {
        if (attempt < constants.MAX_ATTEMPT) {
            try {
                let response = await axios.post(
                    `${constants.BASE_URL}${path}${params || ""}`,
                    { ...body },
                    Network.getAuthHeaders(config)
                );
                return response.data;
            } catch (error) {
                return await Network.resolveError(error as AxiosError, () =>
                    Network.Post(path, body, config, params, attempt++)
                );
            }
        }
        throw Errors().MAX_ATTEMPTS;
    }


    static async DownloadPdf(path: string, config?: object): Promise<void> {
        try {
            const response = await axios.get(`${constants.BASE_URL}${path}`, {
                ...Network.getAuthHeaders(config),

                responseType: 'blob'  // Importante per il download di file binari come PDF
            });
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = window.URL.createObjectURL(pdfBlob);
            //questo apre direttamente il pdf in un altra pagina     
            /* window.open(fileURL, '_blank'); */

            //questo avvia il download
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = "Scala.pdf";

            document.body.appendChild(link);
            link.click();
            /* document.body.removeChild(link);


            window.URL.revokeObjectURL(fileURL); */
        } catch (error) {
            throw error

        }
    }
}

export { Network };
