import { Dialog, DialogContent, Stack, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { CustomButton } from "../components";
import { DialogProps } from ".";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { useContext, useState } from "react";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";
import { getPdf, getPdfGuest } from "@/services/scaleService";
import { ErrorDialog } from "./ErrorDialog";
import { useLocalStorageService } from "@/states/localStorageState";
import { AppCtx } from "@/multiLanguage/LanguagesWrapper";

type FirstResultDialogProps = {
    scaleName: string;
    reportId: string | null;
    scaleIndexes?: any;
    reportPrivateKey?: string | null;
} & DialogProps;

export function FirstResultDialog({
    isOpen,
    closeDialog,
    reportId,
    scaleIndexes,
    reportPrivateKey,
    scaleName,
}: FirstResultDialogProps) {
    const { user } = useLocalStorageService();
    const { palette } = useTheme();
    const { locale } = useContext(AppCtx);
    const navigate = useNavigate();
    const lang = useTranslatedMessages();
    const [error, setError] = useState<string>("");
    const [openDialogs, setOpenDialogs] = useState({ firstResult: false, errorDialog: false });
    function switchVisibilityDialog(name: string) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }
    return (
        <>
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                open={isOpen}
                onClose={() => {
                    closeDialog();
                }}
                PaperProps={{
                    style: { borderRadius: 0 },
                }}
            >
                <DialogHeaderComponent title={lang.pdf.scales[scaleName].result.title} />
                <DialogContent
                    sx={{ paddingLeft: "1rem", paddingRight: "1rem", paddingBottom: "0rem" }}
                >
                    {scaleIndexes ?
                        <Stack width={"100%"} alignItems="center" gap={1}>
                            {scaleName === "lopreHolder" ||
                                (scaleName === "scaleGlycated" && (
                                    <>
                                        <Typography
                                            variant="h5"
                                            sx={{ fontWeight: 400 }}
                                            /* color={palette.primary.main} */
                                            align="center"
                                            padding="10px 0 10px 0"
                                            textTransform="uppercase"
                                        >
                                            {lang.pdf.scales.lopreHolder.result.score2}
                                        </Typography>
                                        <Typography
                                            variant="h3"
                                            sx={{ fontWeight: 700 }}
                                            /* color={palette.primary.main} */
                                            align="center"
                                            padding="0 0 10px 0"
                                            textTransform="capitalize"
                                        >
                                            {scaleIndexes.score2}%
                                        </Typography>
                                    </>
                                ))}
                            <Typography
                                variant="h5"
                                sx={{ fontWeight: 400 }}
                                /* color={palette.primary.main} */
                                align="center"
                                padding="10px 0 10px 0"
                                textTransform="uppercase"
                            >
                                {lang.pdf.scales[scaleName].result.estimatedRisk}
                            </Typography>
                            <Typography
                                variant="h3"
                                sx={{ fontWeight: 700 }}
                                color={palette.primary.dark}
                                align="center"
                                padding="0 0 10px 0"
                                textTransform="capitalize"
                            >
                                {lang.scales.shared.riskLevels[scaleIndexes.riskLevel]}
                            </Typography>
                            <Typography variant="h5" style={{ fontWeight: 400, textAlign: "left" }}>
                                {lang.pdf.scales[scaleName].result.eventProbability +
                                    " " +
                                    scaleIndexes.eventProbability +
                                    "."}
                            </Typography>
                        </Stack>
                        : <Stack><Typography variant="h5" style={{ fontWeight: 400, textAlign: "left" }}>
                            {lang.pdf.scales[scaleName].result.subtitle}
                        </Typography></Stack>}
                    <Stack
                        width={"100%"}
                        gap={1}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                    >
                        <CustomButton
                            label={lang.shared.exit}
                            secondary
                            sx={{
                                width: "100%",
                                borderColor: palette.secondary.dark,
                                border: "1px solid",
                                backgroundColor: palette.primary.light,
                                color: palette.secondary.dark,
                            }}
                            onClick={(e) => {
                                closeDialog();
                                /* setScaleFormData({}); */
                                if (user) {
                                    navigate("/reserved_area");
                                } else {
                                    navigate("/");
                                    window.scrollTo(0, 0);
                                }
                            }}
                        />
                        <CustomButton
                            sx={{
                                width: "100%",
                                backgroundColor: palette.secondary.dark,
                                color: palette.primary.light,
                            }}
                            label={lang.dialogs.report.download}
                            onClick={async () => {
                                try {
                                    if (reportPrivateKey) {
                                        await getPdfGuest(reportId!, reportPrivateKey, locale);
                                    } else {
                                        await getPdf(reportId!, locale);
                                    }
                                } catch (e) {
                                    setError(e.message);
                                    switchVisibilityDialog("errorDialog");
                                }
                            }}
                        />
                    </Stack>
                </DialogContent>
                <ErrorDialog
                    isOpen={openDialogs.errorDialog}
                    closeDialog={() => switchVisibilityDialog("errorDialog")}
                    test={error}
                ></ErrorDialog>
            </Dialog>
        </>
    );
}
