import * as React from "react";
import Box from "@mui/material/Box";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useLocalStorageService } from "../../states/localStorageState";
import { useEffect, useRef, useState } from "react";
import { theme } from "../../theme";
import { GreenButton } from "./GreenButton";
import { Container, Grid, Stack, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { ClientData, deleteClient } from "@/services/pharmacyService";
import { Compilation, getClientCompilations } from "@/services/compilationService";
import { AddClientDialog, ScaleCompilateDialog } from "@/dialogs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getPdf } from "@/services/scaleService";
import { useNavigate } from "react-router-dom";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { AppCtx } from "@/multiLanguage/LanguagesWrapper";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import { getColumnsHeaderVariableTranslatorName } from "@/utils/utils";
import { CustomAppBar } from "../CustomAppBar";
import { BackButton } from "../BackButton";

type DetailTablesProp = {
    client: ClientData;
};

export default function DetailTables({ client: _client }: DetailTablesProp) {
    const lang = useTranslatedMessages();
    const { locale } = React.useContext(AppCtx);
    const user = useLocalStorageService((state) => state.user);
    const hasInizalized = useRef(false);
    const [client, setClient] = useState<ClientData>(_client);
    const [compilations, setCompilations] = useState<Compilation[]>([]); // Inizialmente impostato a null
    const [openDialogs, setOpenDialogs] = useState({
        scaleCompilate: false,
        addClientDialog: false,
        confirmDialog: false,
    });
    const navigate = useNavigate();

    function switchVisibilityDialog(name: keyof typeof openDialogs) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }

    useEffect(() => {
        if (!hasInizalized.current) {
            const fetchData = async () => {
                try {
                    const compilationsData = await getClientCompilations(
                        user?.pharmacyId!,
                        client.email
                    );
                    setCompilations(compilationsData);
                } catch (error) {
                    console.error("Errore nel recupero delle compilazioni recenti", error);
                }
            };
            hasInizalized.current = true;
            fetchData();
        }
    }, [hasInizalized]);

    const columns2: GridColDef[] = [
        {
            field: "scale",
            headerName: lang.tables.recent.scale,
            flex: 1,
        },
        {
            field: "date",
            headerName: lang.tables.recent.date,
            flex: 1,
        },
        {
            field: "actions", // Nome del campo della colonna
            headerName: "", // Header della colonna
            flex: 1,
            align: "right",
            renderCell: (params) => (
                <div>
                    <GreenButton
                        endIcon={<DownloadIcon />}
                        disabled={false}
                        text="PDF"
                        onClickProp={() => {
                            handleButtonClick(params.row); // passo i parameetri row
                        }}
                    />
                </div>
            ),
        },
    ];

    const handleButtonClick = async (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
    ) => {
        await getPdf(params.id.toString(), locale);
    };

    const handleDataSubmit = (data: ClientData) => {
        setClient(data);
    };
    const deleteClientFunction = async () => {
        await deleteClient(user?.pharmacyId!, client);
        switchVisibilityDialog("confirmDialog");
        navigate(-1);
        window.scrollTo(0, 0);
    };

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }}>
            <Container fixed>
                <AddClientDialog
                    isOpen={openDialogs.addClientDialog}
                    clientDataForUpdate={client}
                    closeDialog={() => switchVisibilityDialog("addClientDialog")}
                    onSubmit={handleDataSubmit}
                />
                <ScaleCompilateDialog
                    isOpen={openDialogs.scaleCompilate}
                    closeDialog={() => switchVisibilityDialog("scaleCompilate")}
                    client={client}
                />
                <CustomAppBar
                    title={lang.shared.back}
                    contentColor={theme.palette.secondary.dark}
                    backButton={
                        <BackButton
                            sx={{ marginRight: "10px", color: theme.palette.secondary.dark }}
                            onClick={() => {
                                navigate("/reserved_area/clients");
                                window.scrollTo(0, 0);
                            }}
                        />
                    }
                />
                <Grid padding={["1rem"]} container>
                    <Grid padding={["0rem", "1rem"]} item xs={12} sm={12} md={6}>
                        <Stack marginTop={["1rem", "0rem"]} height={["7rem", "4rem", "3rem"]}>
                            <Stack
                                height={["100%", "auto"]}
                                flexDirection={["column", "row"]}
                                sx={{ justifyContent: "space-between" }}
                            >
                                <Typography
                                    sx={{ fontSize: " 1.2rem", color: theme.palette.primary.main }}
                                >
                                    {lang.tables.client.userDataLabel}
                                </Typography>
                                {user!.role === "Admin" ? (
                                    <>
                                        {" "}
                                        <GreenButton
                                            disabled={user!.role !== "Admin"}
                                            text={lang.pages.reservedAreaProfile.updateButton}
                                            endIcon={<EditIcon />}
                                            onClickProp={() => {
                                                switchVisibilityDialog("addClientDialog");
                                            }}
                                        />
                                        <GreenButton
                                            disabled={user!.role !== "Admin"}
                                            text={lang.pages.reservedAreaProfile.deleteButton}
                                            isDeleteButton={true}
                                            endIcon={<DeleteIcon />}
                                            onClickProp={() => {
                                                switchVisibilityDialog("confirmDialog");
                                            }}
                                        />
                                    </>
                                ) : null}
                            </Stack>
                        </Stack>
                        <Box
                            marginTop={["1rem", "0rem"]}
                            sx={{
                                height: ["35rem", "30rem"],
                                width: "100%",
                                borderRadius: "10px",
                                marginBottom: ["0rem", "0rem", "4.3rem"],
                                boxShadow:
                                    "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                            }}
                        >
                            {Object.entries(lang.tables.clientDetail).map(([key, label]) => (
                                <Stack key={key} className="row" direction={"row"}>
                                    <Box
                                        sx={{
                                            width: "50%",
                                            padding: "15px 20px",
                                            fontWeight: "bold",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                        className="field"
                                    >
                                        {label}
                                    </Box>
                                    <Box
                                        sx={{
                                            wordWrap: "break-word",
                                            width: "50%",
                                            padding: "15px 20px",
                                        }}
                                        className="dato"
                                    >
                                        {key !== "nationality"
                                            ? client[key]
                                            : key === "nationality"
                                            ? lang.countries[client[key]]
                                            : ""}
                                    </Box>
                                </Stack>
                            ))}
                        </Box>
                    </Grid>
                    <Grid padding={["0rem", "1rem"]} item xs={12} sm={12} md={6}>
                        <Stack marginTop={["2rem", "0rem"]} height={["5rem", "4rem", "3rem"]}>
                            <Stack
                                flexDirection={["column", "row"]}
                                sx={{ justifyContent: "space-between" }}
                            >
                                <Typography
                                    sx={{ fontSize: " 1.2rem", color: theme.palette.primary.main }}
                                >
                                    {lang.pages.reservedAreaRecent.tableTitle}
                                </Typography>
                                <GreenButton
                                    text={lang.pages.reservedArea.addCompilationDialogHeader}
                                    endIcon={<AddIcon />}
                                    onClickProp={() => {
                                        switchVisibilityDialog("scaleCompilate");
                                    }}
                                />
                            </Stack>
                        </Stack>
                        <Box
                            sx={{
                                height: ["35rem", "30rem"],
                                width: "100%",
                                borderRadius: "10px",
                                marginBottom: ["0rem", "0rem", "4.3rem"],
                                boxShadow:
                                    "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                            }}
                        >
                            <DataGrid
                                rows={compilations}
                                columns={columns2}
                                disableRowSelectionOnClick={true}
                                localeText={{
                                    noRowsLabel: lang.tables.recent.noRowsLabel, // Personalizza il testo per le righe vuote
                                    ...getColumnsHeaderVariableTranslatorName(lang),
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 7,
                                        },
                                    },
                                }}
                                sx={{
                                    /* '& .MuiDataGrid-footerContainer': {
                                        display: 'none', // Questo nasconde il piè di pagina che include il conteggio delle pagine e i controlli di navigazione
                                    }, */
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        color: theme.palette.primary.dark,
                                        fontSize: "0.9rem",
                                        fontWeight: "bold",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        border: "none",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <ConfirmDialog
                    isOpen={openDialogs.confirmDialog}
                    closeDialog={() => {
                        switchVisibilityDialog("confirmDialog");
                    }}
                    onConfirm={() => deleteClientFunction()}
                    title={lang.dialogs.confirmDialog.deleteTitle}
                    message={lang.dialogs.confirmDialog.deleteContent}
                />
            </Container>
        </Box>
    );
}
