import { Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import { useLocalStorageService } from "@/states/localStorageState";
import { getClients, ClientData } from "@/services/pharmacyService";
import ClientsTable from "@/components/newGraphics/ClientsTable";
import ViewAppBar from "@/components/newGraphics/ViewAppBar";

export default function Client() {
    const user = useLocalStorageService((state) => state.user);
    const [clients, setClients] = useState<ClientData[]>([]);
    const hasInizalized = useRef(false);
    useEffect(() => {
        if (!hasInizalized.current) {
            hasInizalized.current = true;
            fetchData();
        }
    }, [hasInizalized]);

    const fetchData = async () => {
        try {
            const clientsData = await getClients(user?.pharmacyId!);
            setClients(clientsData);
        } catch (error) {
            console.error("Errore nel recupero dei clienti", error);
        }
    };

    return (
        <Stack>
            <ViewAppBar />
            <ClientsTable clients={clients} reloadClients={fetchData} />
        </Stack>
    );
}
