import React from "react";
import HomeComponent from "../../components/newGraphics/Home";

export default function Home() {
    return (
        <>
            <HomeComponent></HomeComponent>
        </>
    );
}
