import React, { useState } from "react";
import {
    Collapse,
    DialogContent,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { Dialog } from "@mui/material";
import { Link } from "@mui/material";
import { CustomButton } from "../components";
import { DialogProps } from ".";
import { constants } from "@/utils/constants";
import { useAuthService } from "@/services/authService";
import { AxiosError } from "axios";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";

type loginProps = DialogProps;

export function LoginDialog({ isOpen, closeDialog }: loginProps) {
    const lang = useTranslatedMessages();
    const { palette } = useTheme();
    const [error, setError] = React.useState<null | string>(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isResetPasswordClicked, setIsResetPasswordClicked] = React.useState(false);
    const [formData, setFormData] = React.useState({ email: "", password: "" });
    const [resetPasswordRequested, setResetPasswordRequested] = useState(false);
    const { loginRequest, forgotPasswordRequest } = useAuthService();
    const handleChange = (value: string | null, fieldName: string) => {
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [fieldName]: value,
            };
        });
        setError(null);
    };

    const handleOnSubmit = async () => {
        setIsProcessing(true);
        if (isResetPasswordClicked) {
            try {
                await forgotPasswordRequest(formData.email);
                setResetPasswordRequested(true);
            } catch (err) {
                switch ((err as AxiosError).response?.status) {
                    case 400:
                        setError(lang.dialogs.login.userDoesNotExist);
                        break;
                    default:
                        setError(lang.dialogs.login.genericErrorResetPassword);
                }
            }
        } else {
            try {
                await loginRequest(formData);

                closeDialog();

                //navigate("/reserved_area");
                window.location.href = "/reserved_area";
            } catch (err) {
                switch ((err as AxiosError).response?.status) {
                    case 400:
                        setError(lang.dialogs.login.wrongPasswordOrEmail);
                        break;
                    default:
                        setError(lang.dialogs.login.genericErrorLogin);
                }
            }
        }
        setIsProcessing(false);
    };

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                setFormData({ email: "", password: "" });
                setIsResetPasswordClicked(false);
                setResetPasswordRequested(false);
                setError(null);
                closeDialog();
            }}
            maxWidth="xs"
            PaperProps={{
                style: { borderRadius: 0 },
            }}
        >
            <DialogHeaderComponent
                title={
                    lang.dialogs.login[
                        isResetPasswordClicked ? "loginDialogTitleReset" : "loginDialogTitle"
                    ]
                }
            />
            <DialogContent
                sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleOnSubmit();
                    }}
                >
                    <Stack width={"100%"} marginTop={"2rem"} alignItems="center" gap={2}>
                        <TextField
                            name="email"
                            type={"email"}
                            label={lang.shared.email}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            placeholder={lang.shared.email}
                            onChange={(v) => handleChange(v.target.value, "email")}
                            value={formData.email}
                            sx={{
                                margin: 0,
                                width: "100%",
                                color: palette.primary.main,
                                backgroundColor: palette.primary.light,
                            }}
                        />
                        <Collapse sx={{ width: "100%" }} in={!isResetPasswordClicked}>
                            <TextField
                                name="password"
                                label={lang.shared.password}
                                placeholder={lang.shared.password}
                                onChange={(v) => {
                                    handleChange(v.target.value, "password");
                                }}
                                type="password"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <KeyIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                value={formData.password}
                                sx={{
                                    margin: 0,
                                    width: "100%",
                                    color: palette.primary.main,
                                    backgroundColor: palette.primary.light,
                                }}
                            />
                        </Collapse>

                        {error && (
                            <Typography color={palette.error.main} variant="h6" align="center">
                                {error}
                            </Typography>
                        )}

                        {isResetPasswordClicked && resetPasswordRequested && (
                            <Typography variant="h6" align="center" color={palette.success.main}>
                                {lang.pages.resetPassword.resetPasswordRequested}
                            </Typography>
                        )}

                        <Stack width={"100%"} flexDirection={"row"} gap={1}>
                            {!isResetPasswordClicked ? (
                                <CustomButton
                                    sx={{
                                        width: "100%",
                                        borderColor: palette.secondary.dark,
                                        border: "1px solid",
                                        backgroundColor: palette.primary.light,
                                        color: palette.secondary.dark,
                                    }}
                                    label={lang.shared.close}
                                    secondary
                                    onClick={() => {
                                        setFormData({ email: "", password: "" });
                                        setIsResetPasswordClicked(false);
                                        setResetPasswordRequested(false);
                                        setError(null);
                                        closeDialog();
                                    }}
                                />
                            ) : null}
                            <CustomButton
                                label={
                                    lang.dialogs.login[
                                        isResetPasswordClicked
                                            ? "loginDialogSendForgotPasswordRequest"
                                            : "loginDialogTitle"
                                    ]
                                }
                                sx={{ width: "100%", backgroundColor: palette.secondary.dark }}
                                type={"submit"}
                                disabled={
                                    isProcessing ||
                                    (isResetPasswordClicked && resetPasswordRequested)
                                }
                            />
                        </Stack>

                        <Typography
                            color={palette.darkSecondary.main}
                            sx={{
                                justifyContent: "center",
                                cursor: "pointer",
                                whiteSpace: "break-spaces",
                                textAlign: "center",
                            }}
                            onClick={() => {
                                setIsResetPasswordClicked((e) => !e);
                                setError(null);
                            }}
                        >
                            {
                                lang.dialogs.login[
                                    isResetPasswordClicked
                                        ? "loginDialogCloseForgotPassword"
                                        : "loginDialogForgotPassword"
                                ]
                            }
                        </Typography>

                        <Collapse in={!isResetPasswordClicked}>
                            <Stack width={"100%"} direction={"row"} alignItems="center">
                                <h5>{lang.dialogs.login.notRegisterYetMessage}&ensp;</h5>
                                <Link
                                    className="bold"
                                    underline="none"
                                    href="#"
                                    color={palette.primary.main}
                                    onClick={() => window.open(encodeURI(constants.FORM_URL))}
                                >
                                    {lang.dialogs.login.notRegisterContact}
                                </Link>
                            </Stack>
                        </Collapse>
                    </Stack>
                </form>
            </DialogContent>
        </Dialog>
    );
}
