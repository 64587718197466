import { MouseEventHandler } from "react";
import { Button, ButtonProps, useTheme } from "@mui/material";

export type CustomButtonProps = {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    label: string;
    secondary?: boolean;
    margin?: string;
    disabled?: boolean;
} & ButtonProps;

export function CustomButton({
    onClick,
    label,
    secondary,
    margin = "15px 0px",
    disabled,
    ...rest
}: CustomButtonProps) {
    const { palette } = useTheme();
    return (
        <Button
            {...rest}
            variant="contained"
            onClick={onClick}
            disabled={disabled}
            disableElevation={true}
            sx={{
                backgroundColor: secondary ? palette.secondary.main : palette.primary.main,
                borderRadius: "8px",
                color: secondary ? "black" : "white",
                textTransform: "none",
                "&:hover": {
                    backgroundColor: secondary ? palette.primary.light : palette.secondary.dark,
                },
                margin: margin,
                fontSize: "18px",
                ...rest.sx,
            }}
        >
            {label}
        </Button>
    );
}
