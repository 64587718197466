import { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { NavigationOptions, PaginationOptions } from "swiper/types";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { Navigate, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import { TutorialCard, CustomAppBar, BottomNavbarTutorial } from "../components";
import { useSettingsService } from "@/services/settingsService";
import { useAuthService } from "@/services/authService";
import { nameToScaleMap } from "@/scales";
import { useScaleStore } from "@/states/formState";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { Box, Stack } from "@mui/material";
import { theme } from "@/theme";

export default function TutorialPage() {
    const lang = useTranslatedMessages();
    const { settings, setSkipTutorial } = useSettingsService();
    const selectedScale: keyof typeof nameToScaleMap = useScaleStore(
        (store) => store.selectedScale as keyof typeof nameToScaleMap
    );
    const { isLoggedIn } = useAuthService();
    let [isTutorialEnd, setIsTutorialEnd] = useState(false);
    const navigate = useNavigate();

    const swiperParams: { pagination: PaginationOptions; navigation: NavigationOptions } = {
        pagination: {
            type: "bullets",
            el: ".paginator",
        },
        navigation: {
            nextEl: ".next",
        },
    };
    const isXs = useMediaQuery(useTheme().breakpoints.only("xs"));

    useEffect(() => {
        if (isLoggedIn()) {
            setSkipTutorial(true);
            navigateToScale();
        }
    }, [isLoggedIn]);

    const getScalePath = (name: keyof typeof nameToScaleMap) => {
        return `/scale/${name}`;
    };
    const navigateToScale = () => {
        if (!isTutorialEnd) return;
        if (isLoggedIn()) navigate(getScalePath(selectedScale));
        else navigate(getScalePath("scaleFree"));
    };

    if (settings?.skipTutorial) {
        if (isLoggedIn()) return <Navigate to={getScalePath(selectedScale)} />;
        else return <Navigate to={getScalePath("scaleFree")} />;
    }

    return (
        <motion.div
            exit={{ width: 0, overflow: "hidden", transition: { duration: 0.2 } }}
            className="tutorialPage"
            style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
            }}
        >
            <Box sx={{ background: theme.palette.secondary.main }}>
                <Container
                    fixed
                    disableGutters={isXs}
                    style={{
                        width: "100vw",
                    }}
                >
                    <Stack
                        height={"40rem"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        flexDirection={"column"}
                    >
                        <Box>
                            <CustomAppBar title={lang.pages.tutorial.tutorialIntro} />
                        </Box>

                        <Swiper
                            pagination={swiperParams.pagination}
                            navigation={swiperParams.navigation}
                            modules={[Pagination, Navigation]}
                            onSlideResetTransitionStart={navigateToScale}
                            onSlideChangeTransitionEnd={(event) => setIsTutorialEnd(event.isEnd)}
                            className="mySwiper"
                            style={{ width: "100%" }}
                        >
                            <SwiperSlide>
                                <TutorialCard
                                    title={lang.pages.tutorial.tutorialTitle1}
                                    text={lang.pages.tutorial.tutorialText1}
                                    image="tutorial0.png"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TutorialCard
                                    title={lang.pages.tutorial.tutorialTitle2}
                                    text={lang.pages.tutorial.tutorialText2}
                                    image="tutorial1.png"
                                />
                            </SwiperSlide>
                            <SwiperSlide>
                                <TutorialCard
                                    title={lang.pages.tutorial.tutorialTitle3}
                                    text={lang.pages.tutorial.tutorialText3}
                                    image="tutorial2.png"
                                />
                            </SwiperSlide>
                        </Swiper>

                        <BottomNavbarTutorial
                            isTutorialEnd={isTutorialEnd}
                            showSkip={isLoggedIn()}
                            onSkip={() => {
                                setSkipTutorial(true);
                                navigateToScale();
                            }}
                            onClickForward={navigateToScale}
                        />
                    </Stack>
                </Container>
            </Box>
        </motion.div>
    );
}
