import { IconButton, IconButtonTypeMap } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";

const NavigateButton = ({
    route,
    icon,
    ...rest
}: { route: string | number; icon: ReactNode } & DefaultComponentProps<IconButtonTypeMap>) => {
    const navigate = useNavigate();

    return (
        <IconButton
            onClick={() => {
                if (typeof route === "number") navigate(route as number);
                else navigate(route as string);
                window.scrollTo(0, 0);
            }}
            {...rest}
        >
            {icon}
        </IconButton>
    );
};

export const BackButton = ({
    route,
    ...rest
}: { route?: string | number } & DefaultComponentProps<IconButtonTypeMap>) => {
    return (
        <NavigateButton
            icon={<ArrowBackIosNewIcon sx={{ width: ["1rem"], height: ["1rem"] }} />}
            route={route ?? -1}
            {...rest}
        />
    );
};
