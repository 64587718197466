import { ThemeProvider } from "@mui/material/styles";
import { SessionExpiredDialog } from "./dialogs/SessionExpiredDialog";

import LanguagesWrapper from "./multiLanguage/LanguagesWrapper";
import { AnimatedRouter } from "./pages/router/AnimatedRouter";
import { theme } from "./theme";
import { useAuthService } from "./services/authService";
import { useEffect, useRef } from "react";
export default function App() {
    const { loading, initCurrentUser } = useAuthService()
    const hasInitialized = useRef<boolean>(false);
    useEffect(() => {
        if (!hasInitialized.current) init();
    }, [hasInitialized]);

    const init = async () => {
        hasInitialized.current = true;
        await initCurrentUser();
    };
    return (
        <>{loading ? null :
            <div translate="no">
                <LanguagesWrapper>
                    <ThemeProvider theme={theme}>
                        <SessionExpiredDialog />
                        <AnimatedRouter />
                    </ThemeProvider>
                </LanguagesWrapper></div>}</>
    );
}
