import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { RefObject, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import React from "react";

type CustomPasswordInputProps = {} & TextFieldProps;

const CustomPasswordInput = React.forwardRef((props: CustomPasswordInputProps, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <TextField
            {...props}
            ref={ref as RefObject<HTMLDivElement>}
            type={showPassword ? "text" : "password"}
            color="primary"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
});

export default CustomPasswordInput;
