import { CustomButton } from "@/components/CustomButton";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { zodResolver } from "@hookform/resolvers/zod";
import { Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { FieldValues, Path, useForm } from "react-hook-form";

import { ZodType } from "zod";
import CustomPasswordInput from "./CustomPasswordInput";

export type CustomResetPasswordProps<T> = {
    showOldPassword?: boolean;
    schema: ZodType<any, any, any>;
    submitData: (state: T) => Promise<void>;
    title: string;
    submitLabel: string;
};

export function CustomResetPassword<T extends FieldValues>({
    showOldPassword,
    submitData,
    schema,
    submitLabel,
    title,
}: CustomResetPasswordProps<T>) {
    const { palette } = useTheme();
    const lang = useTranslatedMessages();

    const {
        register,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm<T>({
        resolver: zodResolver(schema),
    });

    const [isProcessing, setIsProcessing] = useState(false);

    const onSubmit = async (resetPasswordData: T) => {
        try {
            setIsProcessing(true);
            await submitData(resetPasswordData);
        } catch (e) {
            setError("root.serverError", { message: e.message });
        } finally {
            setIsProcessing(false);
        }
    };

    const getErrorMessage = (error: any | undefined) => {
        return !!error ? lang.pages.changePassword[error.message] : "";
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems="stretch" justifyContent={"space-between"} sx={{ height: "100%" }}>
                <Typography textAlign={"center"} variant="h4" fontWeight={"bold"}>
                    {title}
                </Typography>
                <Stack alignItems="stretch">
                    {showOldPassword && (
                        <>
                            <CustomPasswordInput
                                sx={{ marginTop: "15px" }}
                                type={"password"}
                                label={lang.pages.changePassword.oldPasswordLabel}
                                error={!!errors.oldPassword}
                                helperText={getErrorMessage(errors.oldPassword)}
                                {...register("oldPassword" as Path<T>)}
                            />
                        </>
                    )}
                    <CustomPasswordInput
                        sx={{ marginTop: "15px" }}
                        type={"password"}
                        label={lang.pages.resetPassword.resetPasswordPagePasswordLabel}
                        error={!!errors.newPassword}
                        helperText={getErrorMessage(errors.newPassword)}
                        {...register("newPassword" as Path<T>)}
                    />
                    <CustomPasswordInput
                        sx={{ marginTop: "15px" }}
                        type={"password"}
                        label={lang.pages.resetPassword.resetPasswordPageConfirmPasswordLabel}
                        error={!!errors.confirmPassword}
                        helperText={getErrorMessage(errors.confirmPassword)}
                        {...register("confirmPassword" as Path<T>)}
                    />
                </Stack>
                <Stack alignItems="stretch">
                    {!!errors.root?.serverError && (
                        <Typography
                            variant="caption"
                            textAlign={"left"}
                            sx={{
                                marginTop: "10px",
                                color: palette.error.light,
                            }}
                        >
                            {errors.root.serverError.message!}
                        </Typography>
                    )}
                    <CustomButton
                        type="submit"
                        label={submitLabel}
                        disabled={/*isDisabled() ||*/ isProcessing}
                    />
                </Stack>
            </Stack>
        </form>
    );
}
