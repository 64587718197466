import { theme } from "@/theme";
import { Stack, Typography, useTheme } from "@mui/material";
/* import { Props } from 'react-intl/src/components/relative' */

export type DialogHeaderComponentProps = {
    title: string;
    error?: boolean;
};
export const DialogHeaderComponent = ({ title, error }: DialogHeaderComponentProps) => {
    const { palette } = useTheme();
    return (
        <>
            <Stack
                sx={{
                    width: "100%",
                    background: !error
                        ? `linear-gradient(90deg,#4395D0,${theme.palette.secondary.dark})`
                        : "linear-gradient(90deg, #f99988,#e53e3e)",
                    height: ["10rem", "10rem", "10rem", "10rem"],
                }}
                alignContent={"center"}
                justifyContent={"center"}
                textAlign={"center"}
            >
                <Typography
                    marginRight={["5rem"]}
                    marginLeft={["5rem"]}
                    variant="caption"
                    color={palette.primary.light}
                    sx={{
                        fontWeight: 700,
                        fontSize: ["1rem", "1rem", "1rem", "1.2rem"],
                        lineHeight: "1.56rem",
                    }}
                >
                    {title.toUpperCase()}
                </Typography>
            </Stack>
        </>
    );
};
