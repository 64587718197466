import { Dialog, DialogContent, Typography, Box, Stack } from "@mui/material";
import { rulesHomeCard } from "@/components/newGraphics/Home";
import { DialogProps } from ".";

import { theme } from "@/theme";
import { CustomButton } from "@/components";

import { useTranslatedMessages } from "@/multiLanguage/i18n";

type RuleHomeDialogProps = {
    rule?: rulesHomeCard;
} & DialogProps;
export function HomeRuleDialog({ isOpen, closeDialog, rule }: RuleHomeDialogProps) {
    const lang = useTranslatedMessages();
    return (
        <>
            {rule ? (
                <Dialog open={isOpen} onClose={closeDialog}>
                    {/* <DialogHeaderComponent title={rule.title} /> */}
                    <img src={rule.imgPath} alt={rule.title} style={{ objectFit: "cover" }} />
                    <DialogContent
                        sx={{ paddingTop: "1rem", paddingLeft: "2rem", paddingRight: "2rem" }}
                    >
                        <Stack width={"100%"} alignItems="center" gap={1}>
                            <Box>
                                <Typography
                                    align="justify"
                                    sx={{
                                        color: theme.palette.primary.main,
                                        fontFamily: "Open Sans",
                                        fontWeight: 400,
                                        fontSize: ["none", "0.8rem", "0.8rem", "1.10rem"],
                                        lineHeight: "1.56rem",
                                    }}
                                >
                                    {rule.content}
                                </Typography>
                            </Box>

                            <Box width={"100%"}>
                                <CustomButton
                                    onClick={closeDialog}
                                    sx={{
                                        width: "100%",
                                        color: theme.palette.primary.light,
                                        backgroundColor: theme.palette.secondary.dark,
                                    }}
                                    label={lang.shared.close}
                                    type="submit"
                                />
                            </Box>
                        </Stack>
                    </DialogContent>
                </Dialog>
            ) : null}
        </>
    );
}
