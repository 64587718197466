import { Box, Button, Grid, useTheme } from "@mui/material";
import { useLocation, Link, useNavigate } from "react-router-dom";

import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { useState } from "react";
import { LoginDialog } from "../../dialogs";
import { useAuthService } from "../../services/authService";
import Home from "./Home";
import AboutUs from "./AboutUs";
import ReservedArea from "@/pages/newGraphicsPages/reservedArea/ReservedArea";
import { LocaleSwitcher } from "@/multiLanguage/LocaleSwitcher";
import React from "react";

type PagesListProp = {
    pagesProp: Page[];
    isLogout: boolean;
    textColor?: string;
    fontSize?: string[];
    justifyContentType?: string[];
    marginTop?: string[];
    marginBottom?: string[];
    marginLeft?: string[];
};
export type Page = {
    page: string;
    path: string;
    translatorName: string;
    isProtected: boolean;
    componentName: React.ElementType;
};
export const pages: Page[] = [
    { page: "Home", path: "/", translatorName: "home", isProtected: false, componentName: Home },
    {
        page: "Chi Siamo",
        path: "/about_us",
        translatorName: "aboutUs",
        isProtected: false,
        componentName: AboutUs,
    },
    {
        page: "Area Riservata",
        path: "/reserved_area",
        translatorName: "reservedArea",
        isProtected: true,
        componentName: ReservedArea,
    },
];

export const transaltePageName = (objectPage: Page, translation: any) => {
    const pageName = (
        translation.pages[objectPage.translatorName as keyof typeof translation.pages] as {
            name: string;
        }
    ).name;
    return pageName;
};
export function PagesListAppBar({
    pagesProp,
    isLogout,
    textColor,
    fontSize,
    justifyContentType = ["flex-end"],
    marginTop = ["0", "0"],
    marginBottom = ["2rem", "0"],
    marginLeft = ["1rem", "0"],
}: PagesListProp) {
    const theme = useTheme();
    const location = useLocation();
    const currentPath = location.pathname;
    const { isLoggedIn, logout } = useAuthService();
    const [openDialogs, setOpenDialogs] = useState({
        login: false,
    });
    function switchVisibilityDialog(name: keyof typeof openDialogs) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }

    const navigate = useNavigate();
    const translation = useTranslatedMessages();
    return (
        <Box marginTop={marginTop}>
            <Grid justifyContent={justifyContentType} alignItems={"center"} container>
                {pagesProp.map((objectPage, index) => (
                    <React.Fragment key={`fragment_${index}`}>
                        {!objectPage.isProtected || (objectPage.isProtected && isLoggedIn()) ? (
                            //<WrapperTimerListner key={index}>
                            <Grid
                                marginLeft={marginLeft}
                                marginBottom={marginBottom}
                                xs={12}
                                md={"auto"}
                                item
                            >
                                <Link
                                    key={index}
                                    to={objectPage.path}
                                    style={{
                                        marginInline: "10px",
                                        marginRight: "2rem",

                                        color: `${
                                            textColor ? textColor : theme.palette.secondary.dark
                                        }`,
                                        fontWeight: `${
                                            currentPath === objectPage.path ||
                                            currentPath.startsWith(objectPage.path + "/")
                                                ? `700`
                                                : "500"
                                        }`,
                                        whiteSpace: "nowrap",

                                        border: `1px solid ${theme.palette.primary.light}`,
                                        textDecoration: "none",
                                        fontSize: `${fontSize ? fontSize : "1rem"}`,
                                    }}
                                >
                                    {transaltePageName(objectPage, translation)}
                                </Link>
                            </Grid>
                        ) : //</WrapperTimerListner>
                        null}
                    </React.Fragment>
                ))}

                {isLoggedIn() && (
                    <Grid
                        marginLeft={marginLeft}
                        marginBottom={marginBottom}
                        xs={"auto"}
                        md={"auto"}
                        item
                    >
                        <Button
                            onClick={async () => {
                                navigate("/");
                                window.scrollTo(0, 0);
                                await logout();
                            }}
                            sx={{
                                marginX: "10px",
                                marginRight: "2rem",
                                color: theme.palette.secondary.dark,
                                whiteSpace: "nowrap",
                                padding: "5px 15px",
                                border: `1px solid ${theme.palette.secondary.dark}`,
                                borderRadius: "10px",
                                textTransform: "none",
                                "&:hover": {
                                    border: `1px solid ${theme.palette.secondary.dark}`,
                                },
                            }}
                        >
                            Log Out
                        </Button>
                    </Grid>
                )}
                {!isLoggedIn() && (
                    <Grid
                        marginLeft={marginLeft}
                        marginBottom={marginBottom}
                        xs={"auto"}
                        md={"auto"}
                        item
                    >
                        <Button
                            onClick={() => {
                                switchVisibilityDialog("login");
                            }}
                            sx={{
                                marginX: "10px",
                                marginRight: "2rem",
                                color: theme.palette.secondary.dark,
                                whiteSpace: "nowrap",
                                padding: "5px 15px",
                                border: `1px solid ${theme.palette.secondary.dark}`,
                                borderRadius: "10px",
                                textTransform: "none",
                                "&:hover": {
                                    border: `1px solid ${theme.palette.secondary.dark}`,
                                },
                            }}
                        >
                            Login
                        </Button>
                    </Grid>
                )}
                <Grid marginLeft={marginLeft} xs={12} md={"auto"} justifyItems={"center"} item>
                    <Box marginLeft={["10px"]}>
                        <LocaleSwitcher sx={{ maxWidth: [40, 44] }} />
                    </Box>
                </Grid>
            </Grid>
            <LoginDialog
                isOpen={openDialogs.login}
                closeDialog={() => {
                    switchVisibilityDialog("login");
                }}
            />
        </Box>
    );
}

export function PagesListFooter({
    pagesProp,
    isLogout,
    textColor,
    fontSize,
    justifyContentType = ["flex-end"],
    marginTop = ["0", "0"],
    marginBottom = ["2rem", "0"],
    marginLeft = ["2rem", "0rem"],
}: PagesListProp) {
    const theme = useTheme();
    const location = useLocation();
    const currentPath = location.pathname;
    const { isLoggedIn } = useAuthService();
    // const [openDialogs, setOpenDialogs] = useState({
    //     login: false,
    // });

    const translation = useTranslatedMessages();
    return (
        <Box key={`footer_box_1`} marginTop={marginTop}>
            <Grid
                key={`footer_grid_1`}
                justifyContent={justifyContentType}
                alignItems={"center"}
                container
            >
                {pagesProp.map((objectPage, index) => (
                    <React.Fragment key={`fragment_${index}`}>
                        {!objectPage.isProtected || (objectPage.isProtected && isLoggedIn()) ? (
                            //<WrapperTimerListner key={index}>
                            <Grid
                                key={`footer_grid_grid_${index}`}
                                marginLeft={marginLeft}
                                marginBottom={marginBottom}
                                xs={"auto"}
                                md={"auto"}
                                item
                            >
                                <Link
                                    key={`footer_grid_grid_link_${index}`}
                                    to={objectPage.path}
                                    style={{
                                        marginRight: "2rem",

                                        color: `${
                                            textColor ? textColor : theme.palette.secondary.dark
                                        }`,
                                        fontWeight: `${
                                            currentPath === objectPage.path ? `700` : "500"
                                        }`,
                                        whiteSpace: "nowrap",

                                        border: `1px solid ${theme.palette.primary.light}`,
                                        textDecoration: "none",
                                        fontSize: `${fontSize ? fontSize : "1rem"}`,
                                    }}
                                >
                                    {transaltePageName(objectPage, translation)}
                                </Link>
                            </Grid>
                        ) : //</WrapperTimerListner>
                        null}
                    </React.Fragment>
                ))}
            </Grid>
        </Box>
    );
}
