import { Network } from "./network";


export const getPdfGuest = async (scaleId: string, key: string, language: string): Promise<any> => {

    try {
        const data = await Network.DownloadPdf(`/Scale/guestDownload/${scaleId}/${key}?culture=${language}`);

        return data;
    } catch (e) {
        throw e
    }


};

export const getPdf = async (scaleId: string, language: string): Promise<any> => {
    try {
        const data = await Network.DownloadPdf(`/Scale/download/${scaleId}?culture=${language}`);

        return data;

    } catch (e) {
        throw e
    }

};