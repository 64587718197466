import { useFormContext } from "react-hook-form";
import {
    TextField,
    Typography,
    FormControlLabel,
    Stack,
    InputAdornment,
    IconButton,
    Popover,
    Switch,
} from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import HelpIcon from "@mui/icons-material/Help";
import { theme } from "@/theme";
import { useEffect, useState } from "react";

function SmokeData() {
    const lang = useTranslatedMessages();
    const [infoTextIsOpen, setInfoTextIsOpen] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();
    const isExSmoker = watch("exSmokerSwitch");
    const isCurrentSmoker = watch("actualSmoker");

    useEffect(() => {
        if (isCurrentSmoker) {
            setValue("exSmokerSwitch", false);
        }
    }, [isCurrentSmoker, setValue]);
    useEffect(() => {
        if (!isExSmoker) {
            setValue("exSmokerYears", undefined);
        }
    }, [isExSmoker, setValue]);
    return (
        <Stack gap={0}>
            {/* <Typography variant='h4'>{lang.scales.smokeComponent.title} </Typography>
             */}

            <FormControlLabel
                control={
                    <Switch
                        {...register("actualSmoker")}
                        data-testid="form-component-actualSmoker"
                    />
                }
                label={lang.scales.smokeComponent.actualSmoker.label}
                labelPlacement="start"
                sx={{
                    marginLeft: 0,
                    marginTop: "10px",
                    marginBottom: "10px",
                    flexGrow: 1,
                    "&.MuiFormControlLabel-labelPlacementStart": {
                        justifyContent: "space-between",
                    },
                }}
            />

            {!isCurrentSmoker && (
                <FormControlLabel
                    control={
                        <Switch
                            {...register("exSmokerSwitch")}
                            data-testid="form-component-exSmoker"
                        />
                    }
                    label={lang.scales.smokeComponent.exSmoker.label}
                    labelPlacement="start"
                    sx={{
                        marginLeft: 0,
                        marginTop: "10px",
                        marginBottom: "10px",
                        flexGrow: 1,
                        "&.MuiFormControlLabel-labelPlacementStart": {
                            justifyContent: "space-between",
                        },
                    }}
                />
            )}

            {isExSmoker && (
                <TextField
                    {...register("exSmokerYears", {
                        valueAsNumber: true,
                        required: lang.scales.smokeComponent.exSmoker.errorText,
                        validate: (value) => {
                            if (!Number.isInteger(value) || value < 0 || value > 89) {
                                return lang.scales.smokeComponent.exSmoker.errorText;
                            } else {
                                return true;
                            }
                        },
                    })}
                    data-testid="form-component-exSmokerYears"
                    type="number"
                    inputProps={{
                        step: ".01",
                        onWheel: (event: any) => {
                            event.target.blur();
                        },
                    }}
                    label={lang.scales.smokeComponent.exSmoker.exSmokerYears}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    error={!!errors.exSmokerYears}
                    helperText={errors.exSmokerYears ? String(errors.exSmokerYears?.message) : ""}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        setAnchorEl(event.currentTarget);
                                        setInfoText(lang.scales.smokeComponent.exSmoker.infoText);
                                        setInfoTextIsOpen(true);
                                    }}
                                    edge="end"
                                >
                                    {<HelpIcon sx={{ color: theme.palette.secondary.light }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    FormHelperTextProps={{
                        style: {
                            fontSize: '0.65rem', // Cambia la grandezza del testo come preferisci
                        },
                    }}
                />
            )}

            <Popover
                open={infoTextIsOpen}
                anchorEl={anchorEl}
                onClose={() => {
                    setInfoTextIsOpen(false);
                }}
                disableScrollLock
                transformOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Typography sx={{ p: 2 }}>{infoText}</Typography>
            </Popover>
        </Stack>
    );
}

export default SmokeData;
