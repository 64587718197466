import { create } from "zustand";

export type User = {
    id?: string;
    name: string;
    email: string;
    role: string;
    password?: string;
    pharmacyId?: string;
};

export const defaultUser: User = {
    id: "XXXXX",
    name: "",
    email: "",
    role: "",
    password: "",
    pharmacyId: "",
};

export type Settings = {
    skipTutorial: boolean;
};

export type Scale = {
    scaleName: string;
    date: string;
    id?: number;
};

export type Compilation = {
    id: number;
    patient?: Patient;
    pharmacy: PharmacyType;
    pharmacist: Pharmacist;
    scale: Scale;
    hour: string;
    date: string;
    compilation_data: string;
};
export type PharmacyType = {
    details: {
        id: number;
        pharmacy_name: string;
        vatNumber: string;
        address: string;
        city: string;
        postCode: string;
        email: string;
        telephoneNumber: string;
    };
    myPharmacist?: Pharmacist[];
};
export type Pharmacist = {
    id: number;
    pharmacist_name: string;
    role?: string;
};

export type Patient = {
    id: number;
    name: string;
    dateOfBirth: string;
    cityOfBirth: string;
    provinceOfBirth: string;
    nationality: string;
    taxCode: string;
    email: string;
    lastEdit?: string;
};

export type RegisteredUser = {
    id: number;
    name: string;
    dateOfBirth: string;
    cityOfBirth: string;
    provinceOfBirth: string;
    nationality: string;
    taxCode: string;
    email: string;
    scalesFilledIn?: number;
    lastEdit?: string;
    compilations: Compilation[];
};

export type listRegisteredUser = RegisteredUser[];

export type listCompilations = Compilation[];

interface LocalStorageState {
    user: User | null;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    settings: Settings;
    showSessionExpiredDialog: boolean;
    setUser: (user: User | null) => void;
    setSettings: (settings: Settings) => void;
    setShowSessionExpiredDialog: (value: boolean) => void;
    selectedSubPage: string;
    registeredUsers: listRegisteredUser;
    compilations: Compilation[];

    myPharmacy: PharmacyType;
    setSelectedPage: (selectedSubPage: string) => void;
    setRegisteredUsers: (users: listRegisteredUser) => void; // Aggiunto il setter per gli utenti registrati
    setCompilations: (compilations: Compilation[]) => void;
    setMyPharmacy: (myPharmacy: PharmacyType) => void;
}

export const useLocalStorageService = create<LocalStorageState>((set, get) => ({
    user: null,
    loading: true,
    setLoading: (loading: boolean) => set((_) => ({ loading })),
    settings: { skipTutorial: false },
    showSessionExpiredDialog: false,
    setUser: (user: User) => set((_) => ({ user })),
    setSettings: (settings: Settings) => set((_) => ({ settings })),
    setShowSessionExpiredDialog: (value: boolean) =>
        set((_) => ({ showSessionExpiredDialog: value })),
    selectedSubPage: "",
    registeredUsers: [], // Inizialmente, nessun utente registrato
    compilations: [],
    myPharmacy: {} as PharmacyType,
    setSelectedPage: (selectedSubPage) => set(() => ({ selectedSubPage })),
    setRegisteredUsers: (users) => set(() => ({ registeredUsers: users })), // Implementazione del setter
    setCompilations: (compilations) => set(() => ({ compilations: compilations })),
    setMyPharmacy: (myPharmacy) => set(() => ({ myPharmacy: myPharmacy })),
}));
