import React from "react";
import { Box, Container, Grid, Link, Stack, Typography, useMediaQuery } from "@mui/material";
import "../../App.css";
import { theme } from "../../theme";

import GridCarousel from "./Carousel";

import MapContainer from "./Maps";
import { GreenButton } from "./GreenButton";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

interface Doctors {
    name: string;
    type: string;
    img_path: string;
}
interface Affiliation {
    title: string;
    href?: string;
}

const defaultDoctors = [
    { name: "DR. Mario Rossi 1", type: "Cardio Chirurgo", img_path: "/doctor.jpg" },
    { name: "DR. Mario Rossi 2", type: "Cardio Chirurgo", img_path: "/doctor.jpg" },
    { name: "DR. Mario Rossi 3", type: "Cardio Chirurgo", img_path: "/doctor.jpg" },
    { name: "DR. Mario Rossi 4", type: "Cardio Chirurgo", img_path: "/doctor.jpg" },
] as Doctors[];

function open_maps() {
    const latitude = 43.609;
    const longitude = 13.45608;
    const zoom = 14; // Livello di zoom (valore da 1 a 20)

    const url = `https://www.google.com/maps?q=${latitude},${longitude}&z=${zoom}`;
    window.open(url);
}

function AboutUsComponent() {
    const translation = useTranslatedMessages();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const affiliations: Affiliation[] = [
        {
            title: translation.pages.aboutUs.affiliationOne,
            href: "https://www.ipertensione.marche.it",
        },
        {
            title: translation.pages.aboutUs.affiliationTwo,
            href: "",
        },
        {
            title: translation.pages.aboutUs.affiliationThree,
            href: "",
        },
        {
            title: translation.pages.aboutUs.affiliationFor,
            href: "https://www.inrca.it/INRCA/MODM2/",
        },
    ];
    return (
        <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
            <Box
                sx={{
                    backgroundImage: ["url('/aboutHeader1.png')", "url('/aboutHeader.png')"],
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "right",
                    height: ["9rem", "15rem"],
                    textAlign: "right",
                    backgroundColor: theme.palette.secondary.main,
                }}
            ></Box>
            <Container disableGutters={isMobile} fixed={!isMobile}>
                {/*maxWidth="xl" maxWidth={false}*/}
                <Box
                    sx={{
                        marginTop: ["-3.8rem", "-3.8rem", "-3.8rem", "-5.5rem"],
                        marginLeft: ["1.5rem", "1.5rem", "1.5rem", "6rem"],
                        marginRight: ["9.5rem", "32rem", "40rem", "58rem"],
                        marginBottom: ["2rem", "2rem", "3rem", "4rem"],
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.secondary.dark,
                            fontFamily: "Open Sans",
                            fontWeight: 600,
                            fontSize: "2.25rem",
                            lineHeight: "3.44rem",
                        }}
                        variant="h3"
                    >
                        <span>{translation.pages.aboutUs.title}</span>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        marginLeft: ["1rem", "1rem", "1rem", "6rem"],
                        marginRight: ["1rem", "1rem", "1rem", "6rem"],
                        marginBottom: ["2rem", "2rem", "2rem", "5rem"],
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.primary.main,
                            fontFamily: "Open Sans",
                            fontWeight: 400,
                            fontSize: ["1.13rem", "1rem", "1.13rem", "1.13rem"],
                            lineHeight: "1.56rem",
                        }}
                        variant="h6"
                    >
                        {translation.pages.aboutUs.subTitleContent}
                    </Typography>
                </Box>

                {/*  <Stack
                    justifyContent={"center"}
                    marginLeft={["0rem", "0rem", "0rem", "2rem"]}
                    marginBottom={["2rem", "2rem", "4rem", "5rem"]}
                    marginRight={["0rem", "0rem", "0rem", "2rem"]}
                >
                    <GridCarousel items={defaultDoctors} />
                </Stack> */}

                <Grid
                    paddingLeft={["0rem", "1rem", "1rem", "4rem"]}
                    paddingBottom={["2rem", "2rem", "4rem", "5rem"]}
                    paddingRight={["0rem", "1rem", "1rem", "4rem"]}
                    spacing={0}
                    container
                >
                    <Grid
                        sx={{
                            boxShadow: "0px 0px 2rem 10px rgba(0,0,0,0.2)",
                            backgroundColor: theme.palette.primary.light,
                        }}
                        item
                        container
                        justifyContent={"center"}
                        md={5}
                        xs={12}
                    >
                        <MapContainer />
                    </Grid>
                    <Grid
                        sx={{
                            boxShadow: "0px 0px 2rem 10px rgba(0,0,0,0.2)",
                            backgroundColor: theme.palette.primary.light,
                        }}
                        justifyContent={"center"}
                        item
                        container
                        md={7}
                        xs={12}
                    >
                        { }
                        <Box
                            width={"100%"}
                            paddingLeft={["1rem", "2rem", "1rem", "2rem"]}
                            paddingTop={["2rem", "2rem", "2rem", "2rem"]}
                            paddingRight={["1rem", "1rem", "1rem", "2rem"]}
                        >
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontFamily: "Open Sans",
                                    fontWeight: 700,
                                    fontSize: ["1.13rem", "1rem", "1rem", "1rem"],
                                    lineHeight: "1.56rem",
                                }}
                                variant="h6"
                            >
                                {translation.pages.aboutUs.fraseOneMaps}
                            </Typography>
                        </Box>
                        <Box
                            width={"100%"}
                            paddingLeft={["1rem", "2rem", "1rem", "2rem"]}
                            paddingTop={["2rem", "2rem", "0rem", "0rem"]}
                            paddingRight={["1rem", "1rem", "1rem", "2rem"]}
                        >
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontFamily: "Open Sans",
                                    fontWeight: 500,
                                    fontSize: ["1.13rem", "1rem", "1rem", "1rem"],
                                    lineHeight: "1.56rem",
                                }}
                                variant="h6"
                            >
                                {translation.pages.aboutUs.fraseTwoMaps}
                            </Typography>
                        </Box>
                        <Box
                            width={"100%"}
                            paddingLeft={["1rem", "2rem", "1rem", "2rem"]}
                            paddingTop={["2rem", "2rem", "0rem", "0rem"]}
                            paddingRight={["1rem", "1rem", "1rem", "2rem"]}
                        >
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontFamily: "Open Sans",
                                    fontWeight: 400,
                                    fontSize: ["1.13rem", "1rem", "1rem", "1rem"],
                                    lineHeight: "1.56rem",
                                }}
                                variant="h6"
                            >
                                {translation.pages.aboutUs.fraseThreeMaps}
                            </Typography>
                        </Box>
                        {affiliations.map((aff) => {
                            return (
                                <Box
                                    width={"100%"}
                                    paddingLeft={["1rem", "2rem", "1rem", "2rem"]}
                                    paddingTop={["2rem", "2rem", "0rem", "0rem"]}
                                    paddingRight={["1rem", "1rem", "1rem", "2rem"]}
                                >
                                    <Typography
                                        sx={{
                                            color: theme.palette.primary.main,
                                            fontFamily: "Open Sans",
                                            fontWeight: 400,
                                            fontSize: ["1.13rem", "1rem", "0.8rem", "0.8rem"],
                                            lineHeight: "1.56rem",
                                        }}
                                        variant="h6"
                                    >
                                        -{" "}
                                        {aff.href ? (
                                            <Link href={aff.href}>{aff.title}</Link>
                                        ) : (
                                            aff.title
                                        )}
                                    </Typography>
                                </Box>
                            );
                        })}
                        {/* <Box
                            width={"100%"}
                            paddingLeft={["1rem", "2rem", "1rem", "2rem"]}
                            paddingTop={["2rem", "2rem", "0rem", "0rem"]}
                            paddingRight={["1rem", "1rem", "1rem", "2rem"]}
                        >
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontFamily: "Open Sans",
                                    fontWeight: 400,
                                    fontSize: ["1.13rem", "1rem", "1.13rem", "1.13rem"],
                                    lineHeight: "1.56rem",
                                }}
                                variant="h6"
                            >
                                -{" "}
                                <Link href="https://www.inrca.it/INRCA/MODM2/">
                                    {translation.pages.aboutUs.fraseForMaps}
                                </Link>
                            </Typography>
                        </Box>

                        <Box
                            width={"100%"}
                            paddingLeft={["1rem", "2rem", "1rem", "2rem"]}
                            paddingTop={["2rem", "2rem", "0rem", "0rem"]}
                            paddingRight={["1rem", "1rem", "1rem", "2rem"]}
                        >
                            <Typography
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontFamily: "Open Sans",
                                    fontWeight: 400,
                                    fontSize: ["1.13rem", "1rem", "1.13rem", "1.13rem"],
                                    lineHeight: "1.56rem",
                                }}
                                variant="h6"
                            >
                                -{" "}
                                <Link href="https://www.ipertensione.marche.it">
                                    {translation.pages.aboutUs.fraseFiveMaps}
                                </Link>
                            </Typography>
                        </Box> */}

                        <Box
                            paddingLeft={["0rem", "2rem"]}
                            paddingTop={["2rem", "2rem", "2rem", "0rem"]}
                            paddingBottom={["2rem", "2rem", "2rem", "0rem"]}
                            paddingRight={["0rem", "2rem"]}
                        >
                            <GreenButton
                                text={translation.pages.aboutUs.maps_button}
                                onClickProp={open_maps}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default AboutUsComponent;
