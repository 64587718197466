import { useFormContext } from "react-hook-form";
import { Stack, Typography, Box } from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { theme } from "@/theme";

function FooterFormData() {
    const lang = useTranslatedMessages();
    const { formState } = useFormContext();

    return (
        <Stack gap={3}>
            {!formState.isValid ? (
                <Box>
                    <Typography
                        sx={{ textAlign: "center" }}
                        variant="h5"
                        color={theme.palette.error.main}
                    >
                        {lang.scales.shared.forms.formError}
                    </Typography>
                </Box>
            ) : null}
            <Box>
                <Typography sx={{ textAlign: "center" }} variant="h5">
                    {lang.scales.shared.forms.mandatoryFields}
                </Typography>
            </Box>
        </Stack>
    );
}

export default FooterFormData;
