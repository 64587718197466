import { AppCtx } from "@/multiLanguage/LanguagesWrapper"
import { Stack, Box } from "@mui/material"
import { useContext } from "react"

export const LocaleSwitcher = ({ border = 6, borderColor = 'white', ...props }: any) => {
    const { changeLocale, supportedLanguages, locale } = useContext(AppCtx)
    const radius = border - 2
    return (
        <Stack
            alignContent="center"
            justifyContent="center"

            width="100%"
            {...props}
            sx={{
                marginBottom: "-2px",
                cursor: "pointer",
                minWidth: 15,
                borderRadius: `${border + radius}px`,
                border: `${radius}px solid ${borderColor}`,
                backgroundColor: borderColor,
                ...props?.sx
            }}

            onClick={changeLocale}
        >
            <Box
                component={'img'}
                src={
                    `../langs/${supportedLanguages[locale].icon}`
                }
                sx={{
                    borderRadius: `${radius + 2}px`,
                    objectFit: 'cover'
                }}
                alt={supportedLanguages[locale].name}
            />
        </Stack>
    )
}