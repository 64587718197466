import {
    FormControlLabel,
    TextField,
    Switch,
    Box,
    Stack,
    useTheme,
    DialogContent,
} from "@mui/material";
import { Dialog } from "@mui/material";
import { CustomButton } from "../components";
import { DialogProps } from ".";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";
import { Controller, useForm } from "react-hook-form";

type FormContactsValues = {
    name: string;
    email: string;
    message: string;
    privacy: boolean;
};
const DefaultValues: FormContactsValues = {
    name: "",
    email: "",
    message: "",
    privacy: false,
};

type ContactDialogProps = { onClick: Function } & DialogProps;

export function ContactDialog({ isOpen, closeDialog, onClick }: ContactDialogProps) {
    const lang = useTranslatedMessages();
    const { palette } = useTheme();

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid },
    } = useForm<FormContactsValues>({
        defaultValues: DefaultValues,
    });
    const onSubmit = handleSubmit((data) => {
        onClick(data.name, data.email, data.message);
        reset(DefaultValues);
    });

    return (
        <Box>
            <Dialog
                maxWidth="xs"
                open={isOpen}
                onClose={() => {
                    closeDialog();
                    reset(DefaultValues);
                }}
                PaperProps={{
                    style: { borderRadius: 0 },
                }}
            >
                <DialogHeaderComponent title={lang.dialogs.contact.contactDialogTitle} />
                <DialogContent
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
                >
                    <form onSubmit={onSubmit}>
                        <Box width={"100%"}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: "Name is required" }}
                                render={({ field }) => (
                                    <TextField
                                        label={lang.shared.name}
                                        variant="outlined"
                                        sx={{ width: "100%", margin: "15px 0px" }}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                        <Box width={"100%"}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                        message: "Invalid email",
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        label={lang.shared.email}
                                        margin="normal"
                                        error={!!errors.email}
                                        helperText={errors.email ? errors.email.message : ""}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                        <Box width={"100%"}>
                            <Controller
                                name="message"
                                control={control}
                                rules={{ required: "Message is required" }}
                                render={({ field }) => (
                                    <TextField
                                        label={lang.shared.message}
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        sx={{ width: "100%", margin: "15px 0px" }}
                                        {...field}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Controller
                                name="privacy"
                                control={control}
                                rules={{ required: "Privacy is required" }}
                                defaultValue={false} // Default value for the privacy switch
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        sx={{ marginLeft: 0 }}
                                        control={
                                            <Switch
                                                checked={value}
                                                onChange={(e) => onChange(e.target.checked)}
                                                color="default"
                                            />
                                        }
                                        label={lang.dialogs.contact.contactDialogCheckMessage}
                                        labelPlacement="start"
                                    />
                                )}
                            />
                        </Box>
                        <Box width={"100%"}>
                            <Stack flexDirection={"row"} gap={1}>
                                <CustomButton
                                    sx={{
                                        width: "100%",
                                        borderColor: palette.secondary.dark,
                                        border: "1px solid",
                                        backgroundColor: palette.primary.light,
                                        color: palette.secondary.dark,
                                    }}
                                    secondary
                                    label={lang.shared.close}
                                    onClick={() => {
                                        closeDialog();
                                        reset(DefaultValues);
                                    }}
                                />
                                <CustomButton
                                    sx={{
                                        paddingLeft: "1rem",
                                        width: "100%",
                                        color: palette.primary.light,
                                        backgroundColor: palette.secondary.dark,
                                    }}
                                    label={lang.dialogs.contact.contactDialogSendButton}
                                    disabled={!isValid}
                                    type="submit"
                                />
                            </Stack>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </Box>
    );
}
