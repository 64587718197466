import { FormDataLopre } from "../reactHookLopreScale";
import { RiskLevels, returnValue } from "./shared";

export type LopreHolderIndex = {
    score2: number;
    countryRisk: string;
    egfr: number | null;
    smoke: boolean;
    riskLevel: string;
    bmi: number | null;
    eventProbability?: string;
};

export function getRiskLevelValue(
    formData: FormDataLopre,
    score2value: number,
    egfr: number | null
): [keyof typeof RiskLevels, number] {
    //Ha avuto più di due eventi cardiovascolari in due anni consecutivi
    if (formData.cardiovascularEventSwitch && formData.moreCardiovascularEvent) {
        return returnValue("extreme");
    }
    if (
        // Ha avuto almeno un evento cardiovascolare
        formData.cardiovascularEventSwitch ||
        //score2
        (score2value >= 7.5 && formData.age < 50) ||
        (score2value >= 10 && formData.age >= 50 && formData.age < 70) ||
        (score2value >= 15 && formData.age >= 70) ||
        //MRC severa
        (egfr && egfr < 30) ||
        //DM2 con danno d'organo
        (formData.diabetesSwitch && egfr && egfr < 45)
    ) {
        return returnValue("veryHigh");
    }
    if (
        //score2
        (score2value >= 2.5 && score2value < 7.5 && formData.age < 50) ||
        (score2value >= 5 && score2value < 10 && formData.age >= 50 && formData.age < 70) ||
        (score2value >= 7.5 && score2value < 15 && formData.age >= 70) ||
        //col elevati o pressione alta
        formData.totalCholesterol > 310 ||
        (formData.systolicPressure > 179 &&
            formData.diastolicPressure &&
            formData.diastolicPressure > 109) ||
        //condizione imposta da sarzani in call 26/01
        formData.diabetesSwitch
    ) {
        return returnValue("high");
    }
    if (
        (score2value < 2.5 && formData.age < 50) ||
        (score2value < 5 && formData.age >= 50 && formData.age < 70) ||
        (score2value < 7.5 && formData.age >= 70)
    ) {
        return returnValue("moderateLow");
    }
    //TODO: come gestire l'errore?
    return ["moderateLow", -1];
}
