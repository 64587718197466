import { Box, FormControlLabel, Grid, InputAdornment, Stack, Switch, TextField } from "@mui/material";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import RegistryData from "./scaleComponents/registryComponent";
import { theme } from "@/theme";
import InfoBodyData from "./scaleComponents/bofyInfoComponent";
import SmokeData from "./scaleComponents/smokeComponent";
import PressureData from "./scaleComponents/pressureComponent";
import CholesterolData from "./scaleComponents/cholesterolComponent";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import CardiovascularEventsData from "./scaleComponents/cardiovascularEventComponent";
import { CustomButton } from "@/components";
import GlycatedData from "./scaleComponents/glycatedComponent";
import { ClientData } from "@/services/pharmacyService";
import { useEffect } from "react";
import PrivacyData from "./scaleComponents/privacyComponent";
import FooterFormData from "./scaleComponents/footerFormComponent";
import { constants } from "@/utils/constants";
/* import UrineAnalyzerData from "./scaleComponents/urineAnalyzerComponent";
import {
    AlbConstant,
    AnalyzerPossibleValueProps,
    AscConstant,
    BilConstant,
    BloConstant,
    CaConstant,
    CreConstant,
    GluConstant,
    KetConstant,
    LeuConstant,
    NitConstant,
    PhConstant,
    ProConstant,
    SgConstant,
    UroConstant,
} from "./scalesUtils/constants";
import { findValueByMark } from "./scalesUtils/glycatedScaleModels&Utils"; */

const DefaultFormData: FormDataGlycated = {
    //ANAGRAFICA
    name: "Test",
    surname: "Test",
    country: "italy",
    sex: "male",
    age: 41,
    //Dati fisici
    height: 189,
    weight: 83,
    //Dati fumatore
    actualSmoker: false,
    exSmokerSwitch: false,
    exSmokerYears: 0,
    //Pressione
    systolicPressure: 150,
    diastolicPressure: 150,
    //Cholesterol
    totalCholesterol: 116,
    hdlCholesterol: 27,
    triglycerides: 150,


    //creatine
    creatinine: 10,
    //eventi cardiovasculari
    //cardiovascularEvent: false, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    cardiovascularEventSwitch: false,
    moreCardiovascularEvent: false,

    //diabet data
    //diabetes: false, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    diabetesSwitch: false,

    glycatedValue: 41,
    glycatedUom: "mmol/mol",

    //privacy
    privacy_switch: false,

    /* //urine analyzer
    urineAnalyzerSwitch: false,
    asc: "-",
    leu: "-",
    glu: "-",
    alb: "10 mg/L",
    bil: "-",
    ph: "5,0",
    sg: "1,000",
    ket: "-",
    pro: "-",
    nit: "-",
    uro: "-",
    cre: "10 mg/dL",
    blo: "-",
    ca: "4 mg/dL", */
};
export type FormDataGlycated = {
    //ANAGRAFICA
    name?: string;
    surname?: string;
    country: string;
    sex: string;
    age: number;
    //Dati fisici
    height?: number;
    weight?: number;
    //Dati fumatore
    actualSmoker: boolean;
    exSmokerSwitch: boolean;
    exSmokerYears?: number;
    //Pressione
    systolicPressure: number;
    diastolicPressure: number;
    //Cholesterol
    totalCholesterol: number;
    hdlCholesterol: number;
    ldlCholesterol?: number;
    triglycerides: number;
    noHdlCholesterol?: number;
    //creatine
    creatinine: number;
    //eventi cardiovasculari
    //cardiovascularEvent: boolean, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    cardiovascularEventSwitch: boolean;
    moreCardiovascularEvent: boolean;
    //diabet
    //diabetes: boolean, // NON SERVE DA VEDERE COSA FARE LATO BK E SE SI PUO TOGLIERE
    diabetesSwitch: boolean;
    privacy_switch?: boolean;

    //glycated
    glycatedValue?: number;
    glycatedUom?: string;

    /* //urine analyzer
    urineAnalyzerSwitch?: boolean;
    asc?: string | AnalyzerPossibleValueProps;
    leu?: string | AnalyzerPossibleValueProps;
    glu?: string | AnalyzerPossibleValueProps;
    alb?: string | AnalyzerPossibleValueProps;
    bil?: string | AnalyzerPossibleValueProps;
    ph?: string | AnalyzerPossibleValueProps;
    sg?: string | AnalyzerPossibleValueProps;
    ket?: string | AnalyzerPossibleValueProps;
    pro?: string | AnalyzerPossibleValueProps;
    nit?: string | AnalyzerPossibleValueProps;
    uro?: string | AnalyzerPossibleValueProps;
    cre?: string | AnalyzerPossibleValueProps;
    blo?: string | AnalyzerPossibleValueProps;
    ca?: string | AnalyzerPossibleValueProps;
    newAcr?: AnalyzerPossibleValueProps; */
};

type FormProps = {
    onSubmit: SubmitHandler<FormDataGlycated>;
    selectedClient?: ClientData;
};

export function GlycatedScaleHookForm({ onSubmit, selectedClient }: FormProps) {
    const formMethods = useForm<FormDataGlycated>({
        mode: "onBlur",
        defaultValues: constants.IS_DEBUG ? { ...DefaultFormData } : undefined,
    });
    const lang = useTranslatedMessages();
    useEffect(() => {
        if (selectedClient) {
            formMethods.setValue("name", selectedClient.name);
            formMethods.setValue("surname", selectedClient.surname);
            formMethods.setValue("country", selectedClient.nationality);
        }
    }, [selectedClient]);

    /* const trasformDataBeforeSumbit = (data: FormDataGlycated) => {
        const updateData = { ...data };

        updateData.asc = findValueByMark(updateData.asc as string, AscConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.leu = findValueByMark(updateData.leu as string, LeuConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.glu = findValueByMark(updateData.glu as string, GluConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.alb = findValueByMark(updateData.alb as string, AlbConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.bil = findValueByMark(updateData.bil as string, BilConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.ph = findValueByMark(updateData.ph as string, PhConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.sg = findValueByMark(updateData.sg as string, SgConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.ket = findValueByMark(updateData.ket as string, KetConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.pro = findValueByMark(updateData.pro as string, ProConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.nit = findValueByMark(updateData.nit as string, NitConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.uro = findValueByMark(updateData.uro as string, UroConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.cre = findValueByMark(updateData.cre as string, CreConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.blo = findValueByMark(updateData.blo as string, BloConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        updateData.ca = findValueByMark(updateData.ca as string, CaConstant) || {
            mark: "",
            concentrationValue: 0,
            concetrationUom: "error",
        };
        onSubmit(updateData);
    }; */

    return (
        <FormProvider {...formMethods}>
            <Box
                sx={{
                    borderRadius: "20px 20px 20px 20px",
                    backgroundColor: theme.palette.primary.light,
                }}
            >
                <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Stack gap={3} p={3}>
                        <Grid container spacing={3} >

                            <Grid item xs={12} md={6} >
                                <Box >
                                    <Stack gap={3}>
                                        <RegistryData
                                            disabledPatientData={selectedClient ? true : false}
                                            defaultCountry={selectedClient?.nationality}
                                        />
                                        <InfoBodyData heightRequired={false} weightRequired={false} />

                                    </Stack>
                                </Box>

                            </Grid>


                            <Grid item xs={12} md={6}>
                                <Box >
                                    <Stack gap={3}>
                                        <PressureData diastolicPressureRequired={false} />
                                        <CholesterolData />

                                        <Stack height={65} gap={3}>
                                            <TextField
                                                {...formMethods.register("creatinine", {
                                                    valueAsNumber: true,

                                                    validate: (value) => {
                                                        if (!value) {
                                                            return true;
                                                        }
                                                        if (value < 0.1 || value > 10.3) {
                                                            return lang.scales.scaleWithComponentShared.creatinine
                                                                .errorText;
                                                        }
                                                        return true;
                                                    },
                                                })}
                                                inputProps={{
                                                    "data-testid": "form-component-creatinine",
                                                    step: ".01",
                                                    onWheel: (event: any) => {
                                                        event.target.blur();
                                                    },
                                                }}
                                                label={<>{lang.scales.scaleWithComponentShared.creatinine.label}</>}
                                                type="number"
                                                fullWidth
                                                error={!!formMethods.formState.errors.creatinine}
                                                helperText={formMethods.formState.errors.creatinine?.message || " "}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">mg/dL</InputAdornment>
                                                    ),
                                                }}
                                            />


                                        </Stack>

                                        <GlycatedData />
                                    </Stack>
                                </Box>

                            </Grid>


                            <Grid item xs={12} >
                                <Box>
                                    <Stack >
                                        <SmokeData />
                                        <Stack >
                                            <FormControlLabel
                                                control={<Switch {...formMethods.register("diabetesSwitch")} />}
                                                label={lang.scales.scaleWithComponentShared.diabete2.label}
                                                labelPlacement="start"
                                                sx={{
                                                    marginLeft: 0,
                                                    marginTop: "10px",
                                                    marginBottom: "10px",
                                                    flexGrow: 1,
                                                    "&.MuiFormControlLabel-labelPlacementStart": {
                                                        justifyContent: "space-between",
                                                    },
                                                }}
                                            />
                                        </Stack>
                                        <CardiovascularEventsData arteriopathy={false} />

                                        {/* <UrineAnalyzerData showSwitchPhrase={true} /> */}

                                        <PrivacyData />
                                        <FooterFormData />
                                    </Stack>
                                </Box>

                            </Grid>



                            {/* <RegistryData
                            disabledPatientData={selectedClient ? true : false}
                            defaultCountry={selectedClient?.nationality}
                        />
                       
                        <SmokeData /> */}
                            {/* <PressureData diastolicPressureRequired={false} />
                        <CholesterolData triglycerides={false} />
                        <Stack>
                            <TextField
                                {...formMethods.register("creatinine", {
                                    valueAsNumber: true,

                                    validate: (value) => {
                                        if (!value) {
                                            return true;
                                        }
                                        if (value < 0.1 || value > 10.3) {
                                            return lang.scales.scaleWithComponentShared.creatinine
                                                .errorText;
                                        }
                                        return true;
                                    },
                                })}
                                inputProps={{
                                    "data-testid": "form-component-creatinine",
                                    step: ".01",
                                    onWheel: (event: any) => {
                                        event.target.blur();
                                    },
                                }}
                                label={<>{lang.scales.scaleWithComponentShared.creatinine.label}</>}
                                type="number"
                                fullWidth
                                error={!!formMethods.formState.errors.creatinine}
                                helperText={formMethods.formState.errors.creatinine?.message || ""}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">mg/dL</InputAdornment>
                                    ),
                                }}
                            />
                        </Stack> */}

                            {/* <Stack>
                            <FormControlLabel
                                control={<Switch {...formMethods.register("diabetesSwitch")} />}
                                label={lang.scales.scaleWithComponentShared.diabete2.label}
                                labelPlacement="start"
                                sx={{
                                    marginLeft: 0,
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                    flexGrow: 1,
                                    "&.MuiFormControlLabel-labelPlacementStart": {
                                        justifyContent: "space-between",
                                    },
                                }}
                            />
                        </Stack> */}

                            {/* <GlycatedData /> */}

                            {/* <CardiovascularEventsData arteriopathy={false} />

                        <UrineAnalyzerData />

                        <PrivacyData /> */}

                        </Grid>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"center"} mt={2}>
                        <Box>
                            <CustomButton
                                sx={{
                                    backgroundColor: theme.palette.secondary.dark,
                                    color: theme.palette.primary.light,
                                }}
                                label={lang.scales.shared.forms.calculate}
                                type="submit"
                            />
                        </Box>
                        {/* <Box >
                            <CustomButton
                                sx={{ backgroundColor: theme.palette.secondary.dark, color: theme.palette.primary.light }}

                                label="Errori"
                                onClick={() => printErrors(formMethods.formState.errors)}
                            />

                        </Box> */}
                    </Stack>
                </form>
            </Box>
        </FormProvider>
    );
}
