import { CustomButton } from "@/components";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { useLocalStorageService } from "@/states/localStorageState";
import { Dialog, Stack, Typography, useTheme } from "@mui/material";

export function SessionExpiredDialog() {
    const showSessionExpiredDialog = useLocalStorageService(
        (state) => state.showSessionExpiredDialog
    );
    const lang = useTranslatedMessages();
    const { palette } = useTheme();
    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={showSessionExpiredDialog}
            PaperProps={{
                style: { borderRadius: 20 },
            }}
        >
            <DialogHeaderComponent title={lang.dialogs.expireSession.sessionExpireTitle} />
            <Stack
                sx={{
                    paddingLeft: "2rem",
                    paddingTop: "1rem",
                    paddingRight: "2rem",
                    backgroundColor: palette.background.default,
                }}
                alignItems="center"
                gap={2}
            >
                <Typography variant="body1">
                    {lang.dialogs.expireSession.sessionExpireText}
                </Typography>
                <CustomButton
                    sx={{ backgroundColor: palette.secondary.dark, color: palette.primary.light }}
                    onClick={() => {
                        window.location.href = "/";
                    }}
                    label={"Ricarica sito"}
                />
            </Stack>
        </Dialog>
    );
}
