import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Drawer, Typography } from "@mui/material";

interface DrawerMenuProps {
    isOpen: boolean;
    toggle: () => void;
    head?: { title: string; subtitle: string };
    actions?: {
        icon: JSX.Element;
        label: string | JSX.Element;
        onClick: (e?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    }[];
}

export function DrawerMenu({ isOpen, toggle, head, actions }: DrawerMenuProps) {
    return (
        <Drawer anchor={"right"} open={isOpen} onClose={toggle}>
            <Box sx={{ width: 250 }} role="presentation" onClick={toggle} onKeyDown={toggle}>
                {head && (
                    <Box
                        sx={{
                            backgroundColor: "primary.main",
                            padding: "16px",
                        }}
                    >
                        <Typography fontSize={32} fontWeight={700} color="white">
                            {head.title}
                        </Typography>
                        <Typography
                            fontSize={18}
                            fontWeight={500}
                            color="white"
                            sx={{ opacity: 0.7 }}
                        >
                            {head.subtitle}
                        </Typography>
                    </Box>
                )}
                {actions && (
                    <List>
                        {actions.map((item, index) => (
                            <ListItem key={index} disablePadding onClick={(e) => item.onClick(e)}>
                                <ListItemButton>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.label} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
        </Drawer>
    );
}
