import { useEffect, useRef, useState } from "react";
import { Container, Dialog, Paper, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useAuthService } from "@/services/authService";
import { CustomButton } from "@/components";
import { AxiosError } from "axios";
import { z } from "zod";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { CustomResetPassword } from "@/components/newGraphics/CustomResetPassword";

const resetPasswordSchema = z
    .object({
        newPassword: z
            .string()
            .min(8, "resetPasswordPagePasswordError")
            .regex(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                "resetPasswordPagePasswordError"
            ),
        confirmPassword: z.string(),
    })
    .refine((data) => data.newPassword === data.confirmPassword, {
        path: ["confirmPassword"],
        message: "resetPasswordPageEqualityPasswordError",
    });

type ResetPasswordSchema = z.infer<typeof resetPasswordSchema>;

export const ResetPasswordPage = () => {
    const lang = useTranslatedMessages();

    const hasInitialized = useRef(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const [resetPasswordToken, setResetPasswordToken] = useState("");
    const [resetCompleted, setResetCompleted] = useState(false);

    const { confirmResetPasswordRequest } = useAuthService();

    useEffect(() => {
        if (!hasInitialized.current) {
            hasInitialized.current = true;
            setResetPasswordToken(searchParams.get("token") ?? "");
        }
    }, [hasInitialized.current]);

    const onSubmit = async (state: ResetPasswordSchema) => {
        try {
            await confirmResetPasswordRequest(state.newPassword, resetPasswordToken);
            setResetCompleted(true);
        } catch (error) {
            switch ((error as AxiosError).response?.status) {
                case 400:
                    throw new Error(lang.pages.resetPassword.errorResetPasswordFailed);
                default:
                    throw new Error(lang.pages.resetPassword.generalErrorResetPassword);
            }
        }
    };

    const paperProps = {
        width: 500,
    };

    return (
        <Container
            sx={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                open={resetCompleted}
                PaperProps={{
                    style: { borderRadius: 20 },
                }}
            >
                <div className="padding-large column">
                    <Typography variant="h3" marginBottom={"20px"}>
                        Password reimpostata!
                    </Typography>
                    <Typography variant="body1" marginBottom={"10px"} textAlign="center">
                        La password è stata reimpostata con successo! Per riaccedere al sito, clicca
                        sul pulsante sotto e rieffettua il login con la nuova password!
                    </Typography>
                    <CustomButton
                        label={"Riaccedi al sito"}
                        onClick={() => (window.location.href = "/")}
                    />
                </div>
            </Dialog>
            <Paper
                sx={{
                    padding: 4,
                    width: `${paperProps.width}px`,
                }}
            >
                <CustomResetPassword
                    schema={resetPasswordSchema}
                    submitData={onSubmit}
                    title={lang.pages.resetPassword.resetPasswordPageTitle}
                    submitLabel={lang.pages.resetPassword.resetPasswordPageSubmitButtonLabel}
                />
            </Paper>
        </Container>
    );
};
