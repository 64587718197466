import * as React from "react";
import Box from "@mui/material/Box";
import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useState } from "react";
import { theme } from "../../theme";
import { GreenButton } from "./GreenButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import { Container } from "@mui/material";
import { ScaleCompilateDialog } from "@/dialogs";
import { Compilation } from "@/services/compilationService";
import { getPdf } from "@/services/scaleService";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { AppCtx } from "@/multiLanguage/LanguagesWrapper";
import { getColumnsHeaderVariableTranslatorName } from "@/utils/utils";

export default function RecentTable({ compilations }: { compilations: Compilation[] }) {
    const lang = useTranslatedMessages();
    const { locale } = React.useContext(AppCtx);
    const [openDialogs, setOpenDialogs] = useState({
        scaleCompilate: false,
    });

    function switchVisibilityDialog(name: keyof typeof openDialogs) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }

    const columns: GridColDef[] = [
        {
            field: "clientName",
            headerName: lang.tables.recent.client,
            width: 200,
        },
        {
            field: "scale",
            headerName: lang.tables.recent.scale,
            width: 200,
        },
        {
            field: "date",
            headerName: lang.tables.recent.date,
            width: 200,
        },
        {
            field: "hour",
            headerName: lang.tables.recent.hour,
            width: 150,
        },
        {
            field: "pharmacist",
            headerName: lang.tables.recent.user,
            width: 200,
        },
        {
            field: "actions", // Nome del campo della colonna
            headerName: "", // Header della colonna
            /* renderHeader: () => <GreenButton disabled={true} text="Modifica" onClickProp={() => {

            }} />, */
            width: 150,
            headerAlign: "right",
            align: "right",
            sortable: false,
            filterable: false,
            renderCell: (params) => (
                <div>
                    <GreenButton
                        endIcon={<DownloadIcon />}
                        disabled={false}
                        text="PDF"
                        onClickProp={() => {
                            handleButtonClick(params.row); // passo i parameetri row
                        }}
                    />
                </div>
            ),
        },
    ];

    const handleButtonClick = async (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
    ) => {
        await getPdf(params.id.toString(), locale);
    };

    /* useEffect(() => {
    }, [compilations]) */

    return (
        <>
            <Box sx={{ backgroundColor: theme.palette.background.default }}>
                <Container fixed>
                    <ScaleCompilateDialog
                        isOpen={openDialogs.scaleCompilate}
                        closeDialog={() => switchVisibilityDialog("scaleCompilate")}
                    />
                    <Stack sx={{ margin: "20px" }}>
                        <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <Typography
                                sx={{ fontSize: " 1.2rem", color: theme.palette.primary.main }}
                            >
                                {lang.pages.reservedAreaRecent.tableTitle}
                            </Typography>
                            <GreenButton
                                text={lang.pages.reservedArea.addCompilationDialogHeader}
                                endIcon={<AddIcon />}
                                onClickProp={() => {
                                    switchVisibilityDialog("scaleCompilate");
                                }}
                            />
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            height: "40rem",
                            width: "100%",
                            marginBottom: "4.3rem",
                            borderRadius: "10px",
                            boxShadow:
                                "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                        }}
                    >
                        <DataGrid
                            rows={compilations ? compilations : []}
                            columns={columns}
                            getRowId={(row) => "recent_table" + row.id}
                            disableRowSelectionOnClick={true}
                            localeText={{
                                noRowsLabel: lang.tables.recent.noRowsLabel, // Personalizza il testo per le righe vuote
                                ...getColumnsHeaderVariableTranslatorName(lang),
                            }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                    color: theme.palette.primary.dark,
                                    fontSize: "0.9rem",
                                    fontWeight: "bold",
                                },
                            }}
                        />
                    </Box>
                </Container>
            </Box>
        </>
    );
}
