import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
    Box,
    DialogActions,
    DialogContent,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    useTheme,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dialog from "@mui/material/Dialog";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { useLocalStorageService } from "@/states/localStorageState";
import { ClientData, addNewClient, updateClient } from "@/services/pharmacyService";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";
import { DialogProps } from ".";
import dayjs from "dayjs";
import { CustomButton } from "@/components";
import ConfirmDialog from "./ConfirmDialog";

// const initialFormData: ClientData = {
//     name: "",
//     surname: "",
//     dateOfBirth: "",
//     cityOfBirth: "",
//     provinceOfBirth: "",
//     nationality: "italy",
//     taxCode: "",
//     email: "",
//     scalesFilledIn: 0,
//     lastEdit: Date().toString(),
// };

type AddClientDialogProps = {
    clientDataForUpdate?: ClientData;
    onSubmit?: (data: ClientData) => void;
} & DialogProps;

export function AddClientDialog({
    isOpen,
    closeDialog,
    clientDataForUpdate,
    onSubmit,
}: AddClientDialogProps) {
    const lang = useTranslatedMessages();
    const countries = lang.countries;

    const { palette } = useTheme();

    const user = useLocalStorageService((state) => state.user);
    const [openDialogs, setOpenDialogs] = useState({
        confirmDialog: false,
    });

    function switchVisibilityDialog(name: keyof typeof openDialogs) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }
    const {
        control,
        handleSubmit,
        getValues,
        trigger,
        formState: { errors, isValid },
        reset,
    } = useForm<ClientData>({
        defaultValues: {
            email: "",
            name: "",
            surname: "",
            dateOfBirth: dayjs(),
            cityOfBirth: "",
            provinceOfBirth: "",
            nationality: "",
            taxCode: "",
        },
        mode: "onBlur"
    });

    useEffect(() => {
        if (clientDataForUpdate && isOpen) {
            const dataWithFormattedDate = {
                ...clientDataForUpdate,
                dateOfBirth: clientDataForUpdate.dateOfBirth
                    ? dayjs(clientDataForUpdate.dateOfBirth, "DD/MM/YYYY")
                    : dayjs(),
            };
            reset(dataWithFormattedDate);
        } else {
            reset({
                email: "",
                name: "",
                surname: "",
                dateOfBirth: dayjs(),
                cityOfBirth: "",
                provinceOfBirth: "",
                nationality: "",
                taxCode: "",
            });
        }
    }, [isOpen, clientDataForUpdate, reset]);

    const onSubmitForm = async (data: ClientData) => {
        const formattedData = {
            ...data,
            dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth).format("DD/MM/YYYY") : "",
            // aggiungi altre proprietà qui se necessario
        };
        try {
            if (clientDataForUpdate) {
                await updateClient(user?.pharmacyId!, formattedData);
            } else {
                await addNewClient(user?.pharmacyId!, formattedData);
            }
            onSubmit?.(formattedData);
            closeDialog();
            reset();
            switchVisibilityDialog("confirmDialog");
        } catch (error) {
            console.error("Failed to submit form", error);
        }
    };

    return (
        <Dialog
            maxWidth="md"
            open={isOpen}
            onClose={() => {
                closeDialog();
                reset();
            }}
            PaperProps={{
                style: { width: "50rem", borderRadius: 0 },
            }}
        >
            <DialogHeaderComponent
                title={
                    clientDataForUpdate
                        ? lang.pages.reservedAreaClients.updateCompilationDialogHeader
                        : lang.pages.reservedAreaClients.addCompilationDialogHeader
                }
            />

            <form onSubmit={handleSubmit(onSubmitForm)}>
                <DialogContent
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
                >
                    <Box>
                        <Grid
                            alignItems={"center"}
                            alignContent={"center"}
                            justifyContent={"center"}
                            spacing={2}
                            container
                        >
                            <Grid xs={12} md={6} item>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                            message: "Formato non valido!",
                                        },
                                    }}
                                    render={({
                                        field: { onChange, onBlur, value }

                                    }) => (
                                        <TextField
                                            fullWidth
                                            disabled={clientDataForUpdate ? true : false}
                                            label={
                                                <>
                                                    {
                                                        lang.pages.reservedAreaClients
                                                            .addCompilationDialogEmail
                                                    }
                                                    *
                                                </>
                                            }
                                            margin="normal"

                                            error={!!errors.email}
                                            helperText={errors.email ? errors.email.message : ""}
                                            FormHelperTextProps={{
                                                style: { position: "absolute", top: "3.5rem" },
                                            }}
                                            value={value}
                                            onChange={onChange}
                                            onBlur={async () => {
                                                onBlur(); // chiama prima onBlur di react-hook-form
                                                await trigger("email"); // poi esegui la validazione per il campo email
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{ required: "Nome è obbligatorio!" }}
                                    render={({ field: { ...field } }) => (
                                        <TextField
                                            fullWidth
                                            label={
                                                <>
                                                    {
                                                        lang.pages.reservedAreaClients
                                                            .addCompilationDialogName
                                                    }
                                                    *
                                                </>
                                            }
                                            margin="normal"
                                            error={!!errors.name}
                                            helperText={errors.name ? errors.name.message : ""}
                                            FormHelperTextProps={{
                                                style: { position: "absolute", top: "3.5rem" },
                                            }}
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Controller
                                    name="surname"
                                    control={control}
                                    rules={{ required: "Cognome è obbligatorio!" }}
                                    render={({ field: { ref, onBlur, ...field } }) => (
                                        <TextField
                                            fullWidth
                                            label={
                                                <>
                                                    {
                                                        lang.pages.reservedAreaClients
                                                            .addCompilationDialogLastName
                                                    }
                                                    *
                                                </>
                                            }
                                            margin="normal"
                                            error={!!errors.surname}
                                            helperText={
                                                errors.surname ? errors.surname.message : ""
                                            }
                                            FormHelperTextProps={{
                                                style: { position: "absolute", top: "3.5rem" },
                                            }}
                                            {...field}
                                            onBlur={async () => {
                                                onBlur(); // chiama prima onBlur di react-hook-form
                                                await trigger("surname"); // poi esegui la validazione per il campo email
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <Box height={"3rem"} width={"100%"}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Controller
                                            name="dateOfBirth"
                                            control={control}
                                            render={({ field }) => (
                                                <DatePicker
                                                    sx={{ width: "100%" }}
                                                    label={
                                                        lang.pages.reservedAreaClients
                                                            .addCompilationDialogDateOfBirth
                                                    }
                                                    format="DD/MM/YYYY"
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Controller
                                    name="nationality"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl
                                            fullWidth
                                            margin="normal"
                                            error={!!errors.nationality}
                                        >
                                            <InputLabel>
                                                {
                                                    lang.pages.reservedAreaClients
                                                        .addCompilationDialogNationality
                                                }
                                            </InputLabel>
                                            <Select
                                                {...field}

                                                label={
                                                    lang.pages.reservedAreaClients
                                                        .addCompilationDialogNationality
                                                }
                                            >
                                                {Object.keys(countries).map((country, index) => {
                                                    return (
                                                        <MenuItem value={country} key={index}>
                                                            {countries[country]}
                                                        </MenuItem>
                                                    );
                                                })}
                                                {/*  <MenuItem value="option1">Option 1</MenuItem>
                                                <MenuItem value="option2">Option 2</MenuItem>
                                                <MenuItem value="option3">Option 3</MenuItem> */}
                                                {/* Aggiungi le opzioni desiderate per la selezione */}
                                            </Select>
                                            {errors.nationality && (
                                                <FormHelperText>
                                                    {errors.nationality.message}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    )}
                                />
                            </Grid>

                            <Grid xs={12} md={6} item>
                                <Controller
                                    name="cityOfBirth"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            label={
                                                lang.pages.reservedAreaClients
                                                    .addCompilationDialogCityOfBirth
                                            }
                                            margin="normal"
                                            error={!!errors.cityOfBirth}
                                            helperText={
                                                errors.cityOfBirth ? errors.cityOfBirth.message : ""
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Controller
                                    name="provinceOfBirth"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            label={
                                                lang.pages.reservedAreaClients
                                                    .addCompilationDialogProvinceOfBirth
                                            }
                                            margin="normal"
                                            error={!!errors.provinceOfBirth}
                                            helperText={
                                                errors.provinceOfBirth
                                                    ? errors.provinceOfBirth.message
                                                    : ""
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid xs={12} md={6} item>
                                <Controller
                                    name="taxCode"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            label={
                                                lang.pages.reservedAreaClients
                                                    .addCompilationDialogTaxCode
                                            }
                                            margin="normal"
                                            error={!!errors.taxCode}
                                            helperText={
                                                errors.taxCode ? errors.taxCode.message : ""
                                            }
                                            {...field}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "0rem" }}
                >
                    <Stack width={"100%"} flexDirection={"row"} gap={1}>
                        <CustomButton
                            sx={{
                                width: "100%",
                                borderColor: palette.secondary.dark,
                                border: "1px solid",
                                backgroundColor: palette.primary.light,
                                color: palette.secondary.dark,
                            }}
                            secondary
                            label={lang.shared.close}
                            onClick={() => {
                                closeDialog();
                                reset();
                            }}
                        />
                        <CustomButton
                            sx={{
                                width: "100%",
                                color: palette.primary.light,
                                backgroundColor: palette.secondary.dark,
                            }}
                            label={lang.pages.reservedAreaClients.addCompilationDialogButton}
                            /* type="submit" */
                            onClick={() => {
                                switchVisibilityDialog("confirmDialog");
                            }}
                            disabled={!isValid}
                            color="primary"
                        />
                    </Stack>
                </DialogActions>
            </form>
            <ConfirmDialog
                isOpen={openDialogs.confirmDialog}
                closeDialog={() => {
                    switchVisibilityDialog("confirmDialog");
                }}
                onConfirm={() => onSubmitForm(getValues())}
                title={
                    clientDataForUpdate
                        ? lang.dialogs.confirmDialog.modifyTitle
                        : lang.dialogs.confirmDialog.addTitle
                }
                message={
                    clientDataForUpdate
                        ? lang.dialogs.confirmDialog.modifyContent
                        : lang.dialogs.confirmDialog.addContent
                }
            />
        </Dialog>
    );
}
