import dayjs from "dayjs";
import { Network } from "./network";
import { User } from "@/states/localStorageState";
export interface ClientData {
    id?: string,
    name: string,
    surname: string,
    dateOfBirth: string | dayjs.Dayjs,
    cityOfBirth: string,
    provinceOfBirth: string,
    nationality: string,
    taxCode: string,
    email: string,
    scalesFilledIn: number,
    lastEdit: string
}

export const DefaultClientData: ClientData = {
    id: "xxxxx",
    name: "",
    surname: "",
    dateOfBirth: "",
    cityOfBirth: "",
    provinceOfBirth: "",
    nationality: "",
    taxCode: "",
    email: "",
    scalesFilledIn: 0,
    lastEdit: ""
}

export interface PharmacyDetail {
    id: string,
    name: string,
    email: string,
    city: string,
    address: string,
    postCode: string,
    vatNumber: string,
    phoneNumber: string,
    lat?: number,
    lng?: number
}

export interface Pharmacy {
    details: PharmacyDetail,
    users: User[],

}

export const updatePharamcy = async (pharmacyId: string, pharmacy: PharmacyDetail): Promise<any> => {
    await Network.Post(`/Pharmacy/${pharmacyId}`, pharmacy);

};


export const addNewClient = async (pharmacyId: string, client: ClientData): Promise<any> => {
    await Network.Post(`/Pharmacy/${pharmacyId}/addClient`, client);
};

export const addNewPharmacist = async (pharmacyId: string, user: User): Promise<any> => {
    await Network.Post(`/Pharmacy/${pharmacyId}/addPharmacist`, user);
};

export const updateClient = async (pharmacyId: string, client: ClientData): Promise<any> => {
    await Network.Post(`/Pharmacy/${pharmacyId}/updateClient`, client);
};
export const updatePharmacist = async (pharmacyId: string, user: User): Promise<any> => {
    await Network.Post(`/Pharmacy/${pharmacyId}/updatePharmacist/${user.id}`, user);
};
export const deleteClient = async (pharmacyId: string, client: ClientData): Promise<any> => {
    await Network.Post(`/Pharmacy/${pharmacyId}/deleteClient`, client);
};

export const deletePharmacist = async (pharmacyId: string, userId: string): Promise<any> => {
    await Network.Post(`/Pharmacy/${pharmacyId}/deletePharmacist/${userId}`, {});
};


export const getClients = async (pharmacyId: string): Promise<ClientData[]> => {
    if (pharmacyId) {
        const data = await Network.Get(`/Pharmacy/${pharmacyId}/getClients`);

        const clients: ClientData[] = data.map((item: any) => {

            return {
                id: item.email,
                name: item.name,
                surname: item.surname,
                dateOfBirth: item.dateOfBirth,
                cityOfBirth: item.cityOfBirth,
                provinceOfBirth: item.provinceOfBirth,
                nationality: item.nationality,
                taxCode: item.taxCode,
                email: item.email,
                scalesFilledIn: item.scalesFilledIn,
                lastUpdate: item.lastUpdate
            };
        });
        return clients;
    } else return []

};

export const getPharmacy = async (pharmacyId: string): Promise<Pharmacy | undefined> => {
    if (pharmacyId) {
        const data = await Network.Get(`/Pharmacy/${pharmacyId}`);

        const pharmacy: Pharmacy = {
            details: {
                id: data.id,
                name: data.name,
                vatNumber: data.vatNumber,
                address: data.address,
                city: data.city,
                postCode: data.postCode,
                email: data.email,
                phoneNumber: data.phoneNumber,
            },
            users: data.users.map((user: any) => {
                return {
                    id: user.id,
                    name: user.name,
                    email: user.email,
                    role: user.role,
                    pharmacyId: data.id
                }
            }),
        }
        return pharmacy;
    }
    else return undefined
};

export const getMapInfo = async (): Promise<PharmacyDetail[]> => {
    const data = await Network.Get(`/Pharmacy/mapInfo`);
    return data
};