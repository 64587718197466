import { Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Typography, useMediaQuery } from "@mui/material";
import { theme } from "../../theme";
import { Page, PagesListFooter, pages } from "./PagesList";
import email from "../../assets/email.png";

import telephone from "../../assets/telephone.png";
import linkedin from "../../assets/linkedin.png";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

import PrivacyPage from "@/pages/PrivacyPage";
export const privacyPages: Page = {
    page: "Privacy",
    path: "/privacy",
    translatorName: "privacy",
    isProtected: false,
    componentName: PrivacyPage,
};

export function Footer() {
    const translation = useTranslatedMessages();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Box style={{ padding: "2rem 0" }}>
            <Container fixed={!isMobile}>
                <Grid
                    paddingLeft={["1rem", "1rem", "1rem", "3rem"]}
                    paddingRight={["1rem", "1rem", "1rem", "3rem"]}
                    container
                >
                    <Grid
                        xs={12}
                        sm={4}
                        md={3}
                        paddingBottom={["3rem", "1rem", "1rem", "0rem"]}
                        alignContent={"center"}
                        item
                        container
                        textAlign={["start", "start"]}
                        borderRight={["none", "solid 1px"]}
                        justifyContent={["center"]}
                        alignItems={["center"]}
                    >
                        <Grid xs={5} sm={4} md={4} item width={"100%"}>
                            <Stack width={"100%"} justifyContent={"center"}>
                                <Box>
                                    {" "}
                                    <img
                                        src="/logoUNIVPM.gif"
                                        alt="Linkedin"
                                        style={{ maxWidth: "80%", maxHeight: "80%" }}
                                    />
                                </Box>
                            </Stack>
                        </Grid>

                        <Grid xs={7} sm={8} md={8} item>
                            <Box width={"100%"}>
                                <Typography
                                    sx={{
                                        fontSize: ["1.3rem", "1.3rem", "1.2rem", "1.5rem"],
                                        paddingBottom: "0.5rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    HUMTELEMED
                                </Typography>
                            </Box>
                            <Box width={"100%"}>
                                <Typography sx={{ color: theme.palette.primary.main }}>
                                    Spinoff di Università{" "}
                                </Typography>
                                <Typography sx={{ color: theme.palette.primary.main }}>
                                    Politecnica delle Marche
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid
                        xs={12}
                        sm={4}
                        md={6}
                        paddingBottom={["3rem", "0rem"]}
                        item
                        container
                        alignContent={"center"}
                        justifyContent={["center", "center"]}
                    >
                        <Box paddingBottom={["2rem"]} width={["100%", "90%", "90%", "65%"]}>
                            <PagesListFooter
                                marginBottom={["2rem", "1rem"]}
                                justifyContentType={["center", "start"]}
                                pagesProp={[pages[0], pages[1], privacyPages]}
                                isLogout={false}
                                textColor={theme.palette.primary.main}
                            ></PagesListFooter>
                        </Box>
                        <Box width={["100%", "90%", "90%", "65%"]}>
                            <Typography sx={{ color: theme.palette.primary.main }}>
                                {translation.footer.creators}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item container alignContent={"center"} xs={12} sm={4} md={3}>
                        <Stack
                            justifyContent={["center", "start"]}
                            width={"100%"}
                            sx={{
                                color: theme.palette.primary.main,
                                flexDirection: "row",
                                alignItems: "center",
                                paddingBottom: "10px",
                            }}
                        >
                            <Box
                                sx={{
                                    width: 35,
                                    height: 35,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src={email}
                                    alt="Linkedin"
                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                />
                            </Box>
                            <Box>
                                <Typography sx={{ paddingLeft: "10px" }}>
                                    info@humtelemed.it
                                </Typography>
                            </Box>
                        </Stack>
                        <Stack
                            justifyContent={["center", "start"]}
                            width={"100%"}
                            sx={{
                                color: theme.palette.primary.main,
                                flexDirection: "row",
                                alignItems: "center",
                                paddingBottom: "10px",
                            }}
                        >
                            <Box
                                sx={{
                                    width: 35,
                                    height: 35,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src={telephone}
                                    alt="Linkedin"
                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                />
                            </Box>
                            <Box>
                                {" "}
                                <Typography sx={{ paddingLeft: "10px" }}>
                                    +39 071 220 6126
                                </Typography>
                            </Box>
                        </Stack>
                        <Stack
                            justifyContent={["center", "start"]}
                            width={"100%"}
                            sx={{
                                color: "primary.main",
                                flexDirection: "row",
                                alignItems: "center",
                                paddingBottom: "10px",
                            }}
                        >
                            <Box
                                sx={{
                                    width: 35,
                                    height: 35,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <img
                                    src={linkedin}
                                    alt="Linkedin"
                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                />
                            </Box>
                            <Box>
                                {/* TODO: add link to LinkedIn page */}
                                <Typography sx={{ paddingLeft: "10px" }}>Linkedin</Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>

                <Box>
                    <Typography
                        sx={{
                            textAlign: "center",
                            color: theme.palette.primary.main,
                            paddingTop: "50px",
                        }}
                    >
                        © Copyright 2021-2022. HUMTELEMED S.r.l Società Benefit Via Tronto 10/A
                        60126 Ancona (AN) Italy Tel +39071 220 6126 CF e P.IVA 02939500432 Capitale
                        sociale: € 11.000,00
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
