import { useFormContext } from "react-hook-form";
import { FormControlLabel, Stack, Switch } from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";

function TakingDrugsData() {
    const lang = useTranslatedMessages();

    const { register } = useFormContext();

    return (
        <Stack gap={3}>
            {/* <Typography variant='h4'>{lang.scales.TakingDrugsComponent.title} </Typography>
             */}

            <FormControlLabel
                control={
                    <Switch
                        {...register("cholesterolMedications")}
                        data-testid="form-component-cholesterolMedications"
                        /* onChange={(e) => setValue("cholesterolMedications", e.target.checked)} */
                    />
                }
                label={lang.scales.TakingDrugsComponent.cholesterolMedications.label}
                labelPlacement="start"
                sx={{
                    marginLeft: 0,
                    marginTop: "10px",
                    marginBottom: "10px",
                    flexGrow: 1,
                    "&.MuiFormControlLabel-labelPlacementStart": {
                        justifyContent: "space-between",
                    },
                }}
            />

            <FormControlLabel
                control={
                    <Switch
                        {...register("hypertensionMedications")}
                        data-testid="form-component-hypertensionMedications"
                        /* onChange={(e) => setValue("hypertensionMedications", e.target.checked)} */
                    />
                }
                label={lang.scales.TakingDrugsComponent.hypertensionMedications.label}
                labelPlacement="start"
                sx={{
                    marginLeft: 0,
                    marginTop: "10px",
                    marginBottom: "10px",
                    flexGrow: 1,
                    "&.MuiFormControlLabel-labelPlacementStart": {
                        justifyContent: "space-between",
                    },
                }}
            />
        </Stack>
    );
}

export default TakingDrugsData;
