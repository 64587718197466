import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { Box, Container, Typography, useMediaQuery, useTheme } from "@mui/material";

import { CustomAppBar, BackButton } from "../components";
import { theme } from "@/theme";

export default function PrivacyPage() {
    const lang = useTranslatedMessages();
    const { breakpoints, palette } = useTheme();
    const isXs = useMediaQuery(breakpoints.only("xs"));

    return (
        <>
            <Box sx={{ padding: "10px", backgroundColor: theme.palette.secondary.main }}>
                <Container
                    fixed
                    sx={{ backgroundColor: theme.palette.primary.light, paddingBottom: "64px" }}
                >
                    <header>
                        <CustomAppBar
                            title="Privacy policy - Disclaimer"
                            //titleColor="white"
                            backButton={
                                <BackButton
                                    sx={{
                                        marginRight: "10px",
                                        color: theme.palette.secondary.dark,
                                    }}
                                />
                            }
                        />
                    </header>
                    <Box
                        className="scaleForm padding-large vertical-scrollable"
                        sx={{
                            ...(isXs && { height: "75%" }),
                            boxSizing: "border-box",
                            borderRadius: "20px",
                            paddingBottom: "32px",
                        }}
                    >
                        <Typography>{lang.pages.privacy.privacyIntroBody}</Typography>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 700 }}
                            color={palette.primary.main}
                            align="left"
                            padding="10px 0 10px 0"
                            textTransform="uppercase"
                        >
                            {lang.pages.privacy.privacyLiabilityTItle}
                        </Typography>
                        <Typography>{lang.pages.privacy.privacyLiabilityBody}</Typography>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 700 }}
                            color={palette.primary.main}
                            align="left"
                            padding="10px 0 10px 0"
                            textTransform="uppercase"
                        >
                            {lang.pages.privacy.privacyIndemnityTitle}
                        </Typography>
                        <Typography>{lang.pages.privacy.privacyIndemnityBody}</Typography>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 700 }}
                            color={palette.primary.main}
                            align="left"
                            padding="10px 0 10px 0"
                            textTransform="uppercase"
                        >
                            {lang.pages.privacy.privacyCopyrightTitle}
                        </Typography>
                        <Typography>{lang.pages.privacy.privacyCopyrightBody}</Typography>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 700 }}
                            color={palette.primary.main}
                            align="left"
                            padding="10px 0 10px 0"
                            textTransform="uppercase"
                        >
                            {lang.pages.privacy.privacyRightsTitle}
                        </Typography>
                        <Typography>{lang.pages.privacy.privacyRightsBody}</Typography>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 700 }}
                            color={palette.primary.main}
                            align="left"
                            padding="10px 0 10px 0"
                            textTransform="uppercase"
                        >
                            {lang.pages.privacy.privacyLawTitle}
                        </Typography>
                        <Typography>{lang.pages.privacy.privacyLawBody}</Typography>
                        <Typography
                            variant="h5"
                            sx={{ fontWeight: 700 }}
                            color={palette.primary.main}
                            align="left"
                            padding="10px 0 10px 0"
                            textTransform="uppercase"
                        >
                            {lang.pages.privacy.privacyPolicyTitle}
                        </Typography>
                        <Typography>{lang.pages.privacy.privacyPolicyBody}</Typography>
                    </Box>
                </Container>
            </Box>
        </>
    );
}
