import { FormDataFree } from "../reactHookFreeScale";
import { FormDataGlycated } from "../reactHookGlycatedScale";
import { FormDataLopre } from "../reactHookLopreScale";
import { countryRiskMap } from "./constants";

export enum DiabetesType {
    "diabete1",
    "diabete2",
}
export enum RiskLevels {
    "extreme" = 40,
    "veryHigh" = 55,
    "high" = 70,
    "moderateLow" = 100,
}
export const returnValue = (key: keyof typeof RiskLevels): [keyof typeof RiskLevels, number] => {
    return [key, RiskLevels[key]];
};

/**
 * Ottiene il livello di rischio di un paese basato sul nome del paese.
 *
 * @param {string} country - Il nome del paese.
 * @returns {string} Il livello di rischio del paese (ad esempio, "low", "moderate", "high", "very high").
 * @throws {Error} Se il nome del paese non è valido o non è presente nella mappa dei rischi dei paesi.
 */
export function getCountryRiskValue(country: string): string {
    const countryRisk: string = countryRiskMap[country];
    if (!countryRisk) {
        throw new Error("Invalid country!");
    }
    return countryRisk;
}
/**
 * Calcola il tasso di filtrazione glomerulare stimato (eGFR) utilizzando dati di sesso, creatinina e età.
 *
 * @param {string} sex - Il sesso del paziente ("male" per maschio, "female" per femmina).
 * @param {number} creatinine - Il livello di creatinina del paziente.
 * @param {number} age - L'età del paziente.
 * @returns {number} Il valore eGFR stimato.
 */
export function getEGFRValue(sex: string, creatinine: number, age: number) {
    let a, b, c: number;
    a = sex === "male" ? 141 : 144;
    b = sex === "male" ? 0.9 : 0.7;
    if (sex === "male") {
        if (creatinine > 0.9) {
            c = -1.209;
        } else {
            c = -0.411;
        }
    } else {
        if (creatinine > 0.7) {
            c = -1.209;
        } else {
            c = -0.329;
        }
    }
    const egfr: number = a * (creatinine / b) ** c * 0.993 ** age;
    return egfr;
}
export function getEventProbabilityCostant(lang: any, a: number, b: number): string {
    let lang_matrix = [
        [
            lang.scales.shared.eventProbabilityMatrix.m11,
            lang.scales.shared.eventProbabilityMatrix.m12,
            lang.scales.shared.eventProbabilityMatrix.m13,
        ],
        [
            lang.scales.shared.eventProbabilityMatrix.m21,
            lang.scales.shared.eventProbabilityMatrix.m22,
            lang.scales.shared.eventProbabilityMatrix.m23,
        ],
        [
            lang.scales.shared.eventProbabilityMatrix.m31,
            lang.scales.shared.eventProbabilityMatrix.m32,
            lang.scales.shared.eventProbabilityMatrix.m33,
        ],
        //se una persona ricade nel rischio estremo, scrivo che il suo rischio é maggiore del 20% indipendente dall'età
        [
            lang.scales.shared.eventProbabilityMatrix.m41,
            lang.scales.shared.eventProbabilityMatrix.m42,
            lang.scales.shared.eventProbabilityMatrix.m43,
        ],
    ];
    return lang_matrix[a][b];
}

/**
 * Ottiene la probabilità di eventi in base all'età e al livello di rischio.
 *
 * @param {number} age - L'età del paziente.
 * @param {keyof typeof RiskLevels} riskLevel - Il livello di rischio ("moderateLow", "high", "veryHigh", "extreme").
 * @param {any} lang - L'oggetto lingua o dati di localizzazione.
 * @returns {string} La probabilità di eventi calcolata come una stringa.
 */
export function getEventProbabilityValue(
    age: number,
    riskLevel: keyof typeof RiskLevels,
    lang: any
): string {
    let a, b: number;
    if (riskLevel === "moderateLow") {
        a = 0;
    } else if (riskLevel === "high") {
        a = 1;
    } else if (riskLevel === "veryHigh") {
        a = 2;
    } else {
        a = 3;
    }
    if (age < 50) {
        b = 0;
    } else if (age >= 50 && age < 70) {
        b = 1;
    } else {
        b = 2;
    }
    return getEventProbabilityCostant(lang, a, b);
}

/**
 * Calcola l'Indice di Massa Corporea (BMI) basato sul peso e sull'altezza forniti.
 *
 * @param {number} weight - Il peso in chilogrammi.
 * @param {number} height - L'altezza in centimetri.
 * @returns {number | null} Il valore del BMI calcolato o null se il peso o l'altezza sono nulli o non validi.
 */
export function calculateBMI(weight: number, height: number) {
    if (weight && height) {
        //x10000 perchè la height è in cm
        return (weight * 10000) / (height * height);
    } else {
        return null;
    }
}
/**
 * Questa è una funzione di esempio che saluta una persona.
 *
 * @param {FormDataFree | FormDataLopre | FormDataGlycated } formData - I dati della form della scala.
 * @param {string} countryRisk - Rapressenta il rischio del paese.
 * @param {boolean} smoke - Indica se l'utente è considerato un fumatore.
 * @returns {number[]} Un array di valori numerici che rappresentano il punteggio calcolato.
 *   - [0] - Rischio del paese (a): 0 (low), 1 (moderate), 2 (high), 3 (very high).
 *   - [1] - Sesso (b): 0 (female), 1 (male).
 *   - [2] - Fumo (c): 0 (false), 1 (true).
 *   - [3] - Età (d): 0-9 a seconda dell'età.
 *   - [4] - Pressione sistolica (e): 0-3 a seconda della pressione sistolica.
 *   - [5] - Colesterolo non HDL (f): 0-3 a seconda del colesterolo non HDL.
 * @throws {Error} Se i parametri di input sono invalidi.
 */
export function getScore2Value(
    formData: FormDataLopre | FormDataGlycated | FormDataFree,
    countryRisk: string,
    smoke: boolean
): number[] {
    let { sex, age, systolicPressure, totalCholesterol, hdlCholesterol } = formData;
    const noHdlCholesterol = totalCholesterol - hdlCholesterol;
    let a, b, c, d, e, f;
    switch (countryRisk) {
        case "low":
            a = 0;
            break;
        case "moderate":
            a = 1;
            break;
        case "high":
            a = 2;
            break;
        case "very high":
            a = 3;
            break;
        default:
            throw new Error("invalid country");
    }
    switch (sex) {
        case "female":
            b = 0;
            break;
        case "male":
            b = 1;
            break;
        default:
            throw new Error("invalid sex");
    }
    switch (smoke) {
        case false:
            c = 0;
            break;
        case true:
            c = 1;
            break;
        default:
            throw new Error("invalid smoking");
    }
    if (age >= 85 && age <= 89) {
        d = 0;
    } else if (age >= 80 && age <= 84) {
        d = 1;
    } else if (age >= 75 && age <= 79) {
        d = 2;
    } else if (age >= 70 && age <= 74) {
        d = 3;
    } else if (age >= 65 && age <= 69) {
        d = 4;
    } else if (age >= 60 && age <= 64) {
        d = 5;
    } else if (age >= 55 && age <= 59) {
        d = 6;
    } else if (age >= 50 && age <= 54) {
        d = 7;
    } else if (age >= 45 && age <= 49) {
        d = 8;
    } else if (age >= 40 && age <= 44) {
        d = 9;
    } else {
        throw new Error("invalid age");
    }
    //considerare punteggio rischio maggiore da 160
    if (systolicPressure >= 160) {
        e = 0;
    } else if (systolicPressure >= 140 && systolicPressure <= 159) {
        e = 1;
    } else if (systolicPressure >= 120 && systolicPressure <= 139) {
        e = 2;
    } else if (systolicPressure <= 119) {
        e = 3;
    } else {
        throw new Error("invalid systolic pressure");
    }
    if (noHdlCholesterol <= 150) {
        f = 0;
    } else if (noHdlCholesterol > 150 && noHdlCholesterol <= 200) {
        f = 1;
    } else if (noHdlCholesterol > 200 && noHdlCholesterol <= 250) {
        f = 2;
    } else if (noHdlCholesterol > 250) {
        f = 3;
    } else {
        throw new Error("invalid noHdlCholesterol");
    }
    return [a, b, c, d, e, f];
}
/**
 * Determina se una persona è attualmente un fumatore o un ex fumatore.
 *
 * @param {boolean} actualSmoker - Indica se la persona è attualmente un fumatore (true) o no (false).
 * @param {number} exSmokerYears - Il numero di anni trascorsi dall'ex fumatore (0 se attualmente non è un ex fumatore).
 * @returns {boolean} Restituisce true se la persona è un attuale fumatore o un ex fumatore con almeno 1 anno di astinenza, altrimenti restituisce false.
 */
export function setSmoke(actualSmoker: boolean, exSmokerYears: number) {
    return actualSmoker || exSmokerYears === 0;
}
/**
 * Calcola il valore Friedewald utilizzando dati di colesterolo.
 *
 * @param {number} totalCholesterol - Il livello totale di colesterolo.
 * @param {number} hdlCholesterol - Il livello di colesterolo HDL.
 * @param {number} triglycerides - Il livello di trigliceridi.
 * @param {number} martinHopkins - Il punteggio Martin-Hopkins.
 * @returns {number} Il valore Friedewald calcolato.
 */
export function friedewald(
    totalCholesterol: number,
    hdlCholesterol: number,
    triglycerides: number,
    martinHopkins: number
) {
    return totalCholesterol - hdlCholesterol - triglycerides / martinHopkins;
}
