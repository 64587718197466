import AboutUsComponent from "@/components/newGraphics/AboutUs";
import React from "react";

export default function AboutUs() {
    return (
        <>
            <AboutUsComponent />
        </>
    );
}
