import React, { useEffect, useMemo } from "react";
import Recent from "./subPages/Recent";

import Profile from "./subPages/Profile";

import { useLocalStorageService } from "../../../states/localStorageState";
import Client from "./subPages/Client";
import ViewAppBar from "@/components/newGraphics/ViewAppBar";

type subPage = {
    subPageName: string;
    component: JSX.Element;
};
export const subPages: subPage[] = [
    { subPageName: "Recenti", component: <Recent /> },
    { subPageName: "Clienti", component: <Client /> },
    { subPageName: "Profilo", component: <Profile /> },
];

export default function ReservedArea() {
    const selectedPage = useLocalStorageService((state) => state.selectedSubPage);

    const selectedSubPage = useMemo(
        () => subPages.find((subPage) => subPage.subPageName === selectedPage),
        [selectedPage]
    );
    const SelectedComponent = selectedSubPage?.component || <Recent />;

    useEffect(() => {}, [selectedPage]);

    useEffect(() => {}, [SelectedComponent]);

    return (
        <>
            <ViewAppBar />
            {SelectedComponent}
        </>
    );
}
