import { useTranslatedMessages } from "@/multiLanguage/i18n";
import {
    Box,
    Button,
    Container,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useNavigate, useRouteError } from "react-router";

export default function ErrorPage() {
    const lang = useTranslatedMessages();
    const error: any = useRouteError();
    const navigate = useNavigate();
    //const maxPx: MutableRefObject<Number> = 300;
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const buttonSX = {
        "&:hover": {
            backgroundColor: "white",
        },
    };

    return (
        <Container sx={{ display: "flex", minHeight: "100vh" }}>
            <Stack sx={{ justifyContent: "center" }}>
                <Grid
                    sx={{ alignItems: "center", justifyContent: "center" }}
                    container
                    spacing={2}
                    gap={5}
                >
                    <Grid item xs={12} md={4}>
                        <img
                            src="Ops.png"
                            alt=""
                            style={{
                                maxHeight: "500px",
                                width: "100%",
                                objectFit: "contain",
                                objectPosition: "bottom",
                            }}
                        />
                    </Grid>
                    <Grid item xs={8} md={6}>
                        <Typography
                            sx={{ textAlign: matches ? "center" : "start", color: "white" }}
                            variant={matches ? "h3" : "h2"}
                            /*                             sx={{ fontSize: "2.5rem", fontWeight: "bold", color: "white" }} */
                        >
                            {lang.pages.error.messageErrorPage}
                        </Typography>
                        <Typography
                            sx={{ textAlign: matches ? "center" : "start", color: "white" }}
                            variant="body1"
                            marginTop={"15px"}
                            color="white"
                            /*                             sx={{ fontSize: "2.5rem", fontWeight: "bold", color: "white" }} */
                        >
                            Error: {error.status} {error.statusText}
                        </Typography>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                sx={{
                                    backgroundColor: "white",
                                    marginTop: "30px",
                                    borderRadius: "20px",
                                    ...buttonSX,
                                }}
                                variant="outlined"
                                onClick={() => {
                                    navigate("/");
                                    window.scrollTo(0, 0);
                                }}
                            >
                                <strong>{lang.pages.error.backToHome}</strong>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
        </Container>
    );
}
