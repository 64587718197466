import { useLocalStorageService } from "@/states/localStorageState";

export const useSettingsService = () => {
    const settings = useLocalStorageService((store) => store.settings);
    const setSettings = useLocalStorageService((store) => store.setSettings);

    const setSkipTutorial = (value: boolean) => {
        setSettings({
            ...settings,
            skipTutorial: value,
        });
    };

    return { settings, setSkipTutorial };
};
