import { Dialog, Stack, Typography, useTheme } from "@mui/material";

import { CustomButton } from "../components";
import { DialogProps } from ".";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";

export function MessageSentDialog({ isOpen, closeDialog }: DialogProps) {
    const lang = useTranslatedMessages();
    const { palette } = useTheme();
    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={isOpen}
            onClose={closeDialog}
            PaperProps={{
                style: { borderRadius: 20 },
            }}
        >
            <DialogHeaderComponent title={lang.dialogs.message.confirmMessageHeader} />
            <Stack
                sx={{
                    paddingLeft: "1rem",
                    paddingTop: "1rem",
                    paddingRight: "1rem",
                    backgroundColor: palette.background.default,
                }}
                alignItems="center"
                gap={2}
            >
                <img
                    src="messageSent.png"
                    alt="Messaggio inviato"
                    style={{
                        width: "50%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: "20px",
                    }}
                />

                <Typography variant="body1">{lang.dialogs.message.confirmMessageSent}</Typography>
                <CustomButton
                    sx={{ backgroundColor: palette.secondary.dark, color: palette.primary.light }}
                    label={lang.shared.close}
                    onClick={closeDialog}
                />
            </Stack>
        </Dialog>
    );
}
