import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useLocalStorageService, User } from "../../states/localStorageState";
import { useEffect, useState } from "react";
import { theme } from "../../theme";
import { GreenButton } from "./GreenButton";
import { Container, Grid, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Pharmacy, PharmacyDetail, deletePharmacist } from "@/services/pharmacyService";
import { AddPharmacistDialog } from "@/dialogs/AddPharmacistDialog";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { ReportDialog } from "@/dialogs/ReportDialog";
import { UpdatePharmacyDialog } from "@/dialogs/UpdatePharmacyDialog";
import ConfirmDialog from "@/dialogs/ConfirmDialog";
import { ErrorDialog } from "@/dialogs/ErrorDialog";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { getColumnsHeaderVariableTranslatorName } from "@/utils/utils";
export default function ProfileTable({
    pharmacy: _pharmacy,
    reloadData,
}: {
    pharmacy: Pharmacy;
    reloadData: () => void;
}) {
    const lang = useTranslatedMessages();
    const user = useLocalStorageService((state) => state.user);
    const [pharmacy, setPharmacy] = useState<Pharmacy>(_pharmacy);
    const [error, setError] = useState<string>("");
    const [updateUser, setUpdateUser] = useState<User | undefined>(undefined);
    const [selectedDeleteId, setSelectedDeleteId] = useState<string | undefined>(undefined);
    const [openDialogs, setOpenDialogs] = useState({
        addPharmacistDialog: false,
        assistenceDialog: false,
        updatePharmacyDialog: false,
        confirmDialog: false,
        errorDialog: false,
    });

    function switchVisibilityDialog(name: keyof typeof openDialogs) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }

    const columns2: GridColDef[] = [
        {
            field: "name",
            headerName: lang.tables.user.name,
            flex: 1,
        },
        {
            field: "role",
            headerName: lang.tables.user.role,
            flex: 1,
        },
        {
            field: "actions",
            headerName: "",
            flex: 1,
            align: "right",
            renderCell: (params) => {
                const rowId = params.row.id;

                return (
                    <Stack justifyContent={["center", "center"]} flexDirection={["row", "row"]}>
                        {user!.role === "Admin" ? (
                            <>
                                {" "}
                                <GreenButton
                                    text=""
                                    endIcon={<EditIcon />}
                                    disabled={user!.role !== "Admin"}
                                    onClickProp={() => {
                                        setUpdateUser(params.row);
                                        switchVisibilityDialog("addPharmacistDialog");
                                    }}
                                />
                                <GreenButton
                                    text=""
                                    isDeleteButton={true}
                                    endIcon={<DeleteIcon />}
                                    disabled={user!.role !== "Admin"}
                                    onClickProp={async () => {
                                        setSelectedDeleteId(rowId);
                                        switchVisibilityDialog("confirmDialog");
                                    }}
                                />
                            </>
                        ) : null}
                    </Stack>
                );
            },
        },
    ];

    useEffect(() => {
        setPharmacy(_pharmacy);
    }, [_pharmacy]);

    const handleDataSubmit = (data: PharmacyDetail) => {
        setPharmacy((oldPharmacy) => ({ ...oldPharmacy, details: data }));
    };

    const deleteUserById = async () => {
        try {
            if (selectedDeleteId) {
                await deletePharmacist(user!.pharmacyId!, selectedDeleteId);
                switchVisibilityDialog("confirmDialog");
                setSelectedDeleteId(undefined);
                reloadData();
            } else {
                throw new Error("Error: No user Id selected");
            }
        } catch (error) {
            switchVisibilityDialog("confirmDialog");
            console.log(error);
            setError(`Error: ${error.response.data}`);
            switchVisibilityDialog("errorDialog");
        }
    };

    return (
        <Box sx={{ backgroundColor: theme.palette.background.default }}>
            <Container fixed>
                <AddPharmacistDialog
                    isOpen={openDialogs.addPharmacistDialog}
                    userDataForUpdate={updateUser}
                    closeDialog={() => {
                        switchVisibilityDialog("addPharmacistDialog");
                        setUpdateUser(undefined);
                        reloadData();
                    }}
                />
                <UpdatePharmacyDialog
                    isOpen={openDialogs.updatePharmacyDialog}
                    pharmacyOldData={pharmacy!.details}
                    closeDialog={() => switchVisibilityDialog("updatePharmacyDialog")}
                    onSubmit={handleDataSubmit}
                />
                <Grid padding={["1rem"]} container>
                    <Grid padding={["0rem", "1rem"]} item xs={12} sm={12} md={6} lg={6}>
                        <Stack marginTop={["1rem", "0rem"]} height={["7rem", "4rem", "3rem"]}>
                            <Stack
                                flexDirection={["column", "row"]}
                                height={["100%", "auto"]}
                                sx={{
                                    justifyContent: "space-between",
                                    alignContent: "space-between",
                                    alignItems: "space-between",
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: " 1.2rem",
                                        color: theme.palette.primary.main,
                                        paddingBottom: "5px",
                                    }}
                                >
                                    {lang.pages.reservedAreaProfile.detailsText}
                                </Typography>
                                <GreenButton
                                    disabled={user!.role !== "Admin"}
                                    text={lang.pages.reservedAreaProfile.assistenceButton}
                                    endIcon={<SupportAgentIcon />}
                                    onClickProp={() => {
                                        switchVisibilityDialog("assistenceDialog");
                                    }}
                                />
                                <GreenButton
                                    disabled={user!.role !== "Admin"}
                                    text={lang.pages.reservedAreaProfile.updateButton}
                                    endIcon={<EditIcon />}
                                    onClickProp={() => {
                                        switchVisibilityDialog("updatePharmacyDialog");
                                    }}
                                />
                            </Stack>
                        </Stack>
                        <Box
                            marginTop={["1rem", "0rem"]}
                            sx={{
                                height: "30rem",
                                width: "100%",
                                borderRadius: "10px",
                                marginBottom: ["0rem", "0rem", "4.3rem"],
                                boxShadow:
                                    "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                            }}
                        >
                            {Object.entries(lang.tables.profileDetail).map(([key, label]) => (
                                <Stack key={key} className="row" direction={"row"}>
                                    <Box
                                        sx={{
                                            width: "50%",
                                            padding: "15px 20px",
                                            fontWeight: "bold",
                                            backgroundColor: theme.palette.background.default,
                                        }}
                                        className="field"
                                    >
                                        {label}
                                    </Box>
                                    <Box
                                        sx={{ width: "50%", padding: "15px 20px" }}
                                        className="dato"
                                    >
                                        {pharmacy?.details[key] || ""}
                                    </Box>
                                </Stack>
                            ))}
                        </Box>
                    </Grid>
                    <Grid padding={["0rem", "1rem"]} item xs={12} sm={12} md={6}>
                        <Stack marginTop={["2rem", "0rem"]} height={["4rem", "4rem", "3rem"]}>
                            <Stack sx={{ flexDirection: "row", justifyContent: "space-between" }}>
                                <Typography
                                    sx={{ fontSize: " 1.2rem", color: theme.palette.primary.main }}
                                >
                                    {lang.pages.reservedAreaProfile.usersText}
                                </Typography>
                                {user!.role === "Admin" ? (
                                    <>
                                        <GreenButton
                                            disabled={user!.role !== "Admin"}
                                            text={lang.pages.reservedAreaProfile.addButton}
                                            endIcon={<AddIcon />}
                                            onClickProp={() => {
                                                switchVisibilityDialog("addPharmacistDialog");
                                            }}
                                        />
                                    </>
                                ) : null}{" "}
                            </Stack>
                        </Stack>
                        <Box
                            sx={{
                                height: "30rem",
                                width: "100%",
                                borderRadius: "10px",
                                boxShadow:
                                    "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                            }}
                        >
                            <DataGrid
                                rowHeight={50}
                                rows={pharmacy ? pharmacy.users : []}
                                columns={columns2}
                                disableRowSelectionOnClick={true}
                                initialState={{
                                    pagination: { paginationModel: { pageSize: 7 } },
                                }}
                                localeText={{
                                    ...getColumnsHeaderVariableTranslatorName(lang),
                                }}
                                sx={{
                                    /*    '& .MuiDataGrid-footerContainer': {
                       display: 'none', // Questo nasconde il piè di pagina che include il conteggio delle pagine e i controlli di navigazione
                     }, */
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        color: theme.palette.primary.dark,
                                        fontSize: "0.9rem",
                                        fontWeight: "bold",
                                    },
                                    "& .MuiDataGrid-cell": {
                                        border: "none",
                                    },
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <ReportDialog
                    isOpen={openDialogs.assistenceDialog}
                    closeDialog={() => {
                        switchVisibilityDialog("assistenceDialog");
                    }}
                />
                <ConfirmDialog
                    isOpen={openDialogs.confirmDialog}
                    closeDialog={() => {
                        switchVisibilityDialog("confirmDialog");
                    }}
                    onConfirm={async () => {
                        await deleteUserById();
                    }}
                    title={lang.dialogs.confirmDialog.deleteTitle}
                    message={lang.dialogs.confirmDialog.deleteContent}
                />
                <ErrorDialog
                    isOpen={openDialogs.errorDialog}
                    closeDialog={() => {
                        switchVisibilityDialog("errorDialog");
                    }}
                    test={error}
                />
            </Container>
        </Box>
    );
}
