import { Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import RecentTable from "../../../../components/newGraphics/RecentTable";
import { useLocalStorageService } from "@/states/localStorageState";
import { Compilation, getRecentCompilations } from "@/services/compilationService";
import ViewAppBar from "@/components/newGraphics/ViewAppBar";

export default function Recent() {
    const user = useLocalStorageService((state) => state.user);
    const [compilations, setCompilations] = useState<Compilation[]>([]);
    const hasInizalized = useRef(false);
    useEffect(() => {
        if (!hasInizalized.current) {
            const fetchData = async () => {
                try {
                    const compilationsData = await getRecentCompilations(user?.pharmacyId!);
                    setCompilations(compilationsData);
                } catch (error) {
                    console.error("Errore nel recupero delle compilazioni recenti", error);
                }
            };
            hasInizalized.current = true;
            fetchData();
        }
    }, [hasInizalized]);

    return (
        <Stack>
            <ViewAppBar />
            <RecentTable compilations={compilations} />
        </Stack>
    );
}
