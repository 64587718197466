import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ScaleState {
    selectedScale: string | null;
    formData: any;
    scaleIndexes: any;
    unitOfMeasure: { [field: string]: { index: number; endText: string } };
    setSelectedScale: (scaleName: string | null) => void;
    setFormData: (by: any) => void;
    setScaleIndex: (by: any) => void;
    setUnitOfMeasure: (by: any) => void;
}

export const useScaleStore = create<ScaleState>()(
    devtools((set, get) => ({
        selectedScale: null,
        formData: {},
        scaleIndexes: {},
        unitOfMeasure: {},
        setSelectedScale: (selectedScale) => set((_) => ({ selectedScale })),
        setFormData: (data) => set((_) => ({ formData: data })),
        setScaleIndex: (getIndexes) => set((_) => ({ scaleIndexes: getIndexes })),
        setUnitOfMeasure: (data) =>
            set((prevState) => ({
                unitOfMeasure: {
                    ...prevState.unitOfMeasure,
                    ...data,
                },
            })),
    }))
);
