import { useFormContext } from "react-hook-form";
import { FormControlLabel, Stack, Switch, IconButton, Popover, Typography } from "@mui/material";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import HelpIcon from "@mui/icons-material/Help";
import { useState } from "react";
import { theme } from "@/theme";

function FamilyHycholData() {
    const lang = useTranslatedMessages();
    const { register } = useFormContext();
    const [infoTextIsOpen, setInfoTextIsOpen] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<string>("");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    return (
        <Stack gap={3}>
            {/* <Typography variant='h4'>{lang.scales.cardiovascularEventsComponent.title} </Typography>
             */}

            <FormControlLabel
                control={
                    <Stack flexDirection={"row"} alignItems={"center"}>
                        <Switch
                            {...register("familyHychol")}
                            data-testid="form-component-familyHychol"
                            /* onChange={(e) => setValue("familyHychol", e.target.checked)} */
                        />
                        <IconButton
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setInfoText(
                                    lang.scales.scaleWithComponentShared.familyHychol.textInfo
                                );
                                setInfoTextIsOpen(true);
                            }}
                            edge="end"
                        >
                            <HelpIcon sx={{ color: theme.palette.secondary.light }} />
                        </IconButton>
                    </Stack>
                }
                label={lang.scales.scaleWithComponentShared.familyHychol.label}
                labelPlacement="start"
                sx={{
                    marginLeft: 0,
                    marginTop: "10px",
                    marginBottom: "10px",
                    flexGrow: 1,
                    "&.MuiFormControlLabel-labelPlacementStart": {
                        justifyContent: "space-between",
                    },
                }}
            />

            <Popover
                open={infoTextIsOpen}
                anchorEl={anchorEl}
                onClose={() => {
                    setInfoTextIsOpen(false);
                }}
                disableScrollLock
                transformOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <Typography sx={{ p: 2 }}>{infoText}</Typography>
            </Popover>
        </Stack>
    );
}

export default FamilyHycholData;
