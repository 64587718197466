import React from "react";
//import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
//import jsonData from '../assets/maps_style_json.json';
import { Box } from "@mui/material";

interface MapProperties {
    mapSource?: string;
}

export function MapContainer({
    mapSource = "https://maps.google.com/maps?q=ancona%20via%20tronto%2010a&t=&z=17&ie=UTF8&iwloc=&output=embed",
}: MapProperties) {
    //  const apiKey = '';//"AIzaSyDHUqGC5_9mVKypyamQWHN6nXf635Ir1E0 " // Sostituisci con la tua chiave API di Google Maps AIzaSyDSN1VQQsQ_a2deJ2wNTIpW7y3xilCgtAo -> Aidapt
    //
    //  const mapStyles: React.CSSProperties = {
    //    height: '400px',
    //    width: '100%',
    //  };
    //
    //  const defaultCenter = {
    //    lat: 43.60901,
    //    lng: 13.45608, // Coordinate di New York City (puoi cambiare queste coordinate)
    //  };
    //
    //  const mapOptions = {
    //    styles: jsonData.styles ,
    //    draggable: true, // Blocca il trascinamento della mappa
    //    zoomControl: true, // Nasconde i pulsanti per il controllo dello zoom
    //    fullscreenControl:false,
    //    keyboardShortcuts:false,
    //    mapTypeControl:false,
    //    streetViewControl:false
    //};
    //const customMarkers = [
    //    { lat: 43.60900, lng: 13.45608 }, // Esempio di coordinata per un marker personalizzato
    //    // Aggiungi altri marker personalizzati qui...
    //  ];

    return (
        <Box width={["100%"]}>
            <iframe
                style={{
                    filter: "grayscale(100%)",
                    overflow: "hidden",
                    background: "none!important",
                }}
                width="100%"
                height="400"
                id="gmap_canvas"
                title="Google Maps"
                src={mapSource}
                frameBorder="0"
                scrolling="no"
            ></iframe>
        </Box>
    );
}

export default MapContainer;

/*  <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={17}
        center={defaultCenter}
        options={mapOptions}
      >
        {customMarkers.map((marker) => (
            <Marker key={6} position={marker} />
      ))}
      </GoogleMap>
    </LoadScript> */
