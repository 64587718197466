import { Grid } from "@mui/material";

interface TutorialCardProps {
    title: string;
    text: string;
    image: string;
}

export function TutorialCard({ title, text, image }: TutorialCardProps) {
    return (
        <Grid container alignItems="center" justifyContent="space-between" sx={{ width: "100%" }}>
            <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
                <img src={image} style={{ width: "200px" }} alt="tutorial" />
            </Grid>
            <Grid item container direction="column" alignItems="center" xs={12} md={6}>
                <Grid item>
                    <h3 className="padding-large bold">{title}</h3>
                </Grid>
                <Grid item style={{ width: "80%" }}>
                    <h4 className="padding-large">{text}</h4>
                </Grid>
            </Grid>
        </Grid>
    );
}
