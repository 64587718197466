import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import "../../App.css";
import { theme } from "../../theme";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { ContactDialog, MessageSentDialog } from "@/dialogs";
import { useNavigate } from "react-router-dom";
import { useAuthService } from "@/services/authService";
import { useLocalStorageService } from "@/states/localStorageState";
import { HomeRuleDialog } from "@/dialogs/HomeRuleDialog";
import { useReportService } from "@/services/reportService";
import {
    GeoPosition,
    GoogleMapWithMarkers,
    MarkerProperty,
    defaultCenterPositionMap,
} from "./GoogleMapsComponent";
import { PharmacyDetail, getMapInfo } from "@/services/pharmacyService";

const cardTestContinueToReadLenghtDesktop = 250;
const cardTestContinueToReadLenghtMobile = 180;

function troncaStringaVicinoALimite(testoCompleto: string, isMobile: boolean): string {
    let cartTextContinueToRead: number;
    if (isMobile) {
        cartTextContinueToRead = cardTestContinueToReadLenghtMobile;
    } else {
        cartTextContinueToRead = cardTestContinueToReadLenghtDesktop;
    }

    if (testoCompleto.length <= cartTextContinueToRead) {
        return testoCompleto; // Se la stringa è già breve, non c'è bisogno di troncarla.
    }

    const limite = cartTextContinueToRead;
    let indiceSpazioPiuVicino = -1;

    for (let i = limite; i >= 0; i--) {
        if (testoCompleto.charAt(i) === " ") {
            indiceSpazioPiuVicino = i;
            break;
        }
    }

    if (indiceSpazioPiuVicino !== -1) {
        return testoCompleto.substring(0, indiceSpazioPiuVicino);
    } else {
        // Se non è stato trovato uno spazio, tronca semplicemente a 50 caratteri.
        return testoCompleto.substring(0, limite);
    }
}
export interface rulesHomeCard {
    imgPath: string;
    title: string;
    content: string;
}
export interface contibutorHomeCard {
    imgPath: string;
    size: number[]; //indica il peso dei contributors -> sarebbe la grandezza dell grid -> 12 = riga intera, 6=meta riga, 3= 1/4, 2=1/6, 1 = 1/12
    name: string;
}
const contributorsHomeCardArray: contibutorHomeCard[] = [
    {
        imgPath: "/home8.png",
        size: [12, 2.5],
        name: "Daiichi-Sankyo",
    },
    {
        imgPath: "/home7.png",
        size: [12, 2.5],
        name: "PIAM",
    },
    {
        imgPath: "/home9.png",
        size: [12, 2.5],
        name: "SANDOZ",
    },
    {
        imgPath: "/Viatris_Logo.png",
        size: [12, 2.5],
        name: "SANDOZ",
    },
    {
        imgPath: "/Logo-Fidia-2020-rgb.png",
        size: [6, 1.5],
        name: "Fidia Farmaceutici",
    },
];

function HomeComponent() {
    const [topPharmacies, setTopPharmacies] = useState<PharmacyDetail[] | []>([]);
    const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);
    // Funzione per ottenere la posizione dell'utente
    const translation = useTranslatedMessages();

    const rulesHomeCardArray: rulesHomeCard[] = [
        {
            imgPath: "/home2.png",
            title: translation.pages.home.cardOneTitle,
            content: translation.pages.home.cardOneContent,
        },
        {
            imgPath: "/home3.png",
            title: translation.pages.home.cardTwoTitle,
            content: translation.pages.home.cardTwoContent,
        },
        {
            imgPath: "/home4.png",
            title: translation.pages.home.cardThreeTitle,
            content: translation.pages.home.cardThreeContent,
        },
    ];
    const handleScroll = () => {
        // Trova l'elemento a cui vuoi scorrere
        const anchorElement = document.getElementById("homemap");

        // Calcola la posizione
        const topOffset = anchorElement!.getBoundingClientRect().top + window.pageYOffset;

        // Esegui lo scorrimento animato
        window.scrollTo({
            top: topOffset,
            behavior: "smooth",
        });
    };
    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation({ lat: latitude, lng: longitude });
                },
                (error) => {
                    //console.error('Errore nell\'ottenere la posizione:', error);
                }
            );
        } else {
            //console.error('Geolocalizzazione non supportata dal tuo browser.');
        }
    };

    const [mapInfo, setMapInfo] = useState<MarkerProperty[]>([]);
    const [markersLoaded, setMarkersLoaded] = useState(false);
    const hasInizalized = useRef(false);

    const fetchData = async () => {
        try {
            const mapInfo = await getMapInfo();
            setMapInfo(generateMarkers(mapInfo));
            setMarkersLoaded(true);
        } catch (error) {
            setMarkersLoaded(true);
        }
    };
    useEffect(() => {
        if (!hasInizalized.current) {
            hasInizalized.current = true;
            ////TODO scommentare quando torna la mappa
            //fetchData();
        }
    }, [hasInizalized]);

    useEffect(() => {
        //TODO scommentare quando torna la mappa
        //getUserLocation();
    }, []);

    const { contactRequest } = useReportService();
    const navigate = useNavigate();
    const { isLoggedIn } = useAuthService();
    const setSelectedPage = useLocalStorageService((state) => state.setSelectedPage);
    const [openDialogs, setOpenDialogs] = useState({
        //login: false,
        contact: false,
        sent: false,
    });
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [selectedHomeRulesCard, setSelectedHomeRulesCard] = useState<rulesHomeCard | null>(null);
    const handleDialogOpen = (rules: rulesHomeCard) => {
        setSelectedHomeRulesCard(rules);
        setIsDialogOpen(true);
    };

    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setSelectedHomeRulesCard(null);
    };

    function switchVisibilityDialog(name: keyof typeof openDialogs) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }

    function evaluatesRisk() {
        if (isLoggedIn()) {
            setSelectedPage("Recenti");
            navigate("/reserved_area");
            window.scrollTo(0, 0);
        } else {
            navigate("/tutorial");
            window.scrollTo(0, 0);
        }
    }

    const generateMarkers = (pharmaciesInfo: PharmacyDetail[]): MarkerProperty[] => {
        const markers: MarkerProperty[] = pharmaciesInfo.map((pharmacy: PharmacyDetail) => {
            return {
                id: pharmacy.id,
                title: pharmacy.name,
                pharmacy: pharmacy,
                position: { lat: pharmacy.lat, lng: pharmacy.lng } as GeoPosition,
            } as MarkerProperty;
        });

        return markers;
    };

    return (
        <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}>
            <>
                {isMobile ? (
                    <Box>
                        <Grid
                            sx={{
                                background: `linear-gradient(90deg,${theme.palette.secondary.main} ,${theme.palette.secondary.light})`,
                            }}
                            justifyContent={"center"}
                            container
                        >
                            <Grid
                                overflow={"hidden"}
                                paddingTop={["3rem"]}
                                height={["23rem", "35rem"]}
                                xs={12}
                                md={6}
                                item
                            >
                                <Box>
                                    <img
                                        src={"/home1.png"}
                                        alt="Humtelemed"
                                        width={"100%"}
                                        style={{ mixBlendMode: "multiply", objectFit: "cover" }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid
                                paddingLeft={["1rem"]}
                                paddingRight={["1rem"]}
                                paddingTop={["2rem"]}
                                xs={12}
                                md={6}
                                item
                            >
                                <Stack textAlign={"center"}>
                                    <Box marginBottom={["2rem"]}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.primary.main,
                                                fontFamily: "Open Sans",
                                                fontWeight: 400,
                                                fontSize: "1rem",
                                                lineHeight: "1.56rem",
                                            }}
                                        >
                                            {" "}
                                            {translation.pages.home.headerContentOne}
                                        </Typography>
                                    </Box>
                                    <Stack
                                        marginBottom={["2rem"]}
                                        justifyContent={["center"]}
                                        flexDirection={"column"}
                                    >
                                        <Box marginBottom={["1rem"]} width={["100%"]}>
                                            <Button
                                                onClick={() => evaluatesRisk()}
                                                sx={{
                                                    width: ["100%"],
                                                    borderRadius: "10px",
                                                    height: ["3.5rem"],
                                                    backgroundColor: theme.palette.secondary.dark,
                                                    color: theme.palette.primary.light,
                                                    textTransform: "none",
                                                    fontFamily: "Open Sans",
                                                    fontSize: "1rem",
                                                    fontWeight: 600,
                                                    "&:hover": {
                                                        backgroundColor:
                                                            theme.palette.secondary.dark,
                                                    },
                                                }}
                                            >
                                                {translation.pages.home.headerButtonOne}
                                            </Button>{" "}
                                        </Box>
                                        <Box width={["100%"]}>
                                            {/* //TODO scommentare quando torna la mappa */}
                                            {/* <Button
                                                //href="#homemap"
                                                onClick={() => {
                                                    handleScroll();
                                                }}
                                                sx={{
                                                    width: ["100%"],
                                                    borderRadius: "10px",
                                                    height: ["3.5rem"],
                                                    border: "solid",
                                                    borderWidth: "1px",
                                                    borderColor: theme.palette.secondary.dark,
                                                    fontFamily: "Open Sans",
                                                    fontWeight: 600,
                                                    fontSize: "1rem",
                                                    color: theme.palette.secondary.dark,
                                                    textTransform: "none",
                                                }}
                                            >
                                                {translation.pages.home.headerButtonTwo}
                                            </Button> */}
                                        </Box>
                                    </Stack>
                                    <Box marginBottom={["1rem"]}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.secondary.light,
                                                fontFamily: "Open Sans",
                                                fontWeight: 400,
                                                fontSize: ["none", "0.8rem", "0.8rem", "1.10rem"],
                                                lineHeight: "1.56rem",
                                            }}
                                        >
                                            {" "}
                                            {translation.pages.home.headerContentTwo}{" "}
                                            <Link
                                                onClick={() => switchVisibilityDialog("contact")}
                                                style={{ color: theme.palette.secondary.light }}
                                            >
                                                {translation.pages.home.headerButtonThree}
                                            </Link>
                                        </Typography>{" "}
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Box
                        sx={{
                            background: `linear-gradient(90deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`,
                            height: ["60rem", "55rem", "25rem", "35rem"],
                        }}
                    >
                        <Container fixed={!isMobile}>
                            {/*maxWidth="xl" maxWidth={false}*/}

                            <Grid justifyContent={"center"} container>
                                <Grid
                                    overflow={"hidden"}
                                    paddingRight={["0rem", "2rem"]}
                                    paddingTop={["3rem"]}
                                    height={["30rem", "35rem"]}
                                    xs={12}
                                    md={6}
                                    item
                                >
                                    <Box>
                                        <img
                                            src={"/home1.png"}
                                            alt="Humtelemed"
                                            width={"100%"}
                                            style={{ mixBlendMode: "multiply", objectFit: "cover" }}
                                        />
                                    </Box>
                                </Grid>

                                <Grid
                                    paddingLeft={["2rem"]}
                                    paddingRight={["2rem"]}
                                    paddingTop={["4.5rem"]}
                                    height={["9rem", "30rem"]}
                                    xs={12}
                                    md={6}
                                    item
                                >
                                    <Stack>
                                        <Box marginBottom={["2rem", "2rem", "1rem", "2rem"]}>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.primary.light,
                                                    fontFamily: "Open Sans",
                                                    fontWeight: 700,
                                                    fontSize: "2.25rem",
                                                    lineHeight: "3.44rem",
                                                }}
                                                variant="h3"
                                            >
                                                {" "}
                                                HUMTELEMED
                                            </Typography>
                                        </Box>

                                        <Box marginBottom={["2rem", "2rem", "1rem", "2rem"]}>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.primary.light,
                                                    fontFamily: "Open Sans",
                                                    fontWeight: 400,
                                                    fontSize: [
                                                        "none",
                                                        "0.8rem",
                                                        "0.8rem",
                                                        "1.10rem",
                                                    ],
                                                    lineHeight: "1.56rem",
                                                }}
                                            >
                                                {" "}
                                                {translation.pages.home.headerContentOne}
                                            </Typography>
                                        </Box>

                                        <Stack
                                            marginBottom={["2rem", "2rem", "1rem", "2rem"]}
                                            justifyContent={["center"]}
                                            flexDirection={"row"}
                                        >
                                            <Box width={["50%"]}>
                                                <Button
                                                    onClick={() => evaluatesRisk()}
                                                    sx={{
                                                        width: ["80%"],
                                                        borderRadius: "10px",
                                                        height: ["3rem"],
                                                        backgroundColor: "white",
                                                        color: theme.palette.secondary.dark,
                                                        textTransform: "none",
                                                        fontFamily: "Open Sans",
                                                        fontSize: [
                                                            "none",
                                                            "0.8rem",
                                                            "0.8rem",
                                                            "1.10rem",
                                                        ],
                                                        fontWeight: 600,
                                                        "&:hover": {
                                                            backgroundColor: "white",
                                                        },
                                                    }}
                                                >
                                                    {translation.pages.home.headerButtonOne}
                                                </Button>{" "}
                                            </Box>
                                            <Box width={["50%"]}>
                                                {/* //TODO scommentare quando torna la mappa */}
                                                {/*  <Button
                                                    //href="#homemap"
                                                    onClick={() => {
                                                        handleScroll();
                                                    }}
                                                    sx={{
                                                        width: ["80%"],
                                                        borderRadius: "10px",
                                                        height: ["3rem"],
                                                        border: "solid",
                                                        borderWidth: "1px",
                                                        borderColor: "white",
                                                        fontFamily: "Open Sans",
                                                        fontWeight: 600,
                                                        fontSize: [
                                                            "none",
                                                            "0.8rem",
                                                            "0.8rem",
                                                            "1.10rem",
                                                        ],
                                                        color: "white",
                                                        textTransform: "none",
                                                    }}
                                                >
                                                    {translation.pages.home.headerButtonTwo}
                                                </Button> */}
                                            </Box>
                                        </Stack>

                                        <Box marginBottom={["1rem"]}>
                                            <Typography
                                                sx={{
                                                    color: theme.palette.primary.light,
                                                    fontFamily: "Open Sans",
                                                    fontWeight: 400,
                                                    fontSize: [
                                                        "none",
                                                        "0.8rem",
                                                        "0.8rem",
                                                        "1.10rem",
                                                    ],
                                                    lineHeight: "1.56rem",
                                                }}
                                            >
                                                {" "}
                                                {translation.pages.home.headerContentTwo}{" "}
                                                <Link
                                                    onClick={() =>
                                                        switchVisibilityDialog("contact")
                                                    }
                                                    style={{ color: "white" }}
                                                >
                                                    {translation.pages.home.headerButtonThree}
                                                </Link>
                                            </Typography>{" "}
                                        </Box>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                )}
            </>

            <Box>
                <Container disableGutters={isMobile} fixed={!isMobile}>
                    {/*maxWidth="xl" maxWidth={false}*/}

                    <Stack
                        marginTop={["0", "0rem", "0rem", "-6rem"]}
                        marginBottom={["2rem", "2rem", "5rem", "5rem"]}
                        paddingLeft={["0rem", "0rem", "2rem", "4rem"]}
                        paddingRight={["0rem", "0rem", "2rem", "4rem"]}
                        sx={{ isolation: "isolate", backgroundColor: "white" }}
                        justifyContent={["center"]}
                    >
                        <Box
                            marginTop={["3rem"]}
                            marginBottom={["2rem", "3rem"]}
                            textAlign={"center"}
                        >
                            <Typography
                                sx={{
                                    backgroundClip: "text",
                                    color: "transparent",
                                    backgroundImage: `linear-gradient(90deg, ${theme.palette.secondary.main},${theme.palette.secondary.dark})`,
                                    fontFamily: "Open Sans",
                                    fontWeight: 700,
                                    fontSize: ["1.13rem", "1.22rem"],
                                    lineHeight: "1.56rem",
                                }}
                                variant="h3"
                            >
                                {" "}
                                {translation.pages.home.subHeaderTitle}
                            </Typography>
                        </Box>

                        {rulesHomeCardArray.map((rules) => {
                            return (
                                <>
                                    <Grid
                                        marginBottom={["3rem"]}
                                        height={["20rem", "12rem"]}
                                        justifyContent={"center"}
                                        container
                                    >
                                        <Grid
                                            paddingRight={["0rem", "2rem"]}
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            item
                                        >
                                            <Box>
                                                <img
                                                    src={rules.imgPath}
                                                    alt={rules.title}
                                                    width={"100%"}
                                                    style={{ objectFit: "cover" }}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid
                                            paddingLeft={["1rem"]}
                                            paddingRight={["1rem"]}
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            item
                                        >
                                            <Box marginBottom={["2rem"]}>
                                                <Typography
                                                    sx={{
                                                        color: theme.palette.primary.main,
                                                        fontFamily: "Open Sans",
                                                        fontWeight: 700,
                                                        fontSize: [
                                                            "none",
                                                            "0.8rem",
                                                            "0.8rem",
                                                            "1.10rem",
                                                        ],
                                                        lineHeight: "1.56rem",
                                                    }}
                                                >
                                                    {" "}
                                                    {rules.title}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography
                                                    align="justify"
                                                    sx={{
                                                        color: theme.palette.primary.main,
                                                        fontFamily: "Open Sans",
                                                        fontWeight: 400,
                                                        fontSize: [
                                                            "none",
                                                            "0.8rem",
                                                            "0.8rem",
                                                            "1.10rem",
                                                        ],
                                                        lineHeight: "1.56rem",
                                                        maxHeight: "6rem",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {troncaStringaVicinoALimite(
                                                        rules.content,
                                                        isMobile
                                                    )}
                                                </Typography>
                                                {rules.content.length >
                                                    (isMobile
                                                        ? cardTestContinueToReadLenghtMobile
                                                        : cardTestContinueToReadLenghtDesktop) && (
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.primary.main,
                                                                fontFamily: "Open Sans",
                                                                fontWeight: 700,
                                                                fontSize: [
                                                                    "none",
                                                                    "0.8rem",
                                                                    "0.8rem",
                                                                    "1.10rem",
                                                                ],
                                                                lineHeight: "1.56rem",
                                                            }}
                                                        >
                                                            ...
                                                            <Link
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    handleDialogOpen(rules);
                                                                }}
                                                            >
                                                                {translation.pages.home.continueRead}
                                                            </Link>
                                                        </Typography>
                                                    )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        })}
                    </Stack>

                    <Stack
                        marginLeft={["1rem", "0rem"]}
                        marginRight={["1rem", "0rem"]}
                        marginBottom={["2rem", "4rem"]}
                        flexDirection={["column", "row"]}
                        alignContent={"center"}
                    >
                        <Box
                            width={["100%"]}
                            paddingBottom={["2rem", "0rem"]}
                            paddingRight={["0rem", "2rem"]}
                        >
                            <Grid
                                height={["auto", "15rem"]}
                                sx={{
                                    backgroundColor: "white",
                                    boxShadow: "0px 0px 2rem 10px rgba(0,0,0,0.2)",
                                }}
                                container
                                alignItems={"center"}
                                paddingLeft={["0rem", "2rem"]}
                            >
                                <>
                                    {!isMobile ? (
                                        <Grid xs={12} md={2} item>
                                            <Box>
                                                <img
                                                    src={"/home5.png"}
                                                    alt={translation.pages.home.cardFourTitle}
                                                    width={"100%"}
                                                    style={{ objectFit: "cover" }}
                                                />
                                            </Box>
                                        </Grid>
                                    ) : null}
                                </>
                                <Grid
                                    paddingLeft={["1rem", "2rem"]}
                                    paddingRight={["1rem", "0rem"]}
                                    paddingTop={["1rem", "0rem"]}
                                    paddingBottom={["1rem", "0rem"]}
                                    xs={12}
                                    md={10}
                                    item
                                >
                                    <Box marginBottom={["1rem"]}>
                                        <Typography
                                            sx={{
                                                color: "black",
                                                fontFamily: "Open Sans",
                                                fontWeight: 700,
                                                fontSize: ["1.13rem", "1.13rem"],
                                                lineHeight: "1.56rem",
                                            }}
                                        >
                                            <b>{translation.pages.home.cardFourTitle}</b>
                                        </Typography>
                                    </Box>
                                    <Box paddingRight={["0rem", "2rem"]}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.primary.main,
                                                fontFamily: "Open Sans",
                                                fontWeight: 500,
                                                fontSize: ["1rem", "1rem", "1rem", "1.13rem"],
                                                lineHeight: "1.56rem",
                                            }}
                                        >
                                            {" "}
                                            {translation.pages.home.cardFourContent}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box width={["100%"]} paddingLeft={["0rem", "2rem"]}>
                            <Grid
                                height={["auto", "15rem"]}
                                sx={{
                                    background: `linear-gradient(90deg,#4395D0 ,${theme.palette.secondary.dark})`,
                                    boxShadow: "0px 0px 2rem 10px rgba(0,0,0,0.2)",
                                }}
                                container
                                alignItems={"center"}
                                paddingLeft={["0rem", "2rem"]}
                            >
                                <>
                                    {!isMobile ? (
                                        <Grid xs={12} md={2} item>
                                            <Box>
                                                <img
                                                    src={"/home6.png"}
                                                    alt={translation.pages.home.cardFiveTitle}
                                                    width={"100%"}
                                                    style={{ objectFit: "cover" }}
                                                />
                                            </Box>
                                        </Grid>
                                    ) : null}
                                </>

                                <Grid
                                    paddingLeft={["1rem", "2rem"]}
                                    paddingRight={["1rem", "0rem"]}
                                    paddingTop={["1rem", "0rem"]}
                                    paddingBottom={["1rem", "0rem"]}
                                    xs={12}
                                    md={10}
                                    item
                                >
                                    <Box marginBottom={["1rem"]}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.primary.light,
                                                fontFamily: "Open Sans",
                                                fontWeight: 700,
                                                fontSize: ["1.13rem", "1.13rem"],
                                                lineHeight: "1.56rem",
                                            }}
                                        >
                                            {" "}
                                            {translation.pages.home.cardFiveTitle}
                                        </Typography>
                                    </Box>
                                    <Box paddingRight={["0rem", "2rem"]}>
                                        <Typography
                                            sx={{
                                                color: theme.palette.primary.light,
                                                fontFamily: "Open Sans",
                                                fontWeight: 600,
                                                fontSize: ["1rem", "1rem", "1rem", "1.13rem"],
                                                lineHeight: "1.56rem",
                                            }}
                                        >
                                            {" "}
                                            {translation.pages.home.cardFiveContent}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                    {/* 
                    <Box
                        id="homemap"
                        marginLeft={["1rem", "0rem"]}
                        marginRight={["2rem", "0rem"]}
                        marginBottom={["1.5rem", "2rem"]}
                    >
                        <Typography
                            sx={{
                                color: "black",
                                fontFamily: "Open Sans",
                                fontWeight: 600,
                                fontSize: "1.22rem",
                                lineHeight: "1.56rem",
                            }}
                            variant="h3"
                        >
                            {" "}
                            {translation.pages.home.mapTitle}
                        </Typography>
                    </Box>

                    <Box marginLeft={["1rem", "0rem"]} marginBottom={["1.5rem", "2rem"]}>
                        <Typography
                            sx={{
                                color: theme.palette.primary.main,
                                fontFamily: "Open Sans",
                                fontWeight: 400,
                                fontSize: ["1rem", "1.13rem"],
                                lineHeight: "1.56rem",
                            }}
                            variant="h3"
                        >
                            {" "}
                            {translation.pages.home.mapContent}
                        </Typography>
                    </Box>

                    <Stack
                        marginLeft={["1rem", "0rem"]}
                        marginRight={["1rem", "0rem"]}
                        marginBottom={["2rem", "4rem"]}
                        flexDirection={["column", "row"]}
                        justifyContent={"center"}
                        alignContent={"center"}
                    >
                        <Box
                            marginRight={["0rem", "2rem"]}
                            sx={{ boxShadow: "0px 0px 2rem 10px rgba(0,0,0,0.2)" }}
                            width={"100%"}
                            height={["25rem", "35rem"]}
                        >
                            {markersLoaded && (
                                <GoogleMapWithMarkers
                                    markers={mapInfo}
                                    returnNearPharmacy={(pharmacies: PharmacyDetail[]) => {
                                        setTopPharmacies(pharmacies);
                                    }}
                                    centerOfMaps={
                                        userLocation ? userLocation : defaultCenterPositionMap
                                    }
                                    zoom={userLocation ? 12 : 5}
                                />
                            )}
                        </Box>

                        <Box
                            marginLeft={["0rem", "2rem"]}
                            width={"100%"}
                            height={["auto", "35rem"]}
                        >
                            <Box
                                paddingTop={["1rem", "0rem"]}
                                paddingLeft={["0rem", "1rem"]}
                                width={"100%"}
                                height={["3rem", "5rem"]}
                            >
                                <Typography
                                    sx={{
                                        color: "black",
                                        fontFamily: "Open Sans",
                                        fontWeight: 700,
                                        fontSize: ["1.13rem", "1.13rem"],
                                        lineHeight: "1.56rem",
                                    }}
                                >
                                    <b>{translation.pages.home.topPharmaciesTitle}</b>
                                </Typography>
                            </Box>
                            <Stack
                                flexDirection={["column"]}
                                justifyContent={"stretch"}
                                height={["auto", "30rem"]}
                                width={["100%"]}
                                sx={{
                                    backgroundColor: theme.palette.primary.light,
                                    boxShadow: "0px 0px 2rem 10px rgba(0,0,0,0.2)",
                                }}
                            >
                                {topPharmacies.map((elem: PharmacyDetail, index) => {
                                    return (
                                        <>
                                            <Box
                                                sx={{
                                                    boxSizing: "border-box",
                                                    backgroundColor:
                                                        index % 2 === 1
                                                            ? theme.palette.background.default
                                                            : theme.palette.primary.light,
                                                }}
                                                height={["6rem", "6rem"]}
                                                padding={["1rem", "1.5rem"]}
                                                overflow="hidden"
                                            >
                                                <Box>
                                                    <Typography
                                                        sx={{
                                                            color: "black",
                                                            fontFamily: "Open Sans",
                                                            fontWeight: 400,
                                                            fontSize: ["1rem", "1.13rem"],
                                                            lineHeight: "1.56rem",
                                                        }}
                                                    >
                                                        <b>{elem.name}</b>
                                                    </Typography>
                                                </Box>
                                                <Stack
                                                    width={"100%"}
                                                    flexDirection={["column", "row"]}
                                                    justifyContent={"space-between"}
                                                >
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                color: "black",
                                                                fontFamily: "Open Sans",
                                                                fontWeight: 400,
                                                                fontSize: ["1rem", "1.13rem"],
                                                                lineHeight: "1.56rem",
                                                            }}
                                                        >
                                                            {elem.city}, {elem.address}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                color: theme.palette.secondary
                                                                    .light,
                                                                fontFamily: "Open Sans",
                                                                fontWeight: 400,
                                                                fontSize: ["1rem", "1.13rem"],
                                                                lineHeight: "1.56rem",
                                                            }}
                                                        >
                                                            <Link
                                                                sx={{
                                                                    color: theme.palette.secondary
                                                                        .light,
                                                                    textDecoration: "none",
                                                                }}
                                                                href={`tel:${elem.phoneNumber}`}
                                                            >
                                                                {elem.phoneNumber}
                                                            </Link>
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                        </>
                                    );
                                })}
                            </Stack>
                        </Box>
                    </Stack>
 
                    <Box textAlign={"right"} marginRight={["1rem", "0rem"]} marginBottom={["2rem"]}>
                        <Typography
                            sx={{
                                color: theme.palette.primary.main,
                                fontFamily: "Open Sans",
                                fontWeight: 400,
                                fontSize: ["1rem", "1.13rem"],
                                lineHeight: "1.56rem",
                            }}
                            variant="h3"
                        >
                            {" "}
                            {translation.pages.home.mapContent2}{" "}
                            <Link
                                onClick={() => switchVisibilityDialog("contact")}
                                style={{ fontWeight: "bold" }}
                            >
                                {translation.pages.home.mapContent3}
                            </Link>{" "}
                            {translation.pages.home.mapContent4}
                        </Typography>
                    </Box>
                  */}
                </Container>
            </Box>

            <Box sx={{ backgroundColor: theme.palette.secondary.dark }}>
                <Container fixed={!isMobile}>
                    <Box paddingBottom={["2rem", "3rem"]} paddingTop={["2rem", "3rem"]}>
                        <Typography
                            sx={{
                                color: theme.palette.primary.light,
                                fontFamily: "Open Sans",
                                fontWeight: 600,
                                fontSize: ["1.13rem", "1.22rem"],
                                lineHeight: "1.56rem",
                            }}
                            variant="h3"
                        >
                            {" "}
                            {translation.pages.home.contributorContent}
                        </Typography>
                    </Box>

                    <Grid
                        justifyContent={["center", "space-between"]}
                        paddingBottom={["3rem", "3rem"]}
                        container
                        spacing={3}
                    >
                        {contributorsHomeCardArray.map((contributor, index) => (
                            <Grid
                                item
                                xs={contributor.size[0]}
                                sm={contributor.size[1]}
                                md={contributor.size[1]}
                                key={index}
                            >
                                <Grid
                                    container
                                    style={{
                                        height: "8rem",
                                        backgroundColor: "white",
                                        borderRadius: "25px",
                                    }}
                                    alignItems="center"
                                    justifyContent="center"
                                    padding={3}
                                >
                                    <Grid item>
                                        <img
                                            alt={contributor.name}
                                            width="100%"
                                            src={contributor.imgPath}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            <ContactDialog
                isOpen={openDialogs.contact}
                closeDialog={() => switchVisibilityDialog("contact")}
                onClick={(name: string, email: string, message: string) => {
                    switchVisibilityDialog("contact");
                    contactRequest({ name: name, email: email, message: message });
                    switchVisibilityDialog("sent");
                }}
            />
            <MessageSentDialog
                isOpen={openDialogs.sent}
                closeDialog={() => switchVisibilityDialog("sent")}
            />
            <HomeRuleDialog
                isOpen={isDialogOpen}
                closeDialog={handleDialogClose}
                rule={selectedHomeRulesCard!}
            />
        </Box>
    );
}

export default HomeComponent;
