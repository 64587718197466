import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { PagesListAppBar, pages } from "./PagesList";

import { Box, Container, SwipeableDrawer, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

function CustomToolbar() {
    const [anchorEl, setAnchorEl] = useState<boolean>(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleClick = () => {
        setAnchorEl(true);
    };

    const toggleDrawer = () => {
        setAnchorEl(false);
    };

    return (
        <Container fixed={!isMobile}>
            <AppBar
                sx={{
                    justifyContent: "space-between",
                    width: "100%",
                    boxShadow: "none",
                    backgroundColor: theme.palette.primary.light,
                }}
                position="static"
            >
                <Toolbar
                    sx={{
                        justifyContent: "space-between",
                        marginX: "0px !important",
                        paddingX: "0px !important",
                        boxShadow: "none",
                        background: "white",
                    }}
                >
                    {isMobile ? (
                        <>
                            <Box width={"85%"}>
                                <Link to="/" style={{ textDecoration: "none" }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: "black",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            fontSize: "1.4rem",
                                            lineHeight: "3.44rem",
                                        }}
                                    >
                                        HUMTELEMED
                                    </Typography>
                                </Link>
                            </Box>
                            <Box>
                                <Button onClick={handleClick}>
                                    <MenuIcon />
                                </Button>
                                <SwipeableDrawer
                                    PaperProps={{
                                        sx: {
                                            width: ["50%", "20%"],
                                        },
                                    }}
                                    anchor="right"
                                    open={anchorEl}
                                    onClose={toggleDrawer}
                                    onOpen={handleClick}
                                >
                                    <PagesListAppBar
                                        justifyContentType={["start"]}
                                        marginTop={["30%", "30%", "0", "0"]}
                                        pagesProp={pages}
                                        isLogout={false}
                                    ></PagesListAppBar>
                                </SwipeableDrawer>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box width={["30%", "20%", "20%", "20%"]}>
                                <Link to="/" style={{ textDecoration: "none" }}>
                                    {" "}
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: "black",
                                            fontFamily: "Open Sans",
                                            fontWeight: 600,
                                            fontSize: ["1.5rem", "2rem", "1.5rem", "2rem"],
                                            lineHeight: "3.44rem",
                                        }}
                                    >
                                        HUMTELEMED
                                    </Typography>
                                </Link>
                            </Box>

                            <Box width={["70%", "80%", "80%", "80%"]}>
                                <PagesListAppBar
                                    marginTop={["30%", "30%", "0", "0"]}
                                    fontSize={["2rem", "2rem", "1.5rem", "2rem"]}
                                    pagesProp={pages}
                                    isLogout={false}
                                ></PagesListAppBar>
                            </Box>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </Container>
    );
}

export default CustomToolbar;
