/** @format */

import { useState, useEffect } from "react";
import { constants } from "./constants";

export const useWindowDimensions = function () {
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowDimensions;
};

export const generateUUID = function () {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 =
        (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
};

export const formatString = function (...format: any) {
    let args = Array.prototype.slice.call(arguments, 1);
    return format[0].replace(/{(\d+)}/g, function (match: string, number: number) {
        return typeof args[number] != "undefined" ? args[number] : match;
    });
};

export const addDebugTestId = (testid: string, props?: any) => {
    const res = {
        "data-testid": `testid_${testid}`,
        ...(props || {}),
    };
    if (!constants.IS_DEBUG) delete res[`data-testid`];
    return res;
};

export const debounce = (func: (...args: any) => any, timeout = 300) => {
    let timer: NodeJS.Timeout | undefined;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const debounceLeading = (func: (...args: any) => any, timeout = 300) => {
    let timer: NodeJS.Timeout | undefined;
    return (...args: any) => {
        if (!timer) {
            func.apply(this, args);
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = undefined;
        }, timeout);
    };
};

export const getCountryOptions = (
    countries: { [key: string]: string },
    countryRiskMap: { [key: string]: string }
): { label: string, value: any }[] => {
    //TODO fix italian
    const array: string[] = Object.keys(countryRiskMap);
    return array.map((country) => ({ label: countries[country], value: country }));
};

export const getColumnsHeaderVariableTranslatorName = (lang: any) => {
    return {
        columnMenuSortAsc: lang.tables.shared.columnMenuSortAsc,
        columnMenuSortDesc: lang.tables.shared.columnMenuSortDesc,
        columnMenuUnsort: lang.tables.shared.columnMenuUnsort,
        columnMenuFilter: lang.tables.shared.columnMenuFilter,
        columnMenuHideColumn: lang.tables.shared.columnMenuHideColumn,
        columnMenuManageColumns: lang.tables.shared.columnMenuManageColumns,
        filterPanelColumns: lang.tables.shared.filterPanelColumns,
        filterPanelInputLabel: lang.tables.shared.filterPanelInputLabel,
        filterPanelOperator: lang.tables.shared.filterPanelOperator,
        filterPanelInputPlaceholder: lang.tables.shared.filterPanelInputPlaceholder,
        filterOperatorContains: lang.tables.shared.filterOperatorContains,
        filterOperatorEquals: lang.tables.shared.filterOperatorEquals,
        filterOperatorStartsWith: lang.tables.shared.filterOperatorStartsWith,
        filterOperatorEndsWith: lang.tables.shared.filterOperatorEndsWith,
        filterOperatorIsEmpty: lang.tables.shared.filterOperatorIsEmpty,
        filterOperatorIsNotEmpty: lang.tables.shared.filterOperatorIsNotEmpty,
        filterOperatorIsAnyOf: lang.tables.shared.filterOperatorIsAnyOf
    }
}
