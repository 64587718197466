import { Network } from "./network";
import { useLocalStorageService, User } from "@/states/localStorageState";



export interface AuthServiceProps {
    loggedUser: User | null;
    logout: () => Promise<void>;
    me: () => Promise<void>;
    loginRequest: (loginForm: { email: string; password: string }) => Promise<void>;
    forgotPasswordRequest: (email: string) => Promise<void>;
    confirmResetPasswordRequest: (password: string, resetPasswordToken: string) => Promise<void>;
    initCurrentUser: () => Promise<void>;
    isLoggedIn: () => boolean;
    changePasswordRequest: (changePassword: {
        oldPassword: string;
        newPassword: string;
    }) => Promise<void>;
    loading: boolean;
}
export const useAuthService = (): AuthServiceProps => {
    const lsAccessTokenKey = "accessToken";

    const loggedUser = useLocalStorageService((store) => store.user);
    const setUser = useLocalStorageService((store) => store.setUser);
    const loading = useLocalStorageService((store) => store.loading)
    const setLoading = useLocalStorageService((store) => store.setLoading)

    const logout = async () => {
        await Network.Post("/Auth/logout");
        localStorage.removeItem(lsAccessTokenKey);
        setUser(null);
    };

    const me = async () => {
        const data = await Network.Get("/Auth/me");
        setUser({ id: data.id, name: data.name, email: data.email, role: data.role, pharmacyId: data.pharmacyId });
    };

    const loginRequest = async (loginForm: { email: string; password: string }) => {
        const login = await Network.Post("/Auth/login", loginForm);
        localStorage.setItem(lsAccessTokenKey, login.accessToken);

        await me();

    };

    const forgotPasswordRequest = async (email: string) => {
        await Network.Post("/Auth/resetPassword", { email });
    };

    const confirmResetPasswordRequest = async (newPassword: string, resetPasswordToken: string) => {
        await Network.Post("/Auth/confirmResetPassword", { newPassword, resetPasswordToken });
    };

    const initCurrentUser = async () => {
        const hasAccessToken = !!localStorage.getItem(lsAccessTokenKey);
        if (hasAccessToken) {
            await me();

        }
        setLoading(false)
    };



    const isLoggedIn = () => {
        return !!loggedUser;
    };

    const changePasswordRequest = async (changePassword: {
        oldPassword: string;
        newPassword: string;
    }) => {
        await Network.Post("/Auth/changePassword", changePassword);
    };

    return {
        loggedUser,
        logout,
        me,
        loginRequest,
        initCurrentUser,
        isLoggedIn,
        forgotPasswordRequest,
        confirmResetPasswordRequest,
        changePasswordRequest,
        loading
    };
};
