import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Stack,
    Typography,
    useTheme,
} from "@mui/material";
import { DialogProps } from ".";
import { CustomButton } from "@/components";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";

type ErrorDialogProps = { test?: string } & DialogProps;

export function ErrorDialog({ isOpen, closeDialog, test }: ErrorDialogProps) {
    const lang = useTranslatedMessages();
    const { palette } = useTheme();
    return (
        <Dialog open={isOpen} onClose={closeDialog}>
            <DialogHeaderComponent error title={lang.dialogs.error.title} />

            <DialogContent
                sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
            >
                <Box>
                    <Typography
                        variant="caption"
                        color={palette.primary.dark}
                        sx={{
                            fontWeight: 400,
                            fontSize: ["none", "0.8rem", "0.8rem", "1.10rem"],
                            lineHeight: "1.56rem",
                        }}
                    >
                        {lang.dialogs.error.errorTest}
                    </Typography>
                </Box>
                {test ? (
                    <Box>
                        <Typography
                            variant="caption"
                            color={palette.primary.dark}
                            sx={{
                                fontWeight: 400,
                                fontSize: ["none", "0.8rem", "0.8rem", "1.10rem"],
                                lineHeight: "1.56rem",
                            }}
                        >
                            <b>ERRORE</b>: {test}
                        </Typography>
                    </Box>
                ) : null}
            </DialogContent>
            <DialogActions sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "0rem" }}>
                <Stack width={"100%"} flexDirection={"row"} gap={1}>
                    <CustomButton
                        sx={{
                            width: "100%",
                            borderColor: palette.secondary.dark,
                            border: "1px solid",
                            backgroundColor: palette.primary.light,
                            color: palette.secondary.dark,
                        }}
                        secondary
                        label={lang.shared.close}
                        onClick={() => {
                            closeDialog();
                        }}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
