import React from "react";
import { Dialog, DialogActions, DialogContent, Stack, Box, Typography } from "@mui/material";
import { DialogProps } from ".";
import { DialogHeaderComponent } from "./DialogHeaderComponent";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { theme } from "@/theme";
import { CustomButton } from "@/components";

type ConfirmDialogProps = { onConfirm: () => void; title: string; message: string } & DialogProps;

const ConfirmDialog = ({ isOpen, closeDialog, onConfirm, title, message }: ConfirmDialogProps) => {
    const lang = useTranslatedMessages();
    return (
        <Dialog open={isOpen} onClose={closeDialog}>
            <DialogHeaderComponent title={title}></DialogHeaderComponent>
            <DialogContent
                sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
            >
                <Box>
                    <Typography
                        variant="caption"
                        color={theme.palette.primary.dark}
                        sx={{
                            fontWeight: 400,
                            fontSize: ["none", "0.8rem", "0.8rem", "1.10rem"],
                            lineHeight: "1.56rem",
                        }}
                    >
                        {message}
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions
                sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
            >
                <Stack
                    width={"100%"}
                    justifyContent={"space-between"}
                    flexDirection={"row"}
                    gap={1}
                >
                    <CustomButton
                        sx={{
                            width: "100%",
                            borderColor: theme.palette.secondary.dark,
                            border: "1px solid",
                            backgroundColor: theme.palette.primary.light,
                            color: theme.palette.secondary.dark,
                        }}
                        secondary
                        label={lang.dialogs.confirmDialog.rejectionButton}
                        onClick={closeDialog}
                    />
                    <CustomButton
                        sx={{
                            paddingLeft: "1rem",
                            width: "100%",
                            color: theme.palette.primary.light,
                            backgroundColor: theme.palette.secondary.dark,
                        }}
                        label={lang.dialogs.confirmDialog.confirmButtom}
                        onClick={onConfirm}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
