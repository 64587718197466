import React from "react";
import { Button, IconButton } from "@mui/material";
import { theme } from "../../theme";

type GreenButtonProps = {
    text: string;
    disabled?: boolean;
    isDeleteButton?: boolean;
    endIcon?: React.ReactElement;
    startIcon?: React.ReactElement;
    onClickProp: () => void;
};

export function GreenButton({
    text,
    disabled,
    endIcon,
    startIcon,
    isDeleteButton,
    onClickProp,
}: GreenButtonProps) {
    return (
        //<WrapperTimerListner>
        <>
            {text !== "" ? (
                <Button
                    sx={{
                        backgroundColor: isDeleteButton
                            ? theme.palette.error.main
                            : theme.palette.secondary.main,
                        color: isDeleteButton
                            ? theme.palette.primary.light
                            : theme.palette.secondary.dark,
                        borderRadius: "10px",
                        padding: "5px 20px",
                        fontWeight: "700",
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: isDeleteButton
                                ? theme.palette.error.main
                                : theme.palette.secondary.main,
                            /* color: theme.palette.secondary.dark, borderRadius: "10px", */
                        },
                        "&.Mui-disabled": {
                            color: theme.palette.secondary.dark,
                            // Add any other styles you want for the disabled state here
                        },
                    }}
                    endIcon={endIcon}
                    startIcon={startIcon}
                    disabled={disabled}
                    onClick={() => {
                        onClickProp();
                    }}
                >
                    {text}
                </Button>
            ) : (
                <IconButton
                    sx={{
                        width: ["2rem", "2rem", "3rem"],
                        color: isDeleteButton
                            ? theme.palette.error.main
                            : theme.palette.secondary.dark,
                    }}
                    onClick={() => {
                        onClickProp();
                    }}
                >
                    {endIcon ? endIcon : startIcon}
                </IconButton>
            )}
        </>
        //</WrapperTimerListner>
    );
}
