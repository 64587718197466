import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    useTheme,
} from "@mui/material";
import { User, useLocalStorageService } from "@/states/localStorageState";
import { addNewPharmacist, updatePharmacist } from "@/services/pharmacyService";
import { DialogProps } from ".";
import { CustomButton } from "@/components";
import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";
import ConfirmDialog from "./ConfirmDialog";
import { ErrorDialog } from "./ErrorDialog";

function generateRandomString(length: number = 8): string {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const initialFormData = {
    name: "",
    role: "",
    email: "",
    password: "",
};

type AddClientDialogProps = {
    userDataForUpdate?: User;
    onSubmit?: (data: User) => void;
} & DialogProps;

export function AddPharmacistDialog({
    isOpen,
    closeDialog,
    userDataForUpdate,
    onSubmit,
}: AddClientDialogProps) {
    const user = useLocalStorageService((state) => state.user);
    const lang = useTranslatedMessages();
    const { palette } = useTheme();
    const {
        control,
        handleSubmit,
        trigger,
        watch,
        getValues,
        reset,
        setValue,
        formState: { errors, isValid },
    } = useForm<User>({
        defaultValues: initialFormData,
    });
    const [error, setError] = useState<string>("");
    const [openDialogs, setOpenDialogs] = useState({
        confirmDialog: false,
        errorDialog: false,
    });
    const selectedRole = watch("role");
    function switchVisibilityDialog(name: keyof typeof openDialogs) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }
    useEffect(() => {
        if (userDataForUpdate) {
            Object.entries(userDataForUpdate).forEach(([key, value]) => {
                setValue(key as keyof User, value);
            });
        } else {
            reset(initialFormData);
        }
    }, [isOpen, userDataForUpdate, reset, setValue]);

    const onSubmitForm = async (data: User) => {
        try {
            if (userDataForUpdate) {
                await updatePharmacist(user?.pharmacyId!, data);
            } else {
                data.password = generateRandomString(8);
                await addNewPharmacist(user?.pharmacyId!, data);
            }
            onSubmit?.(data);
            closeDialog();
            switchVisibilityDialog("confirmDialog");
        } catch (error) {
            switchVisibilityDialog("confirmDialog");
            setError(`Error: ${error}`);
            switchVisibilityDialog("errorDialog");
        }
    };

    return (
        <Dialog open={isOpen} onClose={closeDialog}>
            <DialogHeaderComponent
                title={
                    userDataForUpdate
                        ? lang.pages.reservedAreaProfile.updateCompilationDialogHeader
                        : lang.pages.reservedAreaProfile.addCompilationDialogHeader
                }
            />
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <DialogContent
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
                >
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={
                                    <>{lang.pages.reservedAreaProfile.addCompilationDialogName}*</>
                                }
                                margin="normal"
                            />
                        )}
                    />

                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: "Email is required",
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: "Invalid email",
                            },
                        }}
                        render={({ field: { ref, onBlur, ...field }, fieldState: { error } }) => (
                            <TextField
                                {...field}
                                fullWidth
                                label={
                                    <>{lang.pages.reservedAreaProfile.addCompilationDialogEmail}*</>
                                }
                                margin="normal"
                                disabled={!!userDataForUpdate}
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : ""}
                                onBlur={async () => {
                                    onBlur(); // chiama prima onBlur di react-hook-form
                                    await trigger("email"); // poi esegui la validazione per il campo email
                                }}
                            />
                        )}
                    />

                    <FormControl fullWidth margin="normal">
                        <InputLabel id="role-label">
                            {lang.pages.reservedAreaProfile.addCompilationDialogRole}*
                        </InputLabel>
                        <Controller
                            name="role"
                            control={control}
                            rules={{ required: "Role is required" }}
                            disabled={
                                !!userDataForUpdate && selectedRole === "Admin" ? true : false
                            }
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    labelId="role-label"
                                    label={lang.pages.reservedAreaProfile.addCompilationDialogRole}
                                    id="role"
                                >
                                    {["Admin", "User"].map((r) => (
                                        <MenuItem key={r} value={r}>
                                            {r}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "0rem" }}
                >
                    <Stack width={"100%"} flexDirection={"row"} gap={1}>
                        <CustomButton
                            sx={{
                                width: "100%",
                                borderColor: palette.secondary.dark,
                                border: "1px solid",
                                backgroundColor: palette.primary.light,
                                color: palette.secondary.dark,
                            }}
                            secondary
                            label={lang.shared.close}
                            onClick={() => {
                                closeDialog();
                            }}
                        />
                        <CustomButton
                            sx={{
                                width: "100%",
                                color: palette.primary.light,
                                backgroundColor: palette.secondary.dark,
                            }}
                            label={lang.pages.reservedAreaProfile.addCompilationDialogButton}
                            /* type="submit" */
                            onClick={() => {
                                switchVisibilityDialog("confirmDialog");
                            }}
                            disabled={!isValid}
                            color="primary"
                        />
                    </Stack>
                </DialogActions>
            </form>
            <ConfirmDialog
                isOpen={openDialogs.confirmDialog}
                closeDialog={() => {
                    switchVisibilityDialog("confirmDialog");
                }}
                onConfirm={() => onSubmitForm(getValues())}
                title={
                    userDataForUpdate
                        ? lang.dialogs.confirmDialog.modifyTitle
                        : lang.dialogs.confirmDialog.addTitle
                }
                message={
                    userDataForUpdate
                        ? lang.dialogs.confirmDialog.modifyContent
                        : lang.dialogs.confirmDialog.addContent
                }
            />

            <ErrorDialog
                isOpen={openDialogs.errorDialog}
                closeDialog={() => {
                    switchVisibilityDialog("errorDialog");
                }}
                test={error}
            />
        </Dialog>
    );
}
