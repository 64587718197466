import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DialogActions, DialogContent, Stack, TextField, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import { useTranslatedMessages } from "@/multiLanguage/i18n";
import { PharmacyDetail, updatePharamcy } from "@/services/pharmacyService";
import { DialogHeaderComponent } from "@/dialogs/DialogHeaderComponent";
import { DialogProps } from ".";
import { CustomButton } from "@/components";
import ConfirmDialog from "./ConfirmDialog";

type PharmacyDetailDialogProps = {
    pharmacyOldData: PharmacyDetail;
    onSubmit?: (data: PharmacyDetail) => void;
} & DialogProps;

export function UpdatePharmacyDialog({
    isOpen,
    closeDialog,
    pharmacyOldData,
    onSubmit,
}: PharmacyDetailDialogProps) {
    const lang = useTranslatedMessages();
    const { palette } = useTheme();

    const {
        control,
        handleSubmit,
        getValues,
        formState: { errors, isValid },
        reset,
    } = useForm<PharmacyDetail>({
        defaultValues: pharmacyOldData,
    });

    const [openDialogs, setOpenDialogs] = useState({
        confirmDialog: false,
    });

    function switchVisibilityDialog(name: keyof typeof openDialogs) {
        setOpenDialogs((prevOpenDialogs) => ({
            ...prevOpenDialogs,
            [name]: !prevOpenDialogs[name],
        }));
    }

    const onSubmitForm = async (data: PharmacyDetail) => {
        try {
            await updatePharamcy(pharmacyOldData.id, data);

            onSubmit?.(data);
            closeDialog();
            switchVisibilityDialog("confirmDialog");
        } catch (error) {
            console.error("Failed to submit form", error);
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            open={isOpen}
            onClose={() => {
                closeDialog();
                reset(pharmacyOldData);
            }}
            PaperProps={{
                style: { borderRadius: 0, minWidth: "15rem" },
            }}
        >
            <DialogHeaderComponent title={lang.dialogs.updatePharmacy.title} />

            <form onSubmit={handleSubmit(onSubmitForm)}>
                <DialogContent
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "0rem" }}
                >
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: "Name is required" }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label={lang.dialogs.updatePharmacy.name}
                                margin="normal"
                                error={!!errors.name}
                                helperText={errors.name ? errors.name.message : ""}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: "Email is required",
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: "Invalid email",
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label={lang.dialogs.updatePharmacy.email}
                                margin="normal"
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : ""}
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="city"
                        control={control}
                        rules={{ required: "city is required" }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label={lang.dialogs.updatePharmacy.city}
                                margin="normal"
                                error={!!errors.city}
                                helperText={errors.city ? errors.city.message : ""}
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="address"
                        control={control}
                        rules={{ required: "address is required" }}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label={lang.dialogs.updatePharmacy.address}
                                margin="normal"
                                error={!!errors.address}
                                helperText={errors.address ? errors.address.message : ""}
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="postCode"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label={lang.dialogs.updatePharmacy.postCode}
                                margin="normal"
                                error={!!errors.postCode}
                                helperText={errors.postCode ? errors.postCode.message : ""}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="vatNumber"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label={lang.dialogs.updatePharmacy.vatNumber}
                                margin="normal"
                                error={!!errors.vatNumber}
                                helperText={errors.vatNumber ? errors.vatNumber.message : ""}
                                {...field}
                            />
                        )}
                    />
                    <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                label={lang.dialogs.updatePharmacy.phoneNumber}
                                margin="normal"
                                error={!!errors.phoneNumber}
                                helperText={errors.phoneNumber ? errors.phoneNumber.message : ""}
                                {...field}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions
                    sx={{ paddingLeft: "2rem", paddingRight: "2rem", paddingTop: "0rem" }}
                >
                    <Stack width={"100%"} flexDirection={"row"} gap={1}>
                        <CustomButton
                            sx={{
                                width: "100%",
                                borderColor: palette.secondary.dark,
                                border: "1px solid",
                                backgroundColor: palette.primary.light,
                                color: palette.secondary.dark,
                            }}
                            secondary
                            label={lang.shared.close}
                            onClick={() => {
                                closeDialog();
                                reset(pharmacyOldData);
                            }}
                        />
                        <CustomButton
                            sx={{
                                width: "100%",
                                color: palette.primary.light,
                                backgroundColor: palette.secondary.dark,
                            }}
                            label={lang.shared.send}
                            /* type="submit" */
                            onClick={() => {
                                switchVisibilityDialog("confirmDialog");
                            }}
                            disabled={!isValid}
                            color="primary"
                        />
                    </Stack>
                </DialogActions>
            </form>
            <ConfirmDialog
                isOpen={openDialogs.confirmDialog}
                closeDialog={() => {
                    switchVisibilityDialog("confirmDialog");
                }}
                onConfirm={() => onSubmitForm(getValues())}
                title={lang.dialogs.confirmDialog.modifyTitle}
                message={lang.dialogs.confirmDialog.modifyContent}
            />
        </Dialog>
    );
}
